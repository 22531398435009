import { MenuItem, MenuItemProps, Stack, Typography } from '@mui/material';
import { forwardRef } from 'react';
import { AddCircle } from '@mui/icons-material';

export interface AssetAssignerAutoCompleteOption {
  id: string;
  name: string;
}

export interface AssetAssignerAutoCompleteCreateOption {
  id: 'internal';
  name: string;
}

export interface AssetAssignerAutoCompleteDetailedOption {
  id: string;
  name: string;
  subtitle: string;
  metadata: string[];
}
const isDetailedOption = (option: any): option is AssetAssignerAutoCompleteDetailedOption => {
  return typeof option.subtitle === 'string' && Array.isArray(option.metadata);
};

type ItemProps = {
  active: boolean;
} & (AssetAssignerAutoCompleteOption | AssetAssignerAutoCompleteCreateOption | AssetAssignerAutoCompleteDetailedOption);

export const AssetAssignerAutoCompleteItem = forwardRef<HTMLLIElement, ItemProps & MenuItemProps>((props, ref) => {
  const { id, name, active, ...rest } = props;

  const isCreate = id === 'internal';
  return (
    <MenuItem
      ref={ref}
      id={id}
      {...rest}
      sx={{
        background: active ? 'rgba(0, 0, 0, 0.04)' : undefined,
        cursor: 'pointer',
        ...rest.style,
      }}
    >
      {isDetailedOption(props) ? (
        <Stack width="100%">
          <Typography variant="body1" color="text.primary" sx={overflowSx}>
            {name}
          </Typography>
          <Typography variant="caption" color="text.secondary" sx={overflowSx}>
            {props.subtitle}
          </Typography>
          {props.metadata.map((meta, index) => (
            <Typography
              key={`AssetAssignerAutoCompleteItem-${props.id}-${index}`}
              variant="caption"
              color="text.secondary"
              sx={overflowSx}
            >
              {meta}
            </Typography>
          ))}
        </Stack>
      ) : (
        <Stack direction="row" gap={1} height={isCreate ? 42 : 24} alignItems="center" width="100%">
          {isCreate ? <AddCircle fontSize="small" color="action" /> : null}
          <Typography sx={overflowSx}>{name}</Typography>
        </Stack>
      )}
    </MenuItem>
  );
});

AssetAssignerAutoCompleteItem.displayName = 'Item';

const overflowSx = { maxWidth: '100%', overflowX: 'hidden', textOverflow: 'ellipsis' };
