import React, { useEffect, useRef } from 'react';
import { Box, Stack } from '@mui/material';
import { CanvasToolbarBottomPane } from './canvas-toolbar-bottom-pane/canvas-toolbar-bottom-pane';
import { CanvasToolbarHeader } from './header/canvas-toolbar-header';
import { ToolbarState } from '@xspecs/single-source-model';
import { Allotment, AllotmentHandle, LayoutPriority } from 'allotment';

type CanvasToolbarProps = {
  toolbar: ToolbarState;
};

const TOTAL = 500;
const LOCAL_STORAGE_CANVAS_TOOLBAR_SIZES_KEY = 'canvas-toolbar-sizes';

export const CanvasToolbar = (props: CanvasToolbarProps) => {
  const { toolbar } = props;

  const ref = useRef<AllotmentHandle>(null);
  const rowsLengthRef = useRef(toolbar.layout.body.rows.length);

  const isBodyCollapsed = toolbar.layout.body.rows.length === 0;
  const isCompactMode = toolbar.layout.body.rows.length === 1;

  useEffect(() => {
    const rowsLength = toolbar.layout.body.rows.length;
    if (!ref.current || rowsLength === rowsLengthRef.current) return;

    rowsLengthRef.current = rowsLength;
    const bottomSize = 70;
    if (rowsLength === 1) ref.current.resize([TOTAL - bottomSize, bottomSize]);
    else if (rowsLength > 1) {
      setTimeout(() => {
        const item = JSON.parse(localStorage.getItem(LOCAL_STORAGE_CANVAS_TOOLBAR_SIZES_KEY) || '[]');
        ref.current?.resize(item.length === 0 ? [bottomSize, TOTAL - bottomSize] : item);
      }, 1);
    }
  }, [toolbar.layout.body.rows]);

  return (
    <Box
      sx={{
        width: '100%',
        height: isBodyCollapsed ? 58 : TOTAL,
        position: 'absolute',
        bottom: isBodyCollapsed ? 12 : 0,
        left: 0,
        overflow: 'visible',
        pointerEvents: 'none !important',
        '& .split-view': {
          '--focus-border': 'transparent',
          overflow: 'visible !important',
        },
        '& .split-view-view': {
          overflow: 'visible !important',
        },
        '& .split-view-view:first-of-type': {
          pointerEvents: 'none !important',
        },
      }}
    >
      {isBodyCollapsed ? (
        <Stack alignItems="center">
          <CanvasToolbarHeader expanded={!isBodyCollapsed} {...toolbar.layout.header} />
        </Stack>
      ) : (
        <Allotment
          vertical
          ref={ref}
          minSize={1}
          onChange={(sizes) => {
            if (!isCompactMode) {
              localStorage.setItem(LOCAL_STORAGE_CANVAS_TOOLBAR_SIZES_KEY, JSON.stringify(sizes));
            }
          }}
        >
          <Allotment.Pane>
            <Box sx={dummyPaneSx} />
          </Allotment.Pane>
          <Allotment.Pane priority={LayoutPriority.High} minSize={10}>
            <Box position="relative" width="100%" height="100%">
              <Stack alignItems="center" position="absolute" sx={headerContainerSx}>
                <CanvasToolbarHeader expanded={!isBodyCollapsed} {...toolbar.layout.header} />
              </Stack>
              <Stack position="absolute" width="100%" height="100%">
                <CanvasToolbarBottomPane {...toolbar.layout.body} />
              </Stack>
            </Box>
          </Allotment.Pane>
        </Allotment>
      )}
    </Box>
  );
};

const headerContainerSx = {
  zIndex: 1000,
  top: -57,
  width: '100%',
};

const dummyPaneSx = {
  height: '100%',
  width: '100%',
  pointerEvents: 'none',
};
