import { IStore } from '../../data/Store';
import { EntityRepository } from '../../data/EntityRepository';
import { EntityChanges } from '../../types';
import { AssetBase } from '../../entities/assets/AssetBase';
import { AttachmentType } from '../../entities/EntityType';
import { Attachment } from '../../entities/assets/Attachment';
import { get } from 'lodash';

export type AssetsState = Record<string, AssetBase[]>;

export class Assets {
  constructor(public readonly entityRepository: EntityRepository, public readonly store: IStore) {}

  assetsCache: Record<AttachmentType, Record<string, AssetBase>> = {
    [AttachmentType.Upload]: {},
    [AttachmentType.Actor]: {},
    [AttachmentType.Doc]: {},
    [AttachmentType.Query]: {},
    [AttachmentType.Schema]: {},
    [AttachmentType.Spec]: {},
  };

  update(changes: EntityChanges = { added: [], updated: [], deleted: [] }) {
    this.handleDeletions(changes);
    this.handleAdditions(changes);
    this.handleUpdates(changes);
  }

  private handleUpdates(changes: EntityChanges) {
    changes.updated.forEach((update) => {
      this.updateStore(update.entity.id);
    });
  }

  private handleAdditions(changes: EntityChanges) {
    changes.added.forEach((entity) => {
      this.updateStore(entity.id);
    });
  }

  private handleDeletions(changes: EntityChanges) {
    changes.deleted.forEach((entity) => {
      Object.entries(this.assetsCache).forEach(([, value]) => {
        if (value[entity.id]) {
          delete value[entity.id];
        }
      });
    });
  }

  private updateStore(entityId: string) {
    const _entity = this.entityRepository.get(entityId);
    const entity = _entity instanceof AssetBase ? _entity : _entity instanceof Attachment ? _entity.asset : null;
    if (!entity || !Attachment.dataSources[entity.type]) return;
    const currentState = this.store.getState();
    let currentAssets = get(currentState, Attachment.dataSources[entity.type], []);
    currentAssets.push(entity);
    currentAssets = currentAssets.filter((asset, index, self) => self.findIndex((t) => t.id === asset.id) === index);
    this.store.getState().setState(Attachment.dataSources[entity.type], [...currentAssets]);
  }

  public clear() {
    Object.values(Attachment.dataSources).forEach((dataSource) => {
      this.store.getState()[dataSource] = [];
    });
  }
}
