import { EntityType } from '../../entities/EntityType';
import { Attachment } from '../../entities/assets/Attachment';
import { EntityChanges } from '../../types';
import { IStore } from '../../data/Store';
import { EntityBase } from '../../entities/EntityBase';
import { SingleSourceModel } from '../../SingleSourceModel';
import { AssetBase } from '../../entities/assets/AssetBase';

export type FilterByItem = {
  type: string;
  icon: string;
  label: string;
};

export type FiltersMenuState = {
  items: FilterByItem[];
  assets: AssetBase[];
};

export const DEFAULT_FILTERS_MENU_STATE: FiltersMenuState = {
  items: [
    {
      type: EntityType.Label,
      icon: '/label.svg',
      label: 'Label',
    },
    {
      type: EntityType.Actor,
      icon: Attachment.iconMap[EntityType.Actor],
      label: 'Actor',
    },
    {
      type: EntityType.Doc,
      icon: Attachment.iconMap[EntityType.Doc],
      label: 'Doc',
    },
    {
      type: EntityType.Query,
      icon: Attachment.iconMap[EntityType.Query],
      label: 'Query',
    },
    {
      type: EntityType.Schema,
      icon: Attachment.iconMap[EntityType.Schema],
      label: 'Schema',
    },
    {
      type: EntityType.Spec,
      icon: Attachment.iconMap[EntityType.Spec],
      label: 'Spec',
    },
    {
      type: EntityType.Upload,
      icon: Attachment.iconMap[EntityType.Upload],
      label: 'Upload',
    },
  ],
  assets: [],
};

export class FiltersMenu {
  private filtersMenuCache = DEFAULT_FILTERS_MENU_STATE;

  constructor(private readonly model: SingleSourceModel, private readonly store: IStore) {}

  public update(changes: EntityChanges = { added: [], updated: [], deleted: [] }) {
    changes.updated.forEach((updatedEntity) => this.handleChange(updatedEntity.entity));
    changes.added.forEach((entity) => this.handleChange(entity));
    changes.deleted.forEach((entity) => {
      if (Attachment.isValidAttachmentType(entity.type)) {
        this.filtersMenuCache.assets = this.filtersMenuCache.assets.filter((asset) => asset.id !== entity.id);
        this.updateStore();
      }
    });
  }

  private handleChange(entity: EntityBase) {
    const asset = entity instanceof Attachment ? entity.asset : entity instanceof AssetBase ? entity : null;
    if (!asset) return;

    if (Attachment.isValidAttachmentType(asset.type)) {
      this.filtersMenuCache.assets.push(asset);
      this.filtersMenuCache.assets = this.filtersMenuCache.assets.filter(
        (asset, index, self) => index === self.findIndex((t) => t.id === asset.id),
      );
      this.updateStore();
    }

    if (this.filtersMenuCache.items.find((item) => item.type === asset.type)) {
      return;
    }
    this.filtersMenuCache.items.push({
      type: asset.type,
      icon: Attachment.iconMap[asset.type],
      label: asset.type,
    } satisfies FilterByItem);
    this.updateStore();
  }

  public clear() {
    this.filtersMenuCache = DEFAULT_FILTERS_MENU_STATE;
    this.updateStore();
  }

  private updateStore() {
    this.store.getState().setFiltersMenu(this.filtersMenuCache);
  }
}
