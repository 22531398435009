import { useSingleSourceStore } from '../store/single-source-store/single-source-store';
import { Organization } from '../state/types';
import { useCallback, useEffect } from 'react';
import { ORGANIZATIONS_QUERY } from '../graphql/queries';
import { apolloClient } from '../config/apollo/ApolloClient';
import { RegisterEntitiesCommand } from '@xspecs/single-source-model';
import { useApplication } from '../wrappers/application-context/application-context';

export const useActiveOrganization = () => {
  const { application } = useApplication();

  const organization: Organization | undefined = useSingleSourceStore.use.organization();
  const setOrganization = useSingleSourceStore.use.setOrganization();

  const refreshActiveOrganization = useCallback(async () => {
    if (!organization) return;
    const [response] = await apolloClient.refetchQueries({ include: [ORGANIZATIONS_QUERY] });
    const { organizations } = response.data;
    const foundOrganization = organizations.find((org) => org.id === organization.id);

    if (!foundOrganization) return;

    setOrganization(foundOrganization);
  }, [organization, setOrganization]);

  useEffect(() => {
    if (!organization || !application?.model) return;

    const entities: { constructs: any[]; assets: any[] } = {
      constructs: [],
      assets: [],
    };
    const apps: any[] = [];
    organization.registeredApps.forEach((app) => {
      if (!app.manifest) return;
      entities.constructs.push(...(app.manifest.entities?.constructs ?? []));
      entities.assets.push(...(app.manifest.entities?.assets ?? []));
      // @ts-ignore
      apps.push(app);
    });
    application.model.messageBus.send(RegisterEntitiesCommand, {
      entities,
      apps,
    });
  }, [application?.model, organization]);

  return {
    organization,
    setOrganization,
    refreshActiveOrganization,
  } as const;
};
