import { IconComponentProps } from '../icon';

export const NarrativeText = ({ color }: IconComponentProps) => {
  return (
    <>
      <path
        d="M36.3493 25.8611C36.3493 24.4544 36.8284 23.2564 37.7946 22.2516C38.7607 21.2546 39.8968 20.7522 41.2185 20.7522C42.6097 20.7522 43.7922 21.2469 44.7661 22.2207C45.7477 23.2023 46.2346 24.3694 46.2346 25.7297C46.2346 27.1595 45.7554 28.3807 44.797 29.3855C43.8386 30.398 42.6793 30.9003 41.3112 30.9003C39.9432 30.9003 38.7452 30.4057 37.7868 29.4164C36.8284 28.4271 36.3415 27.2368 36.3415 25.8456L36.3493 25.8611ZM37.64 25.8456C37.64 26.9508 37.9878 27.8706 38.6911 28.6203C39.3945 29.37 40.2678 29.741 41.3112 29.741C42.3546 29.741 43.2048 29.37 43.9004 28.6203C44.596 27.8706 44.9438 26.9431 44.9438 25.8301C44.9438 24.7172 44.5883 23.7665 43.885 23.04C43.1816 22.3135 42.2851 21.9502 41.1953 21.9502C40.2215 21.9502 39.379 22.3212 38.6834 23.0709C37.9801 23.8206 37.6323 24.7481 37.6323 25.8533L37.64 25.8456Z"
        fill={color}
      />
      <path
        d="M34.8885 19.191H33.6596V17.9621H34.8885V19.191ZM33.6596 30.8617V20.0798H34.8885V30.8617H33.6596Z"
        fill={color}
      />
      <path
        d="M30.4598 22.5762V17.9621H31.6964V30.9003H30.4598V29.2C30.027 29.7333 29.4937 30.1506 28.8599 30.4521C28.2184 30.7535 27.546 30.9003 26.8272 30.9003C25.4437 30.9003 24.2844 30.4134 23.3337 29.4396C22.383 28.4657 21.9116 27.2677 21.9116 25.8533C21.9116 24.4389 22.3753 23.2641 23.3105 22.3057C24.2457 21.3473 25.4128 20.8604 26.8195 20.8604C27.5769 20.8604 28.2648 21.0073 28.8831 21.301C29.5014 21.5947 30.027 22.0198 30.4521 22.584L30.4598 22.5762ZM30.4984 25.9461C30.4984 24.8563 30.1352 23.9211 29.4164 23.1482C28.6899 22.3753 27.8474 21.9889 26.8658 21.9889C25.8843 21.9889 24.9877 22.3598 24.2689 23.0941C23.5501 23.8283 23.1869 24.7249 23.1869 25.7915C23.1869 26.9122 23.5501 27.8551 24.2689 28.6126C24.9954 29.37 25.8688 29.7564 26.8967 29.7564C27.8397 29.7564 28.6821 29.3777 29.4087 28.6126C30.1352 27.8551 30.4984 26.9663 30.4984 25.9461Z"
        fill={color}
      />
      <path
        d="M12.4127 21.0614H13.6261V26.147C13.6261 27.4532 13.8348 28.3807 14.2599 28.9217C14.685 29.4628 15.3806 29.7333 16.3467 29.7333C16.8955 29.7333 17.3824 29.6251 17.7998 29.4009C18.2171 29.1768 18.5572 28.8522 18.8123 28.4271C18.9591 28.1797 19.0596 27.886 19.1291 27.546C19.191 27.2059 19.2219 26.6185 19.2219 25.776V21.0614H20.4508V30.8926H19.2219V29.6019C18.8509 30.0579 18.4335 30.3825 17.9775 30.5835C17.5215 30.7844 16.965 30.8849 16.3158 30.8849C14.9323 30.8849 13.9353 30.5139 13.3247 29.7642C12.7141 29.0145 12.4127 27.7778 12.4127 26.0466V21.0459V21.0614Z"
        fill={color}
      />
      <path
        d="M8.41684 30.8926V22.298H6.5928V21.0614H8.41684V17.9621H9.64574V21.0614H11.4698V22.298H9.64574V30.8926H8.41684Z"
        fill={color}
      />
      <path
        d="M0.0231859 27.631H1.28301V27.6696C1.28301 28.2648 1.45304 28.7594 1.79312 29.1536C2.13319 29.5478 2.55828 29.741 3.06839 29.741C3.5785 29.741 3.94949 29.5864 4.27411 29.285C4.591 28.9835 4.7533 28.6048 4.7533 28.1411C4.7533 27.6774 4.62964 27.3141 4.37459 27.0359C4.11953 26.7576 3.66352 26.4948 2.99883 26.2552C1.93996 25.8765 1.24436 25.4901 0.919744 25.0959C0.595128 24.7017 0.432819 24.1761 0.432819 23.5115C0.432819 22.7617 0.703332 22.128 1.24436 21.6024C1.78538 21.0768 2.44235 20.8218 3.21524 20.8218C4.03451 20.8218 4.68374 21.0536 5.16294 21.5174C5.64213 21.9811 5.90492 22.6304 5.93583 23.4573H4.62191C4.591 23.0013 4.45187 22.6535 4.18909 22.4062C3.92631 22.1589 3.5785 22.0352 3.13022 22.0352C2.72832 22.0352 2.39597 22.1666 2.12546 22.4371C1.85495 22.7076 1.72356 23.04 1.72356 23.4419C1.72356 23.7279 1.77766 23.9675 1.88586 24.1607C1.99407 24.3539 2.17956 24.5317 2.43462 24.6863C2.66649 24.8331 3.00656 24.9722 3.44711 25.1036C3.89539 25.235 4.18909 25.3355 4.34367 25.3973C4.9388 25.6524 5.37935 25.9924 5.66532 26.4175C5.95129 26.8426 6.09041 27.3837 6.09041 28.0406C6.09041 28.8676 5.80444 29.5478 5.22477 30.0888C4.6451 30.6298 3.91858 30.9003 3.03748 30.9003C2.15638 30.9003 1.4144 30.6066 0.865642 30.0192C0.316886 29.4318 0.0309158 28.6357 0 27.6387L0.0231859 27.631Z"
        fill={color}
      />
      <path
        d="M85.8145 11.3847H76.2847C76.3697 11.9026 76.6866 12.405 77.2354 12.8919C77.931 13.5257 78.7425 13.8348 79.67 13.8348C80.9839 13.8348 81.9269 13.3247 82.4988 12.3045H85.5904C85.2194 13.4406 84.5083 14.399 83.4495 15.1874C82.3133 16.0298 81.0535 16.4549 79.67 16.4549C77.8537 16.4549 76.3234 15.8366 75.0713 14.6077C73.8192 13.3711 73.1931 11.8794 73.1931 10.1327C73.1931 8.38591 73.796 6.89422 75.0094 5.6344C76.2615 4.31275 77.8151 3.65579 79.6545 3.65579C81.494 3.65579 83.063 4.32048 84.2996 5.65759C85.4358 6.90195 86 8.33181 86 9.93943C86 10.3877 85.9382 10.8669 85.8222 11.3847H85.8145ZM76.1997 9.08152H82.9857C82.8234 8.17723 82.406 7.47389 81.7491 6.97151C81.1617 6.5155 80.4661 6.28364 79.67 6.28364C78.9821 6.28364 78.3329 6.46913 77.7455 6.84012C76.9649 7.3425 76.447 8.08448 76.1997 9.08152Z"
        fill={color}
      />
      <path
        d="M66.6622 16.4627L62.218 3.65579H65.0777L67.7365 12.1422H67.7983L70.2948 3.65579H73.1931L68.7258 16.4627H66.6622Z"
        fill={color}
      />
      <path
        d="M58.3149 16.4627V3.65579H61.221V16.4627H58.3149ZM58.3149 2.66649V0H61.221V2.66649H58.3149Z"
        fill={color}
      />
      <path
        d="M52.8274 16.4627V6.39957H51.4052V3.65579H52.8274V0H55.7334V3.65579H57.4802V6.39957H55.7334V16.4627H52.8274Z"
        fill={color}
      />
      <path
        d="M50.3232 3.65579V16.4627H47.6567V14.5845C47.1929 15.1333 46.5592 15.5893 45.7554 15.9448C44.9515 16.2926 44.1786 16.4627 43.4212 16.4627C41.7904 16.4627 40.4146 15.883 39.2939 14.7314C38.1114 13.5025 37.524 11.9258 37.524 9.99353C37.524 8.17723 38.1037 6.67008 39.2708 5.4721C40.4533 4.25865 41.8909 3.65579 43.599 3.65579C44.6501 3.65579 45.6162 3.94949 46.5051 4.54462C47.1002 4.96198 47.4712 5.30979 47.618 5.5803H47.6644V3.65579H50.3309H50.3232ZM47.5717 10.0399C47.5717 8.87283 47.2007 7.93763 46.4587 7.22657C45.7863 6.60825 44.9825 6.29909 44.0318 6.29909C42.9111 6.29909 42.0145 6.70873 41.3421 7.53573C40.7315 8.26998 40.4224 9.13562 40.4224 10.1327C40.4224 11.1297 40.7624 12.0262 41.4503 12.7528C42.1382 13.4716 42.9961 13.8348 44.0318 13.8348C45.0675 13.8348 45.8945 13.4793 46.5669 12.776C47.2393 12.0726 47.5717 11.1606 47.5717 10.0476V10.0399Z"
        fill={color}
      />
      <path
        d="M32.0365 16.4627V3.6558H34.672V5.17067H34.7184C35.0662 4.66829 35.3754 4.30503 35.6459 4.09635C36.0787 3.80265 36.6275 3.64807 37.2922 3.64807H37.5318V6.52324C35.785 6.60053 34.9194 7.58984 34.9194 9.49116V16.4472H32.0442L32.0365 16.4627Z"
        fill={color}
      />
      <path
        d="M25.6369 16.4627V3.6558H28.2725V5.17067H28.3188C28.6667 4.66829 28.9758 4.30503 29.2463 4.09635C29.6791 3.80265 30.2279 3.64807 30.8926 3.64807H31.1322V6.52324C29.3854 6.60053 28.5198 7.58984 28.5198 9.49116V16.4472H25.6446L25.6369 16.4627Z"
        fill={color}
      />
      <path
        d="M24.7172 3.65579V16.4627H22.0507V14.5845C21.5869 15.1333 20.9532 15.5893 20.1494 15.9448C19.3456 16.2926 18.5727 16.4627 17.8152 16.4627C16.1844 16.4627 14.8087 15.883 13.688 14.7314C12.5054 13.5025 11.918 11.9258 11.918 9.99353C11.918 8.17723 12.4977 6.67008 13.6648 5.4721C14.8473 4.25865 16.2849 3.65579 17.993 3.65579C19.0441 3.65579 20.0102 3.94949 20.8991 4.54462C21.4942 4.96198 21.8652 5.30979 22.012 5.5803H22.0584V3.65579H24.7249H24.7172ZM21.9657 10.0399C21.9657 8.87283 21.5947 7.93763 20.8527 7.22657C20.1803 6.60825 19.3765 6.29909 18.4258 6.29909C17.3051 6.29909 16.4086 6.70873 15.7361 7.53573C15.1255 8.26998 14.8164 9.13562 14.8164 10.1327C14.8164 11.1297 15.1565 12.0262 15.8443 12.7528C16.5322 13.4716 17.3901 13.8348 18.4258 13.8348C19.4615 13.8348 20.2885 13.4793 20.9609 12.776C21.6333 12.0726 21.9657 11.1606 21.9657 10.0476V10.0399Z"
        fill={color}
      />
      <path
        d="M0.0231628 16.4627V3.65579H2.75921V5.14748C3.79489 4.15044 4.92332 3.65579 6.14449 3.65579C6.75508 3.65579 7.37339 3.78718 8.0149 4.0577C8.77233 4.3514 9.39065 4.79195 9.86212 5.37162C10.6196 6.29136 10.9983 7.75987 10.9983 9.76939V16.4627H8.03808V10.3491C8.03808 7.70576 7.21109 6.37638 5.54163 6.37638C4.68372 6.37638 4.00357 6.73964 3.51665 7.46616C3.16112 7.984 2.98335 9.01968 2.98335 10.5655V16.4549H0.0231628V16.4627Z"
        fill={color}
      />
    </>
  );
};
