import { Box, styled, Tooltip } from '@mui/material';
import { AttachmentNodeData, EntityType } from '@xspecs/single-source-model';
import { AssetAssigner } from '../asset-assginer/asset-assigner';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Overlay } from '../../constructs/overlay/overlay';
import { ErrorOutline } from '@mui/icons-material';
import { useIntl } from 'react-intl';
import { LinkIcon } from '../../links/link-icon';
import { UploadName } from './upload-name';

type AttachmentsItemProps = {
  attachmentId: string;
  data: AttachmentNodeData;
  selected: boolean;
  hasOverlay: boolean;
};

export const AttachmentItem = (props: AttachmentsItemProps) => {
  const { attachmentId, selected, data, hasOverlay } = props;

  const { formatMessage: f } = useIntl();

  const [clickCount, setClickCount] = useState(0);

  const containerRef = useRef(null);

  const handleMouseDownCapture = useCallback(() => {
    if (clickCount === 0 && selected) {
      setClickCount(2);
    } else {
      setClickCount(clickCount + 1);
    }
  }, [clickCount, selected, setClickCount]);

  const boxShadow = data.isFloating ? '5px 5px 10px #c3c3c3' : 'none';

  useEffect(() => {
    if (data.isNew) {
      setClickCount(2);
    }
  }, [data.isNew]);

  useEffect(() => {
    if (data.isDragging) {
      setClickCount(1);
    }
  }, [data.isDragging]);

  const containerSx = useMemo(
    () => ({
      display: clickCount > 0 ? 'none' : 'block',
      position: 'absolute',
      height: '100%',
      width: '100%',
      zIndex: 1,
    }),
    [clickCount],
  );

  const hasWarning = !data.asset;

  return (
    <Root clickCount={clickCount} ref={containerRef} tabIndex={0}>
      {hasOverlay ? <Overlay /> : null}
      <Content boxShadow={boxShadow} hasWarning={hasWarning}>
        <Box sx={containerSx} onMouseDownCapture={handleMouseDownCapture} />
        {data.metadata?.favicon ? (
          <div style={favIconContainer}>
            <LinkIcon favicon={data.metadata.favicon} url={data.url} />
          </div>
        ) : (
          <img width={24} height={24} style={imgStyle} src={data.iconUrl} alt="attachment" />
        )}
        {data.subType === EntityType.Upload && data.asset ? (
          <UploadName id={data.asset.id} name={data.asset.name} />
        ) : (
          <AssetAssigner attachmentId={attachmentId} data={data} />
        )}
        {hasWarning ? (
          <Tooltip title={f({ id: 'asset-missing-on-attachment' })} placement="top">
            <ErrorOutline color="warning" fontSize="small" />
          </Tooltip>
        ) : null}
      </Content>
    </Root>
  );
};

const imgStyle = {
  width: '24px',
  height: '24px',
  minWidth: '24px',
  minHeight: '24px',
};

const Root = styled('div')<{ clickCount: number }>(({ clickCount }) => ({
  position: 'relative',
  cursor: clickCount > 1 ? 'text' : 'grab',
  flex: 'none',
  outline: 'none',
  width: '100%',
  height: '100%',
}));

const Content = styled('div')<{ boxShadow: string; hasWarning: boolean }>(({ theme, boxShadow, hasWarning }) => ({
  height: '100%',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  gap: '1px',
  border: `1px solid ${hasWarning ? theme.palette.warning.main : theme.palette.text.secondary}`,
  borderRadius: 4,
  zIndex: 0,
  backgroundColor: 'white',
  boxShadow: boxShadow,
}));

const favIconContainer = {
  marginLeft: 2,
  marginRight: 2,
  width: '22px',
  height: '22px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};
