import { Modal } from '../../modal/modal';
import { useSingleSourceStore } from '../../../store/single-source-store/single-source-store';
import { useCallback, useEffect, useState } from 'react';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { LaunchOutlined } from '@mui/icons-material';
import { useIntl } from 'react-intl';

export const UrlPreviewModal = () => {
  const { formatMessage: f } = useIntl();
  const urlToPreview = useSingleSourceStore.use.urlToPreview();
  const setUrlToPreview = useSingleSourceStore.use.setUrlToPreview();

  const [image, setImage] = useState<HTMLImageElement | null>(null);

  const onClose = useCallback(() => {
    setUrlToPreview(null);
    setImage(null);
  }, [setUrlToPreview]);

  const onOpenLink = useCallback(() => {
    if (urlToPreview?.url) window.open(urlToPreview?.url, '_blank');
  }, [urlToPreview?.url]);

  useEffect(() => {
    if (urlToPreview?.url) {
      const img = new Image();
      img.src = urlToPreview.url;
      img.onload = () => {
        setImage(img);
      };
    }
  }, [urlToPreview?.url]);

  return (
    <Modal
      id="UrlPreviewModal"
      isOpen={Boolean(urlToPreview)}
      onClose={onClose}
      headerStartContent={
        <Stack direction="row" justifyContent="space-between" flexGrow={1}>
          <Stack direction="row" gap={0.5} alignItems="center">
            {urlToPreview?.favicon ? <img src={urlToPreview?.favicon} width={20} height={20} /> : null}
            <Typography>{urlToPreview?.title ?? f({ id: 'preview' })}</Typography>
          </Stack>
          <IconButton sx={{ position: 'relative', top: '-12px', left: '12px' }} onClick={onOpenLink}>
            <LaunchOutlined fontSize="inherit" />
          </IconButton>
        </Stack>
      }
      rootSxOverrides={{
        width: { xs: '100%', md: 900 },
        height: { xs: '100%', md: 'auto', maxHeight: 'min(1200px, 90vh)' },
      }}
    >
      <Box
        flexGrow={1}
        sx={{ overflow: 'scroll', display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}
      >
        <img src={urlToPreview?.url} alt="ImagePreview" height="100%" />
      </Box>
      <Typography variant="tooltip">{image ? `${image.width}x${image.height} px` : null}</Typography>
    </Modal>
  );
};
