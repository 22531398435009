import { CommandBase, IParams } from '../framework/CommandBase';
import { EventBase } from '../framework/EventBase';
import { EventHandlerBase } from '../framework/EventHandlerBase';
import { EntitiesMovedEvent } from './MoveEntitiesCommand';
import { ScriptBase } from '../../entities/scripts/ScriptBase';
import { EntityBase } from '../../entities/EntityBase';
import { ConstructBase } from '../../entities/constructs/ConstructBase';
import { EntryData } from '../../read-models/boundary/BoundariesIndex';
import { EntitiesAddedEvent } from './AddEntityCommand';
import { Attachment } from '../../entities/assets/Attachment';
import { CommandError } from '../../ErrorStore';
import { Preview } from '../../entities/assets/Preview';
import { Position } from '../../types';
import { Upload } from '../../entities/assets/Upload';
import { AttachmentType } from '../../entities/EntityType';

interface EntitiesIngestedEventParams extends IParams {
  ingested: { entityId: string; targetId: string; frameId?: number; laneId?: number }[];
  ejected: { entityId: string }[];
  affected: { entityId: string }[];
}

export class EntitiesIngestedEvent extends EventBase {
  static eventType = 'EntitiesIngestedEvent';

  constructor(public readonly params: EntitiesIngestedEventParams, public readonly source = IngestEntitiesCommand) {
    super();
  }
}

interface IngestEntitiesCommandParams extends IParams {
  entityIds: string[];
  ignoreIds?: string[];
  cursorPosition?: Position;
}

export class IngestEntitiesCommand extends CommandBase<IngestEntitiesCommandParams> {
  execute(params: IngestEntitiesCommandParams): EntitiesIngestedEvent | CommandError {
    const ingested: EntitiesIngestedEventParams['ingested'] = [];
    const ejected: EntitiesIngestedEventParams['ejected'] = [];
    const affected: EntitiesIngestedEventParams['affected'] = [];

    const errors = params.entityIds
      .map((entityId) => {
        if (params.ignoreIds && params.ignoreIds.includes(entityId)) return;
        const entity = this.model.entities.get(entityId)!;
        const useCursorPosition = params.cursorPosition && entity instanceof Preview;
        const intersectingEntities = useCursorPosition
          ? this.model.boundariesIndex.getIntersectingEntitiesData(params.cursorPosition!)
          : this.model.boundariesIndex.getIntersectingEntitiesData(entity);
        const precedentTarget = this.determinePrecedentTarget(entity, intersectingEntities);

        if (entity instanceof ScriptBase) return;

        if (
          (!precedentTarget && entity.parent instanceof ScriptBase) ||
          (!precedentTarget && entity.parent instanceof ConstructBase)
        ) {
          // is dropped onto canvas
          const parent = entity.parent;
          const response = parent.eject(entity);
          this.model.entityRepository.update(parent);
          this.model.entityRepository.update(entity);
          if (!response) return parent.getError();
          ejected.push({ entityId });
          affected.push({ entityId: parent.id });
        }

        if (
          precedentTarget &&
          (precedentTarget.entity instanceof ScriptBase || precedentTarget.entity instanceof ConstructBase)
        ) {
          if (entity.parent instanceof ScriptBase || entity.parent instanceof ConstructBase) {
            const parent = entity.parent;
            parent.eject(entity);
            affected.push({ entityId: parent.id });
            this.model.entityRepository.update(parent);
            this.model.entityRepository.update(entity);
          }

          // if this is a preview with its attachment already attached to construct, we need to eject it first
          if (entity instanceof Preview && entity?.parent?.parent instanceof ConstructBase) {
            const construct = entity.parent.parent;
            construct.eject(entity.parent);
            this.model.entityRepository.update(construct);
            this.model.entityRepository.update(entity.parent);
          }
          const response = precedentTarget.entity.ingestEntity(
            entity,
            precedentTarget.entry.frameId!,
            precedentTarget.entry.laneId!,
          );
          this.model.entityRepository.update(entity);
          this.model.entityRepository.update(precedentTarget.entity);
          if (!response) return precedentTarget.entity.getError();
          ingested.push({
            entityId,
            targetId: precedentTarget.entity.id,
            frameId: precedentTarget.entry.frameId,
            laneId: precedentTarget.entry.laneId,
          });
        }
      })
      .filter(Boolean);

    if (errors.length) return CommandError.of(new Error(errors.join('\n')), 'warning');
    return new EntitiesIngestedEvent({ ingested, ejected, affected });
  }

  private determinePrecedentTarget(
    entity: EntityBase,
    intersectingEntities: EntryData[],
  ):
    | {
        entity: EntityBase;
        entry: EntryData;
      }
    | undefined {
    if (entity instanceof ConstructBase)
      return intersectingEntities
        .filter((e) => this.isScript(e))
        .map((e) => ({ entity: this.model.entities.get(e.scriptId!)!, entry: e }))[0]!;

    if (entity instanceof Attachment)
      return intersectingEntities
        .filter((e) => this.isConstruct(e))
        .map((e) => ({ entity: this.model.entities.get(e.entityId)!, entry: e }))[0]!;

    if (entity instanceof Preview) {
      return intersectingEntities
        .filter((e) => this.isConstruct(e))
        .map((e) => ({ entity: this.model.entities.get(e.entityId)!, entry: e }))[0]!;
    }
  }

  private isScript(e: EntryData) {
    return e.scriptId && this.model.entities.get(e.scriptId) instanceof ScriptBase;
  }

  private isConstruct(e: EntryData) {
    return e.entity instanceof ConstructBase;
  }
}

export class IngestCommandPolicy extends EventHandlerBase {
  handles() {
    return [EntitiesAddedEvent, EntitiesMovedEvent];
  }

  execute(event: EventBase) {
    switch (event.type) {
      case EntitiesMovedEvent.type:
        const entitiesMovedEvent = event as EntitiesMovedEvent;
        return this.model.messageBus.sendInternal(IngestEntitiesCommand, {
          entityIds: entitiesMovedEvent.params.entityIds,
          ignoreIds: entitiesMovedEvent.params.skipIngestIds,
          cursorPosition: entitiesMovedEvent.params.cursorPosition,
        });
      case EntitiesAddedEvent.type:
        const entitiesAddedEvent = event as EntitiesAddedEvent;

        const entityIds = entitiesAddedEvent.params.entityIds
          .map((entityId) => {
            const entity = this.model.entities.get(entityId);
            if (entity instanceof Preview) return null;
            if (entity instanceof Upload) return null;
            if (entity instanceof Attachment) {
              if (entity.subType === AttachmentType.Upload) return null;
            }
            return entityId;
          })
          .filter(Boolean) as string[];

        return this.model.messageBus.sendInternal(IngestEntitiesCommand, {
          entityIds: entityIds,
        });
    }
  }
}
