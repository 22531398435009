import { EntityType } from '@xspecs/single-source-model';
import { Icon, IconProps } from '../../icons/icon';
import { useTheme } from '@mui/material';
import { FC } from 'react';

const map: Record<string, FC> = {
  [EntityType.Label]: (props) => {
    const theme = useTheme();

    return <Icon {...props} name="box" color={theme.palette.constructs[EntityType.Label].color} />;
  },
  [EntityType.Filter]: (props) => {
    const theme = useTheme();

    return <Icon {...props} name="box" color={theme.palette.constructs[EntityType.Filter].color} />;
  },
  [EntityType.Capability]: (props) => {
    const theme = useTheme();

    return <Icon {...props} name="box" color={theme.palette.constructs[EntityType.Capability].color} />;
  },
  [EntityType.Edge]: (props) => {
    return <Icon {...props} name="box" />;
  },
  [EntityType.Action]: (props) => {
    const theme = useTheme();

    return <Icon {...props} name="box" color={theme.palette.constructs[EntityType.Action].color} />;
  },
  [EntityType.Actor]: (props) => {
    return <Icon {...props} name="actor" />;
  },
  [EntityType.Command]: (props) => {
    const theme = useTheme();

    return <Icon {...props} name="box" color={theme.palette.constructs[EntityType.Command].color} />;
  },
  [EntityType.Constraints]: (props) => {
    const theme = useTheme();

    return <Icon {...props} name="box" color={theme.palette.constructs[EntityType.Constraints].color} />;
  },
  [EntityType.Data]: (props) => {
    const theme = useTheme();

    return <Icon {...props} name="box" color={theme.palette.constructs[EntityType.Data].color} />;
  },
  [EntityType.Event]: (props) => {
    const theme = useTheme();

    return <Icon {...props} name="box" color={theme.palette.constructs[EntityType.Event].color} />;
  },
  [EntityType.ExternalSystem]: (props) => {
    const theme = useTheme();

    return <Icon {...props} name="box" color={theme.palette.constructs[EntityType.ExternalSystem].color} />;
  },
  [EntityType.Gateway]: (props) => {
    const theme = useTheme();

    return <Icon {...props} name="box" color={theme.palette.constructs[EntityType.Gateway].color} />;
  },
  [EntityType.Interface]: (props) => {
    const theme = useTheme();

    return <Icon {...props} name="box" color={theme.palette.constructs[EntityType.Interface].color} />;
  },
  [EntityType.Moment]: (props) => {
    const theme = useTheme();

    return <Icon {...props} name="box" color={theme.palette.constructs[EntityType.Moment].color} />;
  },
  [EntityType.Process]: (props) => {
    const theme = useTheme();

    return <Icon {...props} name="box" color={theme.palette.constructs[EntityType.Process].color} />;
  },
  [EntityType.Projection]: (props) => {
    const theme = useTheme();

    return <Icon {...props} name="box" color={theme.palette.constructs[EntityType.Projection].color} />;
  },
  [EntityType.ReadModel]: (props) => {
    const theme = useTheme();

    return <Icon {...props} name="box" color={theme.palette.constructs[EntityType.ReadModel].color} />;
  },

  [EntityType.Doc]: (props) => {
    return <Icon {...props} name="doc" color="#4274A2" />;
  },
  [EntityType.Query]: (props) => {
    return <Icon {...props} name="query" color="#D346A7" />;
  },
  [EntityType.Resolver]: (props) => {
    const theme = useTheme();

    return <Icon {...props} name="box" color={theme.palette.constructs[EntityType.Resolver].color} />;
  },
  [EntityType.Schema]: (props) => {
    return <Icon {...props} name="key-value-pairs" />;
  },
  [EntityType.Spec]: (props) => {
    const theme = useTheme();

    return <Icon {...props} name="spec" color={theme.palette.primary.main} />;
  },

  [EntityType.NarrativeScript]: (props) => {
    const theme = useTheme();

    return <Icon {...props} name="script" color={theme.palette.primary.main} />;
  },

  [EntityType.ActionScript]: (props) => {
    const theme = useTheme();

    return <Icon {...props} name="script" color={theme.palette.primary.main} />;
  },

  [EntityType.Attachment]: (props) => {
    return <Icon {...props} name="box" />;
  },
  [EntityType.Upload]: (props) => {
    return <Icon {...props} name="box" />;
  },
  [EntityType.Comment]: (props) => {
    const theme = useTheme();

    return <Icon {...props} name="box" color={theme.palette.primary.main} />;
  },
  [EntityType.Thread]: (props) => {
    const theme = useTheme();

    return <Icon {...props} name="box" color={theme.palette.primary.main} />;
  },
  [EntityType.Narrative]: (props) => {
    const theme = useTheme();

    return <Icon {...props} name="script" color={theme.palette.primary.main} />;
  },
  [EntityType.Preview]: () => {
    throw new Error('Preview entity type should not be rendered');
  },
};

type EntityIconProps = {
  type: EntityType | string;
} & Partial<Omit<IconProps, 'name'>>;

export const EntityIcon = (props: EntityIconProps) => {
  const { type, ...rest } = props;

  const Icon = map[type];

  if (!Icon) {
    // eslint-disable-next-line no-console
    console.warn(`No icon found for entity type: ${type}`);
    return null;
  }

  return <Icon {...rest} />;
};
