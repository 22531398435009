import { FC, ReactNode, useRef } from 'react';
import { useSingleSourceStore } from '../../store/single-source-store/single-source-store';
import { Status } from '@xspecs/single-source-model';
import { Loading } from '../../components/loading/loading';
import { FileStatusModal } from '../../components/file-status/file-status-modal';

type ModelStatusWrapperProps = {
  id: string;
  children: ReactNode;
};

export const ModelStatusWrapper: FC<ModelStatusWrapperProps> = (props) => {
  const { id, children } = props;
  const file = useSingleSourceStore.use.filesById()[id];

  const isFirstLoadRef = useRef(true);

  if (!file) {
    return null;
  }

  if (isFirstLoadRef.current) {
    if (file.status === Status.Connecting || file.status === Status.Unsynced) {
      return <Loading />;
    }

    if (file.status === Status.Disconnected) {
      return <FileStatusModal isOpen={file.status === Status.Disconnected} status={file.status} />;
    }
  }

  isFirstLoadRef.current = false;
  return <>{children}</>;
};
