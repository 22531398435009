import { Box, Link, SxProps, Theme } from '@mui/material';
import { useIntl } from 'react-intl';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

interface FilterSelectorBackButtonProps {
  goBack: () => void;
}

export const FilterSelectorBackButton = (props: FilterSelectorBackButtonProps) => {
  const { goBack } = props;
  const { formatMessage: f } = useIntl();

  return (
    <Box sx={wrapperSx}>
      <Link component="button" variant="caption" color="text.primary" onClick={goBack}>
        <ArrowBackIcon fontSize="small" sx={arrowSx} />
        <span>{f({ id: 'go-back' })}</span>
      </Link>
    </Box>
  );
};

const wrapperSx: SxProps<Theme> = { px: 2 };
const arrowSx: SxProps<Theme> = { width: '16px', height: '16px', mb: 0.5 };
