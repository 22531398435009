import { CommandBase, IParams } from '../framework/CommandBase';
import { EventBase } from '../framework/EventBase';
import { CommandError } from '../../ErrorStore';
import { DynamicEntityRegistry } from '../../apps/DynamicEntityRegistry';

interface RegisterEntitiesParams extends IParams {
  apps: any[];
  entities: {
    constructs: Array<any>;
    assets: Array<any>;
  };
}

export class RegisterEntitiesCommand extends CommandBase<RegisterEntitiesParams> {
  execute(params: RegisterEntitiesParams): EventBase | CommandError | undefined {
    const { entities, apps } = params;

    apps.forEach((app) => {
      this.model.workerManager.addWorker({
        appName: app.name,
        workerScriptUrl: app.manifest.appUrl,
      });
    });

    DynamicEntityRegistry.registerEntities(entities);
    this.model.palette.addConstructs(entities.constructs);
    this.model.palette.addAssets(entities.assets);
    return;
  }
}
