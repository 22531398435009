import { ToolbarElement } from '@xspecs/single-source-model';
import { Divider } from './divider';
import { Textbox } from './textbox';
import { Tabs } from './tabs';
import { Switch } from './switch';
import { Category } from './category';
import { CategoryCompact } from './category-compact';
import { IconButton } from './icon-button';
import { List } from './list';
import { MenuButton } from './menu-button';

export const renderPaletteComponent = ({ element, key }: { element: ToolbarElement; key: string }) => {
  switch (element.type) {
    case 'divider':
      return <Divider key={key} element={element} />;
    case 'textbox':
      return <Textbox key={key} element={element} />;
    case 'tabs':
      return <Tabs key={key} element={element} />;
    case 'switch':
      return <Switch key={key} element={element} />;
    case 'category':
      return <Category key={key} element={element} />;
    case 'category-compact':
      return <CategoryCompact key={key} element={element} />;
    case 'icon-button':
      return <IconButton key={key} element={element} />;
    case 'list':
      return <List key={key} element={element} />;
    case 'menu-button':
      return <MenuButton key={key} element={element} />;
    default:
      return null;
  }
};
