import { FC, useCallback, useMemo } from 'react';
import { Modal } from '../modal/modal';
import { Button, Stack, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { CloudOff, Logout } from '@mui/icons-material';
import { RoutePaths } from '../../config/route-paths/route-paths';
import { useAuth } from '../../auth';
import { Status } from '@xspecs/single-source-model';
import { useActiveWorkspace } from '../../hooks/use-active-workspace';
import { logger } from '@xspecs/logger';

type FileStatusModalProps = {
  isOpen: boolean;
  status: Status;
};

export const FileStatusModal: FC<FileStatusModalProps> = (props) => {
  const { isOpen, status } = props;

  const { formatMessage: f } = useIntl();
  const { logout } = useAuth();
  const { workspace } = useActiveWorkspace();

  const onGoToLogin = useCallback(async () => {
    logout({ logoutParams: { returnTo: window.location.origin + RoutePaths.Login } }).catch((e) => {
      logger.error(e);
    });
  }, [logout]);

  const onTryAgain = useCallback(() => {
    window.location.reload();
  }, []);

  const statusPropertiesMap = useMemo(
    () => ({
      [Status.Disconnected]: {
        title: f({ id: 'cannot-load-workspace' }, { name: workspace?.name }),
        subtitle: f({ id: 'cannot-load-workspace-description' }),
        Icon: CloudOff,
        buttonText: f({ id: 'try-again' }),
        onClick: onTryAgain,
        variant: 'error',
      },
      [Status.AuthenticationFailed]: {
        title: f({ id: 'you-have-been-logged-out' }),
        subtitle: f({ id: 'please-login-again' }),
        Icon: Logout,
        buttonText: f({ id: 'go-to-login' }),
        onClick: onGoToLogin,
        variant: 'primary',
      },
    }),
    [f, onGoToLogin, onTryAgain, workspace?.name],
  );

  if (!statusPropertiesMap[status]) return null;

  const { title, subtitle, Icon, buttonText, onClick, variant } = statusPropertiesMap[status];

  return (
    <Modal id="AuthenticationFailedModal" isOpen={isOpen} rootSxOverrides={{ width: 702 }}>
      <Stack gap={6} alignItems="flex-end">
        <Stack width="100%" gap={1.5}>
          <Stack gap={1} direction="row" alignItems="center" justifyContent="center">
            <Icon color={variant} fontSize="large" />
            <Typography variant="h6" textAlign="center">
              {title}
            </Typography>
          </Stack>
          <Typography textAlign="center">{subtitle}</Typography>
        </Stack>
        <Button size="small" variant="contained" onClick={onClick}>
          {buttonText}
        </Button>
      </Stack>
    </Modal>
  );
};
