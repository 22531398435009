import { useEffect } from 'react';
import { Analytics } from '@xspecs/single-source-model';
import { AppTypeEvent } from './use-track-events';
import { getUser } from '../lib/utils';
import dayjs from 'dayjs';

const THRESHOLD_HOURS = 2;

export const useTrackUserLoggedIn = () => {
  useEffect(() => {
    const trackUserLoggedIn = () => {
      const user = getUser();
      Analytics.getInstance().track({
        event: AppTypeEvent.LoggedIn,
        params: {
          id: user.id,
          email: user.email,
        },
      });
    };

    const lastLoggedIn = localStorage.getItem('lastLoggedIn') || '';
    const now = dayjs().toISOString();

    if (!lastLoggedIn) {
      trackUserLoggedIn();
      localStorage.setItem('lastLoggedIn', now);
      return;
    }

    const lastLoggedInTime = dayjs(lastLoggedIn);
    if (dayjs().diff(lastLoggedInTime, 'hour') > THRESHOLD_HOURS) {
      trackUserLoggedIn();
      return;
    }
  }, []);
};
