import ImageIcon from '@mui/icons-material/Image';
import { Box } from '@mui/material';
import { Fragment } from 'react';
import { UploadLinkOverlay } from '../single-source-model/canvas/links/upload-link-overlay';

type ImageCanvasNodeProps = {
  url: string;
  title: string;
};

const imageStyle = { width: '100%', height: '100%' };
const sx = {
  height: '100%',
  width: '100%',
  backgroundColor: 'background.paper',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};
export const ImageCanvasNode = (props: ImageCanvasNodeProps) => {
  const { url, title } = props;

  return (
    <Fragment>
      <UploadLinkOverlay url={url} title={title} expand openInNewTab />
      {url ? (
        <img alt="NodeImage" style={imageStyle} src={url} />
      ) : (
        <Box sx={sx}>
          <ImageIcon color="action" />
        </Box>
      )}
    </Fragment>
  );
};
