import { CommandBase, IParams } from '../framework/CommandBase';
import { ScriptBase } from '../../entities/scripts/ScriptBase';
import { EventBase } from '../framework/EventBase';
import { CommandError } from '../../ErrorStore';

interface DeleteScriptLaneParams extends IParams {
  scriptId: string;
  laneIndex: number;
}

interface ScriptLaneDeletedParams extends IParams {
  scriptId: string;
  laneIndex: number;
}

export class ScriptLaneDeletedEvent extends EventBase {
  static eventType = 'ScriptLaneDeletedEvent';

  constructor(public readonly params: ScriptLaneDeletedParams, public readonly source = DeleteScriptLaneCommand) {
    super();
  }
}

export class DeleteScriptLaneCommand extends CommandBase<DeleteScriptLaneParams> {
  execute(params: DeleteScriptLaneParams): ScriptLaneDeletedEvent | CommandError {
    const script = this.model.entityRepository.get<ScriptBase>(params.scriptId)!;
    const entities = script.getEntitiesInLane(params.laneIndex);
    const entityIdsToDelete = entities.map((entity) => entity.id);
    if (!script.removeLane(params.laneIndex)) return CommandError.of(script.getError()!, 'error');
    entityIdsToDelete.forEach((entityId) => this.model.entityRepository.delete(entityId));
    this.model.entityRepository.update(script);
    return new ScriptLaneDeletedEvent({ scriptId: params.scriptId, laneIndex: params.laneIndex });
  }
}
