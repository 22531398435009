import { IconName } from './icon';

export const getIconForUrl = (url: string): IconName | undefined => {
  if (!url) return undefined;

  if (url.includes('jira')) {
    return 'jira';
  }
  if (url.includes('atlassian.net')) {
    return 'confluence';
  }
  if (url.includes('slack.com')) {
    return 'slack';
  }
  if (url.includes('linear.app')) {
    return 'linear';
  }
  if (url.includes('notion.so')) {
    return 'notion';
  }
  if (url.includes('miro.com')) {
    return 'miro';
  }
  if (url.includes('docs.google.com/spreadsheets')) {
    return 'google-sheets';
  }
  if (url.includes('docs.google.com/document')) {
    return 'google-docs';
  }
  if (url.includes('figma.com')) {
    return 'figma';
  }
};
