import { EdgeLabelRenderer, useViewport } from '@xyflow/react';
import { useSingleSourceStore } from '../../../store/single-source-store/single-source-store';
import { Box } from '@mui/material';
import { useSingleSourceModel } from '../../../hooks/use-single-source-model';
import { useActiveOrganization } from '../../../hooks/use-active-organization';

export function Cursors() {
  const viewport = useViewport();

  const { organization } = useActiveOrganization();
  const model = useSingleSourceModel();
  const _activeUsers = organization?.singleSourceModel?.id
    ? useSingleSourceStore.use.activeUsersByFile()[organization.singleSourceModel.id]
    : [];
  const activeUsers = _activeUsers?.filter((user) => user.id !== model?.entities.activeUser.id) ?? [];

  return (
    <EdgeLabelRenderer>
      {activeUsers
        .filter((user) => user.position)
        .map(({ id, color, name, position }) => {
          if (!position) return null;
          const translate = `translate(${position.x}px, ${position.y}px)`;
          const scale = `scale(${1 / viewport.zoom} )`;

          return (
            <Box
              key={id}
              sx={{
                transform: translate,
                zIndex: 'calc(infinity)',
                position: 'relative',
              }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" style={{ overflow: 'visible' }}>
                <g
                  style={{
                    transform: scale,
                    position: 'absolute',
                  }}
                >
                  <path d="m0 0 7.07 17 2.51-7.39L18 9.07z" fill={color} fillRule="evenodd" clipRule="evenodd" />
                  <text x="20" y="20" fill={color}>
                    {name}
                  </text>
                </g>
              </svg>
            </Box>
          );
        })}
    </EdgeLabelRenderer>
  );
}
