import { Stack } from '@mui/material';
import { OrganizationsQuery } from '../../../../gql/graphql';
import { AppsListItem } from './item/apps-list-item';

type AppsListProps = {
  apps: OrganizationsQuery['organizations'][0]['registeredApps'];
};

export const AppsList = (props: AppsListProps) => {
  const { apps } = props;

  return (
    <Stack direction="column" gap={2} alignItems="flex-start" justifyContent="center">
      {apps.map((app) => (
        <AppsListItem key={app.id} {...app} />
      ))}
    </Stack>
  );
};
