export const Z_INDEXES = {
  Default: 0,
  Script: 1000,
  Edge: 3000,
  ConstructInsideFrame: 4000,
  Construct: 5000,
  Capability: 5000,
  Attachment: 6000,
  Annotation: 6000,
  Thread: 7000,
  ScriptBeingDragged: 7500,
  ConstructBeingDragged: 8000,
  ConstructEdgesDragged: 8500,
  DebugNode: 9000,
} as const;
