import { FC } from 'react';
import { IconComponentProps } from '../icon';

export const ShowSidebar: FC<IconComponentProps> = () => {
  return (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.75 6C22.75 4.74 22.25 3.532 21.359 2.641C20.468 1.75 19.26 1.25 18 1.25H6C4.74 1.25 3.532 1.75 2.641 2.641C1.75 3.532 1.25 4.74 1.25 6V18C1.25 19.26 1.75 20.468 2.641 21.359C3.532 22.25 4.74 22.75 6 22.75H18C19.26 22.75 20.468 22.25 21.359 21.359C22.25 20.468 22.75 19.26 22.75 18V6Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.75 6C22.75 4.74 22.25 3.532 21.359 2.641C20.468 1.75 19.26 1.25 18 1.25H6C4.74 1.25 3.532 1.75 2.641 2.641C1.75 3.532 1.25 4.74 1.25 6V18C1.25 19.26 1.75 20.468 2.641 21.359C3.532 22.25 4.74 22.75 6 22.75H18C19.26 22.75 20.468 22.25 21.359 21.359C22.25 20.468 22.75 19.26 22.75 18V6ZM21.25 6V18C21.25 18.862 20.908 19.689 20.298 20.298C19.689 20.908 18.862 21.25 18 21.25H10L10 2.75H18C18.862 2.75 19.689 3.092 20.298 3.702C20.908 4.311 21.25 5.138 21.25 6ZM6 21.25H8.5L8.5 2.75H6C5.138 2.75 4.311 3.092 3.702 3.702C3.092 4.311 2.75 5.138 2.75 6V18C2.75 18.862 3.092 19.689 3.702 20.298C4.311 20.908 5.138 21.25 6 21.25ZM11.75 12.798H17.4394L15.7197 14.6279C15.6481 14.7016 15.591 14.7896 15.5517 14.887C15.5124 14.9843 15.4917 15.0891 15.4908 15.195C15.4899 15.301 15.5089 15.4061 15.5466 15.5042C15.5843 15.6022 15.64 15.6913 15.7104 15.7663C15.7809 15.8412 15.8646 15.9004 15.9568 15.9406C16.0489 15.9807 16.1477 16.0009 16.2473 16C16.3469 15.999 16.4453 15.977 16.5368 15.9352C16.6283 15.8934 16.711 15.8326 16.7802 15.7564L19.7802 12.5642C19.8499 12.4901 19.9052 12.4022 19.9429 12.3054C19.9806 12.2086 20 12.1048 20 12C20 11.8952 19.9806 11.7914 19.9429 11.6946C19.9052 11.5978 19.8499 11.5099 19.7802 11.4358L16.7802 8.24363C16.711 8.16741 16.6283 8.10662 16.5368 8.06479C16.4453 8.02297 16.3469 8.00095 16.2473 8.00003C16.1477 7.99911 16.0489 8.0193 15.9568 8.05943C15.8646 8.09955 15.7809 8.15881 15.7104 8.23374C15.64 8.30867 15.5843 8.39777 15.5466 8.49585C15.5089 8.59393 15.4899 8.69901 15.4908 8.80497C15.4917 8.91094 15.5124 9.01566 15.5517 9.11302C15.591 9.21038 15.6481 9.29844 15.7197 9.37206L17.4394 11.202H11.75C11.5511 11.202 11.3603 11.286 11.2197 11.4357C11.079 11.5854 11 11.7883 11 12C11 12.2117 11.079 12.4146 11.2197 12.5643C11.3603 12.714 11.5511 12.798 11.75 12.798Z"
        fill="black"
        fillOpacity="0.54"
      />
    </>
  );
};
