import { ToolbarLayout } from './Toolbar.types';

export type ToolbarSelectedScheme = {
  filter: 'Construct' | 'Asset';
  scheme: string;
};

export type ToolbarState = {
  selectedEntity: string;
  selectedEntityIconUrl: string;
  isOpen: boolean;
  compactMode: boolean;
  layout: ToolbarLayout;
  currentFilter: string;
  selectedSchemes: ToolbarSelectedScheme[];
};

export const TOOLBAR_DEFAULT_STATE: ToolbarState = {
  isOpen: false,
  selectedEntity: '',
  selectedEntityIconUrl: '',
  currentFilter: '',
  selectedSchemes: [],
  compactMode: false,
  layout: {
    header: {
      rows: [],
    },
    body: {
      rows: [],
    },
  },
} satisfies ToolbarState;
