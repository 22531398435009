import { singleSourceStore } from '../store/single-source-store/single-source-store';
import { useCallback, useEffect, useState } from 'react';
import { isEqual } from 'lodash';

export const useReactiveState = <T>(path: string) => {
  const selector = usePathSelector(path);
  return useReactiveStateInternal(selector) as T;
};

const useReactiveStateInternal = <T>(selector: (state: any) => T) => {
  const [selectedState, setSelectedState] = useState(selector(singleSourceStore.getState()));

  useEffect(() => {
    const unsubscribe = singleSourceStore.subscribe(
      (state) => selector(state),
      (newSelectedState, prevSelectedState) => {
        if (!isEqual(newSelectedState, prevSelectedState)) setSelectedState(newSelectedState);
      },
    );

    return () => unsubscribe();
  }, [selector]);

  return selectedState;
};

const usePathSelector = (path: string) => {
  return useCallback(
    (state: any) => {
      const pathParts = path.split('.');
      return pathParts.reduce((acc, part) => acc && acc[part], state);
    },
    [path],
  );
};
