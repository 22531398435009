import { Box, Slide } from '@mui/material';
import { useIntl } from 'react-intl';
import { useCallback, useRef, useState } from 'react';
import { FiltersButtonMenuList, FiltersButtonMenuListProps } from './list/filters-button-menu-list';
import { LabelsSelector, LabelsSelectorProps } from '../../../labels/labels-selector/labels-selector';
import { FiltersListProps } from '../../filters-list/filters-list';
import { Attachment } from '@xspecs/single-source-model';
import { AssetsSelector, AssetsSelectorProps } from '../../../assets-selector/assets-selector';

export type View = 'Default' | 'Label' | string;

export type FiltersButtonMenuProps = {
  filtersButtonMenuListProps: Pick<FiltersButtonMenuListProps, 'filterByAssetsList'>;
  labelsSelectorProps: Omit<LabelsSelectorProps, 'hideManageLabels' | 'hideHeader' | 'placeholder'>;
  assetsSelectorProps: Omit<AssetsSelectorProps, 'hideManageLabels' | 'hideHeader' | 'placeholder' | 'type'>;
  filtersListProps?: FiltersListProps;
};

export const FiltersButtonMenu = (props: FiltersButtonMenuProps) => {
  const { labelsSelectorProps, filtersListProps, assetsSelectorProps, filtersButtonMenuListProps } = props;

  const { formatMessage: f } = useIntl();

  const [view, setView] = useState<View>('Default');

  const onViewSelect = useCallback((view: View) => {
    setView(view);
  }, []);

  const goBack = useCallback(() => onViewSelect('Default'), [onViewSelect]);

  const rootRef = useRef<HTMLDivElement>(null);

  return (
    <Box ref={rootRef} sx={{ overflow: 'hidden' }}>
      {view === 'Label' ? (
        <Slide in direction="left" container={rootRef.current} mountOnEnter unmountOnExit>
          <Box>
            <LabelsSelector
              {...labelsSelectorProps}
              hideHeader
              hideManageLabels
              placeholder={f({ id: 'label-name' })}
              goBack={goBack}
            />
          </Box>
        </Slide>
      ) : null}
      {Attachment.isValidAttachmentType(view) ? (
        <Slide in direction="left" container={rootRef.current} mountOnEnter unmountOnExit>
          <Box>
            <AssetsSelector {...assetsSelectorProps} type={view} goBack={goBack} />
          </Box>
        </Slide>
      ) : null}
      {view === 'Default' ? (
        <FiltersButtonMenuList
          onViewSelect={onViewSelect}
          filtersListProps={filtersListProps}
          {...filtersButtonMenuListProps}
        />
      ) : null}
    </Box>
  );
};
