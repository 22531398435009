import { useCallback, useState } from 'react';
import { Box, styled } from '@mui/material';
import { UploadLinkOverlay } from './upload-link-overlay';
import { ClickAwayListener } from '@mui/material';

interface FigmaLinkEmbedProps {
  url: string;
}

export const FigmaLinkEmbed = (props: FigmaLinkEmbedProps) => {
  const { url } = props;
  const [view, setView] = useState(false);

  const onClickAway = useCallback(() => {
    setView(true);
  }, []);

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <IframeWrapper>
        {view ? <UploadLinkOverlay url={url} view={true} setShowOverlay={setView} /> : null}
        <iframe src={url} width="100%" height="100%" allowFullScreen></iframe>
      </IframeWrapper>
    </ClickAwayListener>
  );
};

const IframeWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '100%',
  flexGrow: 1,
  borderRadius: 4,
  backgroundColor: theme.palette.other.imagePlaceholderBg,
  border: `1px solid ${theme.palette.divider}`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
}));
