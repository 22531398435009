import { IconComponentProps } from '../icon';
import { FC } from 'react';

export const GoogleDocs: FC<IconComponentProps> = () => {
  return (
    <>
      <mask
        id="mask0_1600_1042"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="4"
        y="1"
        width="13"
        height="18"
      >
        <path
          d="M11.9792 1.66675H5.33855C4.69402 1.66675 4.16667 2.17158 4.16667 2.7886V16.9988C4.16667 17.6158 4.69402 18.1206 5.33855 18.1206H15.4948C16.1393 18.1206 16.6667 17.6158 16.6667 16.9988V6.15418L11.9792 1.66675Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_1600_1042)">
        <path
          d="M11.9792 1.66675H5.33855C4.69402 1.66675 4.16667 2.17158 4.16667 2.7886V16.9988C4.16667 17.6158 4.69402 18.1206 5.33855 18.1206H15.4948C16.1393 18.1206 16.6667 17.6158 16.6667 16.9988V6.15418L13.9323 4.28441L11.9792 1.66675Z"
          fill="#4285F4"
        />
      </g>
      <mask
        id="mask1_1600_1042"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="4"
        y="1"
        width="13"
        height="18"
      >
        <path
          d="M11.9792 1.66675H5.33855C4.69402 1.66675 4.16667 2.17158 4.16667 2.7886V16.9988C4.16667 17.6158 4.69402 18.1206 5.33855 18.1206H15.4948C16.1393 18.1206 16.6667 17.6158 16.6667 16.9988V6.15418L11.9792 1.66675Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_1600_1042)">
        <path d="M12.3219 5.82593L16.6667 9.98428V6.15407L12.3219 5.82593Z" fill="url(#paint0_linear_1600_1042)" />
      </g>
      <mask
        id="mask2_1600_1042"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="4"
        y="1"
        width="13"
        height="18"
      >
        <path
          d="M11.9792 1.66675H5.33855C4.69402 1.66675 4.16667 2.17158 4.16667 2.7886V16.9988C4.16667 17.6158 4.69402 18.1206 5.33855 18.1206H15.4948C16.1393 18.1206 16.6667 17.6158 16.6667 16.9988V6.15418L11.9792 1.66675Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask2_1600_1042)">
        <path
          d="M7.29167 13.6331H13.5417V12.8852H7.29167V13.6331ZM7.29167 15.1289H11.9792V14.381H7.29167V15.1289ZM7.29167 9.89355V10.6415H13.5417V9.89355H7.29167ZM7.29167 12.1373H13.5417V11.3894H7.29167V12.1373Z"
          fill="#F1F1F1"
        />
      </g>
      <mask
        id="mask3_1600_1042"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="4"
        y="1"
        width="13"
        height="18"
      >
        <path
          d="M11.9792 1.66675H5.33855C4.69402 1.66675 4.16667 2.17158 4.16667 2.7886V16.9988C4.16667 17.6158 4.69402 18.1206 5.33855 18.1206H15.4948C16.1393 18.1206 16.6667 17.6158 16.6667 16.9988V6.15418L11.9792 1.66675Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask3_1600_1042)">
        <path
          d="M11.9792 1.66675V5.03232C11.9792 5.65214 12.5036 6.15418 13.151 6.15418H16.6667L11.9792 1.66675Z"
          fill="#A1C2FA"
        />
      </g>
      <mask
        id="mask4_1600_1042"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="4"
        y="1"
        width="13"
        height="18"
      >
        <path
          d="M11.9792 1.66675H5.33855C4.69402 1.66675 4.16667 2.17158 4.16667 2.7886V16.9988C4.16667 17.6158 4.69402 18.1206 5.33855 18.1206H15.4948C16.1393 18.1206 16.6667 17.6158 16.6667 16.9988V6.15418L11.9792 1.66675Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask4_1600_1042)">
        <path
          d="M5.33855 1.66675C4.69402 1.66675 4.16667 2.17158 4.16667 2.7886V2.88209C4.16667 2.26507 4.69402 1.76024 5.33855 1.76024H11.9792V1.66675H5.33855Z"
          fill="white"
          fillOpacity="0.2"
        />
      </g>
      <mask
        id="mask5_1600_1042"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="4"
        y="1"
        width="13"
        height="18"
      >
        <path
          d="M11.9792 1.66675H5.33855C4.69402 1.66675 4.16667 2.17158 4.16667 2.7886V16.9988C4.16667 17.6158 4.69402 18.1206 5.33855 18.1206H15.4948C16.1393 18.1206 16.6667 17.6158 16.6667 16.9988V6.15418L11.9792 1.66675Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask5_1600_1042)">
        <path
          d="M15.4948 18.0269H5.33855C4.69402 18.0269 4.16667 17.5221 4.16667 16.905V16.9985C4.16667 17.6155 4.69402 18.1204 5.33855 18.1204H15.4948C16.1393 18.1204 16.6667 17.6155 16.6667 16.9985V16.905C16.6667 17.5221 16.1393 18.0269 15.4948 18.0269Z"
          fill="#1A237E"
          fillOpacity="0.2"
        />
      </g>
      <mask
        id="mask6_1600_1042"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="4"
        y="1"
        width="13"
        height="18"
      >
        <path
          d="M11.9792 1.66675H5.33855C4.69402 1.66675 4.16667 2.17158 4.16667 2.7886V16.9988C4.16667 17.6158 4.69402 18.1206 5.33855 18.1206H15.4948C16.1393 18.1206 16.6667 17.6158 16.6667 16.9988V6.15418L11.9792 1.66675Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask6_1600_1042)">
        <path
          d="M13.151 6.15408C12.5036 6.15408 11.9792 5.65205 11.9792 5.03223V5.12571C11.9792 5.74554 12.5036 6.24757 13.151 6.24757H16.6667V6.15408H13.151Z"
          fill="#1A237E"
          fillOpacity="0.1"
        />
      </g>
      <path
        d="M11.9792 1.66675H5.33855C4.69402 1.66675 4.16667 2.17811 4.16667 2.80311V17.1971C4.16667 17.8221 4.69402 18.3334 5.33855 18.3334H15.4948C16.1393 18.3334 16.6667 17.8221 16.6667 17.1971V6.2122L11.9792 1.66675Z"
        fill="url(#paint1_radial_1600_1042)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1600_1042"
          x1="229.582"
          y1="41.53"
          x2="229.582"
          y2="421.719"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1A237E" stopOpacity="0.2" />
          <stop offset="1" stopColor="#1A237E" stopOpacity="0.02" />
        </linearGradient>
        <radialGradient
          id="paint1_radial_1600_1042"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(43.7673 34.4174) scale(2015.61 1943.25)"
        >
          <stop stopColor="white" stopOpacity="0.1" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </radialGradient>
      </defs>
    </>
  );
};
