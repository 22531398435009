import { FC } from 'react';
import { IconComponentProps } from '../icon';

export const SortingZA: FC<IconComponentProps> = ({ color }) => {
  return (
    <>
      <path d="M3 6H7L3 17H7" stroke={color} strokeWidth="2" strokeLinecap="square" strokeLinejoin="round" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.4722 7.47813C17.7625 7.15882 18.1346 7 18.5 7C18.8654 7 19.2375 7.15882 19.5278 7.47813C19.821 7.80061 20 8.25674 20 8.75V11.7227H17V8.75C17 8.25674 17.179 7.80061 17.4722 7.47813ZM15 12.7227V8.75C15 7.78457 15.3478 6.84175 15.9923 6.13278C16.6397 5.42064 17.5393 5 18.5 5C19.4607 5 20.3603 5.42064 21.0077 6.13278C21.6522 6.84175 22 7.78457 22 8.75V17V18H20V17V13.7227H17V17V18H15V17V12.7227Z"
        fill={color}
      />
      <path d="M10 12H13" stroke={color} strokeWidth="2" strokeLinecap="square" strokeLinejoin="round" />
    </>
  );
};
