import { Plans } from '../../components/settings/plans/plans';
import { Divider, Stack } from '@mui/material';
import { Billing } from '../../components/settings/billing/billing';
import { SettingsNav } from '../../components/settings/nav/settings-nav';
import { navBarOverridePropsVar } from '../../state/state';
import { useEffect } from 'react';
import { Apps } from '../../components/settings/apps/apps';

export const SettingsRoute = () => {
  useEffect(() => {
    navBarOverridePropsVar({ hideWorkspaces: true });
    return () => {
      navBarOverridePropsVar({});
    };
  }, []);

  return (
    <Stack direction="row" alignItems="flex-start">
      <SettingsNav />
      <Divider orientation="vertical" flexItem />
      <Stack sx={{ height: 'calc(100vh - 64px)', overflowY: 'scroll' }} flexGrow={1} p={8} gap={8} width="100%">
        <Plans />
        <Billing />
        <Apps />
      </Stack>
    </Stack>
  );
};
