import { useActiveOrganization } from '../../../hooks/use-active-organization';
import { useSingleSourceStore } from '../../../store/single-source-store/single-source-store';
import { Status } from '@xspecs/single-source-model';
import { Box, Tooltip, useTheme } from '@mui/material';

export const StatusIndicator = () => {
  const theme = useTheme();
  const { organization } = useActiveOrganization();
  const modelId = organization?.singleSourceModel?.id;
  const file = modelId ? useSingleSourceStore.use.filesById()[modelId] : null;

  if (!organization || !file?.status) return null;

  const colorMap: Record<Status, any> = {
    [Status.Authenticated]: theme.palette.warning.main,
    [Status.AuthenticationFailed]: theme.palette.error.main,
    [Status.Connected]: theme.palette.warning.main,
    [Status.Connecting]: theme.palette.warning.main,
    [Status.Disconnected]: theme.palette.error.main,
    [Status.Initial]: theme.palette.info.main,
    [Status.Synced]: theme.palette.success.main,
    [Status.Unknown]: theme.palette.info.main,
    [Status.Unsynced]: theme.palette.error.main,
  };

  return (
    <Tooltip title={file.status}>
      <Box
        bgcolor={colorMap[file.status]}
        sx={{
          backgroundColor: colorMap[file.status],
          borderRadius: '50%',
          height: 16,
          width: 16,
        }}
      />
    </Tooltip>
  );
};
