import { ToggleButton } from '@mui/material';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import { useCallback } from 'react';
import { focusEditor, useEditorState } from '@udecode/plate-common';
import { triggerFloatingLink } from '@udecode/plate-link';

type BalloonMenuLinkProps = {
  closeMenu: () => void;
};
export const BalloonMenuLink = (props: BalloonMenuLinkProps) => {
  const { closeMenu } = props;

  const editor = useEditorState();

  const onAddLink = useCallback(
    async (event) => {
      if (!editor) return;
      closeMenu();
      event.preventDefault();
      event.stopPropagation();
      const selection = editor.selection ?? editor.prevSelection;
      if (!selection) return;
      focusEditor(editor, selection);
      setTimeout(() => {
        triggerFloatingLink(editor, { focused: true });
      }, 0);
    },
    [closeMenu, editor],
  );

  return (
    <ToggleButton
      data-testid="balloon-menu-link"
      value=""
      sx={{ minWidth: '47px', borderRadius: 'unset', border: 'none' }}
      onMouseDown={onAddLink}
    >
      <InsertLinkIcon color="action" />
    </ToggleButton>
  );
};
