import { IconComponentProps } from '../icon';
import { FC } from 'react';

export const Notion: FC<IconComponentProps> = () => {
  return (
    <>
      <path
        d="M3.49536 2.38577L12.6488 1.70461C13.7732 1.60745 14.0621 1.67295 14.7691 2.19077L17.691 4.26456C18.1729 4.62122 18.3333 4.71839 18.3333 5.10671V16.4803C18.3333 17.1931 18.0764 17.6148 17.177 17.6791L6.54746 18.3278C5.87252 18.36 5.5511 18.2628 5.19759 17.809L3.0459 14.99C2.65997 14.4712 2.5 14.0829 2.5 13.6289V3.51908C2.5 2.93626 2.75691 2.45027 3.49536 2.38577Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6487 1.70478L3.49535 2.38594C2.7569 2.45027 2.5 2.93643 2.5 3.51909V13.629C2.5 14.0828 2.65996 14.4711 3.0459 14.9901L5.19756 17.8089C5.55107 18.2627 5.87249 18.3601 6.54741 18.3277L17.177 17.6794C18.0758 17.6149 18.3333 17.1933 18.3333 16.4806V5.10691C18.3333 4.73858 18.1889 4.63241 17.7638 4.31808L14.769 2.19077C14.0621 1.67295 13.7731 1.60745 12.6487 1.70461V1.70478ZM6.78777 4.92074C5.9198 4.97957 5.72294 4.99291 5.22998 4.58908L3.97673 3.58476C3.84936 3.45476 3.91338 3.29259 4.2343 3.26026L13.0337 2.61244C13.7726 2.54744 14.1574 2.80693 14.4464 3.0336L15.9555 4.13525C16.0201 4.16808 16.1805 4.36191 15.9875 4.36191L6.90026 4.91307L6.78777 4.92074ZM5.77588 16.3834V6.72805C5.77588 6.30639 5.90441 6.11189 6.28919 6.07923L16.7264 5.46357C17.0804 5.4314 17.2404 5.65807 17.2404 6.07906V15.6701C17.2404 16.0918 17.1759 16.4484 16.598 16.4806L6.61028 17.0639C6.03245 17.0961 5.77605 16.9023 5.77605 16.3834H5.77588ZM15.6351 7.24571C15.6991 7.53738 15.6351 7.82904 15.3456 7.86237L14.8642 7.95854V15.0873C14.4462 15.314 14.0614 15.4435 13.7399 15.4435C13.2259 15.4435 13.0975 15.2813 12.7126 14.7956L9.56457 9.80568V14.6335L10.5604 14.8606C10.5604 14.8606 10.5604 15.4439 9.75696 15.4439L7.54194 15.5734C7.47742 15.4435 7.54194 15.1196 7.76642 15.0551L8.3449 14.8935V8.5102L7.5421 8.4447C7.47759 8.15304 7.63805 7.73188 8.088 7.69921L10.4646 7.53804L13.74 12.5925V8.12087L12.9051 8.02421C12.8406 7.66704 13.0975 7.40755 13.4184 7.37588L15.6351 7.24571Z"
        fill="black"
      />
    </>
  );
};
