import { useUpdateEntity } from '../../hooks/use-update-entity';
import { ChangeEventHandler, useCallback } from 'react';
import { InputBase } from '@mui/material';

type UploadNameProps = {
  id: string;
  name: string;
};

export const UploadName = (props: UploadNameProps) => {
  const { id, name } = props;
  const { onRename } = useUpdateEntity(id);

  const onChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      onRename(e.target.value);
    },
    [onRename],
  );

  return <InputBase value={name} onChange={onChange} sx={inputSx} />;
};

const inputSx = {
  input: {
    p: 0,
  },
};
