import { CommandBase, IParams } from '../framework/CommandBase';
import { EventBase } from '../framework/EventBase';
import { CommandError } from '../../ErrorStore';
import { AttachmentType, EntityType } from '../../entities/EntityType';
import { AddEntityCommand } from '../entities/AddEntityCommand';
import { EntityParserFactory } from '../../entities/EntityParserFactory';
import { AssetBase } from '../../entities/assets/AssetBase';

interface AddToolbarSelectedEntityCommandParams extends IParams {
  id: string;
  position: { x: number; y: number };
  userSub: string;
}

interface ToolbarSelectedEntityAddedEventParams extends IParams {
  id: string;
  position: { x: number; y: number };
  userSub: string;
}

export class ToolbarSelectedEntityAddedEvent extends EventBase {
  static eventType = 'ToolbarSelectedEntityAdded';

  constructor(
    public readonly params: ToolbarSelectedEntityAddedEventParams,
    public readonly source = AddToolbarSelectedEntityCommand,
  ) {
    super();
  }
}

export class AddToolbarSelectedEntityCommand extends CommandBase<AddToolbarSelectedEntityCommandParams> {
  execute(params: AddToolbarSelectedEntityCommandParams): ToolbarSelectedEntityAddedEvent | CommandError {
    const type = this.model.toolbar.getSelectedEntityType();
    if (type) {
      if (EntityParserFactory.isInstanceOfBase(type, AssetBase)) {
        this.model.messageBus.send(AddEntityCommand, {
          id: params.id,
          type: EntityType.Attachment,
          subType: type as unknown as AttachmentType,
          position: params.position,
          name: '',
        });
      } else {
        if (type !== EntityType.Attachment) {
          this.model.messageBus.send(AddEntityCommand, {
            id: params.id,
            type: type as any,
            position: params.position,
            name: '',
            createdBy: params.userSub,
          });
        }
      }
      this.model.toolbar.selectEntity('');
      return new ToolbarSelectedEntityAddedEvent({ id: params.id, position: params.position, userSub: params.userSub });
    }
    return CommandError.of(new Error('No entity type selected.'), 'error');
  }
}
