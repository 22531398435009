import { GqlGraphDetails } from '@xspecs/single-source-model';
import axios from 'axios';
import { singleSourceStore } from '../../../store/single-source-store/single-source-store';
import { parse } from 'graphql';

export const fetchGraphDetails = async (graphId: string, withSubgraphs: boolean = true): Promise<GqlGraphDetails> => {
  const response = await axios.get<GqlGraphDetails>(
    `${getInstantMockUrl()}/graphs/${graphId}?withSubgraphs=${withSubgraphs}`,
  );
  return response.data;
};

const getInstantMockUrl = () => {
  const instantMockUrl = singleSourceStore.getState().instantMockUrl;
  if (!instantMockUrl) throw new Error('Instant Mock URL is not set');
  return instantMockUrl;
};

export const runQuery = async (queryText: string, variantProposal: string) => {
  const [graphId, variantName] = variantProposal.split('@');
  singleSourceStore.getState().setRunningQuery(true);
  try {
    const response = await axios.post(
      `${getInstantMockUrl()}/${graphId}/${variantName}/graphql`,
      {
        // @ts-ignore
        operationName: getOperationName(queryText),
        query: queryText.trim(),
        variables: {},
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'seed-group': 'default',
        },
      },
    );

    singleSourceStore.getState().setSeedData(response.data ?? {});
  } finally {
    singleSourceStore.getState().setRunningQuery(false);
  }
};

export const getOperationName = (queryText: string): string => {
  //  @ts-ignore
  return parse(queryText).definitions.find((def) => def.kind === 'OperationDefinition').name?.value ?? '';
};
