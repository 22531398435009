import { Node, NodeProps } from '@xyflow/react';
import { AnnotationNodeData, AnnotationType, UpdateGqlEntityCommand } from '@xspecs/single-source-model';
import { useCallback, useState } from 'react';
import { useSingleSourceStore } from '../../../../store/single-source-store/single-source-store';
import { useApplication } from '../../../../wrappers/application-context/application-context';
import { AutoComplete } from './auto-complete';
import { useIntl } from 'react-intl';
import { Box, Stack, Tooltip, Typography } from '@mui/material';

export const AnnotationNode = (props: NodeProps<Node<AnnotationNodeData>>) => {
  const { id, type, data, selected } = props;

  const schemaOptions = useSingleSourceStore.use.schemaOptions();
  const { application } = useApplication();
  const { formatMessage: f } = useIntl();

  const [isFocused, setIsFocused] = useState(false);
  const splitPaths = data.path.split('.');
  const leaf = splitPaths[splitPaths.length - 1];
  const leafParent = splitPaths[splitPaths.length - 2];

  const topParent = splitPaths[0];

  const onSubmit = useCallback(
    (value: string) => {
      application?.model.messageBus.send(UpdateGqlEntityCommand, {
        entityId: id,
        name: value,
      });
    },
    [application?.model.messageBus, id],
  );

  if (!selected) {
    return (
      <>
        <Stack
          height="100%"
          width="100%"
          className={annotationNodeClassName[type]}
          justifyContent="center"
          borderRadius={2}
          px={1}
          py={0.5}
          sx={{
            border: '1px solid #8DA33C',
            boxSizing: 'border-box',
          }}
        >
          {topParent !== leaf ? (
            <Typography variant="caption" color="text.secondary" sx={overflowSx}>
              <i>{topParent}</i>
            </Typography>
          ) : null}
          <Typography variant="body1" color="text.primary" sx={overflowSx}>
            {(leafParent ? leafParent + '.' : '') + (leaf ?? '')}
          </Typography>
        </Stack>
      </>
    );
  }

  return (
    <Tooltip title={data.path} placement="top">
      <Box
        height="100%"
        width="100%"
        borderRadius={2}
        sx={{
          border: '2px solid #64732d',
          boxSizing: 'border-box',
          overflow: 'hidden',
        }}
      >
        <AutoComplete
          inputClassName={annotationNodeClassName[type]}
          data={schemaOptions}
          value={data.path}
          placeholder={f({ id: 'enter-annotation' })}
          onSubmit={onSubmit}
          autoFocus={false}
          inputStyle={{
            height: '100%',
            width: '100%',
            borderRadius: 2,
            outline: 'none',
            cursor: isFocused ? 'text' : 'grab',
          }}
          onBlur={() => setIsFocused(false)}
          onFocus={() => setIsFocused(true)}
        />
      </Box>
    </Tooltip>
  );
};

export const annotationNodeClassName: Record<AnnotationType, string> = {
  [AnnotationType.Operation]: 'Operation',
  [AnnotationType.Field]: 'Data',
};

const overflowSx = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
};
