import { ChangeEvent, Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  autoUpdate,
  flip,
  FloatingFocusManager,
  FloatingPortal,
  size as sizeMiddleware,
  useDismiss,
  useFloating,
  UseFloatingOptions,
  useInteractions,
  useListNavigation,
  useRole,
} from '@floating-ui/react';
import { Box, InputBase, List, MenuItem, Stack, SxProps, Theme, Typography } from '@mui/material';
import { AssetAssignerAutoCompleteOption, AssetAssignerAutoCompleteItem } from './asset-assigner-auto-complete-item';
import { useIntl } from 'react-intl';
import { useSnackStack } from '../../wrappers/snack-stack-context';
import { AddCircle, Search } from '@mui/icons-material';

type AutoCompleteProps = {
  placeholder?: string;
  value: string;
  options: AssetAssignerAutoCompleteOption[];
  onChange: (option: AssetAssignerAutoCompleteOption | null | string) => void;
  onCreateNew: (name: string) => void;
  disableCreate?: boolean;
  notFoundText?: string;
};

export const AssetAssignerAutoComplete = (props: AutoCompleteProps) => {
  const { formatMessage: f } = useIntl();
  const { placeholder, value, options, onChange: onChangeProp, onCreateNew, disableCreate, notFoundText } = props;

  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const { addToast } = useSnackStack();

  const listRef = useRef<Array<HTMLElement | null>>([]);
  const { refs, floatingStyles, context, elements } = useFloating<HTMLInputElement>(
    useMemo<Partial<UseFloatingOptions>>(
      () => ({
        placement: 'bottom-start',
        whileElementsMounted: autoUpdate,
        open,
        onOpenChange: setOpen,
        middleware: [flip({ padding: 10 }), sizeMiddleware({ padding: 10 })],
      }),
      [open],
    ),
  );
  const role = useRole(
    context,
    useMemo(() => ({ role: 'listbox' as const }), []),
  );
  const dismiss = useDismiss(context);
  const listNav = useListNavigation(
    context,
    useMemo(() => ({ listRef, activeIndex, onNavigate: setActiveIndex, virtual: true, loop: true }), [activeIndex]),
  );
  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
    useMemo(() => [role, dismiss, listNav], [dismiss, listNav, role]),
  );

  const onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInputValue(value);
    if (value) {
      setActiveIndex(0);
    }
    setOpen(true);
  }, []);

  const items = useMemo(() => {
    const _options = options.filter((item) => item.name.toLowerCase().includes(inputValue.toLowerCase()));
    const optionWithNameExists = _options.some((item) => item.name === inputValue);
    if (inputValue && !optionWithNameExists && !disableCreate)
      _options.unshift({
        id: 'internal',
        name: inputValue,
      });
    return _options;
  }, [disableCreate, inputValue, options]);

  const createItem = useMemo(() => {
    return items.find((item) => item.id === 'internal');
  }, [items]);

  const onOptionSelect = useCallback(
    (option: AssetAssignerAutoCompleteOption) => {
      if (option.id === 'internal') onCreateNew(inputValue);
      else onChangeProp(option);
    },
    [inputValue, onChangeProp, onCreateNew],
  );

  const getListItemProps = useCallback(
    (index: number) => {
      return getItemProps({
        ref(node) {
          listRef.current[index] = node;
        },
        onMouseDown() {
          onOptionSelect(items[index]);
          setOpen(false);
          setInputValue(items[index].name);
          setTimeout(() => {
            elements.domReference?.blur();
          }, 10);
        },
      });
    },
    [elements.domReference, getItemProps, items, onOptionSelect],
  );

  const CreateItem = useMemo(() => {
    if (!createItem) return null;
    const props = getListItemProps(0);

    return (
      <MenuItem
        sx={{
          background: activeIndex === 0 ? 'rgba(0, 0, 0, 0.04)' : undefined,
          cursor: 'pointer',
          width: '100%',
        }}
      >
        <Stack direction="row" gap={1} height={42} alignItems="center" width="100%" {...props}>
          <AddCircle fontSize="small" color="action" />
          <Typography sx={overflowSx}>{createItem.name}</Typography>
        </Stack>
      </MenuItem>
    );
  }, [activeIndex, createItem, getListItemProps]);

  const hasCreate = Boolean(createItem);

  const inputProps = useMemo(
    () => ({
      ...getReferenceProps({
        ref: refs.setReference,
        onChange,
        value: inputValue,
        placeholder,
        'aria-autocomplete': 'list',
        onBlur() {
          const optionByName = options.find((item) => item.name === inputValue);
          if (optionByName) {
            onOptionSelect(optionByName);
          } else {
            addToast({ severity: 'error', message: notFoundText ?? f({ id: 'not-found' }) });
          }
        },
        onClick() {
          setOpen(true);
        },
        onKeyDown(event) {
          if (event.key === 'Enter') {
            const activeItem = activeIndex !== null && items[activeIndex];
            if (activeItem) {
              onOptionSelect(activeItem);
              setActiveIndex(null);
              setOpen(false);
              setTimeout(() => {
                elements.domReference?.blur();
              }, 10);
            } else {
              if (disableCreate) {
                const option = options.find((item) => item.id === value);
                if (!option) return;
                onOptionSelect(option);
                setInputValue(option?.name ?? '');
                setActiveIndex(null);
                setOpen(false);
                setTimeout(() => {
                  elements.domReference?.blur();
                }, 10);
                addToast({ severity: 'error', message: notFoundText ?? f({ id: 'not-found' }) });
              }
            }
          }
        },
      }),
      sx: inputSx,
    }),
    [
      activeIndex,
      addToast,
      disableCreate,
      elements.domReference,
      f,
      getReferenceProps,
      inputValue,
      items,
      notFoundText,
      onChange,
      onOptionSelect,
      options,
      placeholder,
      refs.setReference,
      value,
    ],
  );

  const offset = hasCreate ? 1 : 0;

  const floatingProps = useMemo(
    () =>
      getFloatingProps({
        ref: refs.setFloating,
        style: { ...floatingStyles },
      }),
    [floatingStyles, getFloatingProps, refs.setFloating],
  );

  useEffect(() => {
    const option = options.find((item) => item.id === value);
    setInputValue(option?.name ?? '');
  }, [options, value]);

  const itemsToShow = useMemo(() => items.slice(offset), [items, offset]);

  return (
    <Fragment>
      <InputBase inputProps={inputProps} />
      {open && (items.length > 0 || hasCreate) && (
        <FloatingPortal>
          <FloatingFocusManager context={context} initialFocus={-1} visuallyHiddenDismiss>
            <Box {...floatingProps} sx={rootSx}>
              {CreateItem}
              {items.length === offset ? (
                <Stack sx={notFoundRootSx} alignItems="center">
                  <Search color="primary" />
                  <Stack gap={0.5}>
                    <Typography variant="caption" textAlign="center" color="text.primary">
                      {f({ id: 'no-results-found' })}
                    </Typography>
                    <Typography variant="tooltip" textAlign="center" color="text.secondary">
                      {f({ id: 'no-results-found-description' })}
                    </Typography>
                  </Stack>
                </Stack>
              ) : (
                <List className="nodrag nowheel" sx={listSx}>
                  {itemsToShow.map((item, index) => (
                    <AssetAssignerAutoCompleteItem
                      key={item.id}
                      {...item}
                      {...getListItemProps(index + offset)}
                      active={activeIndex === index + offset}
                    />
                  ))}
                </List>
              )}
            </Box>
          </FloatingFocusManager>
        </FloatingPortal>
      )}
    </Fragment>
  );
};

const inputSx = {
  padding: 0,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  color: 'text.secondary',
};

const rootSx = {
  backgroundColor: 'background.paper',
  width: 200,
  boxShadow: (t) => t.shadows[8],
  borderRadius: 1,
  maxHeight: 300,
  overflowY: 'auto',
};

const overflowSx = {
  maxWidth: '100%',
  overflowX: 'hidden',
  textOverflow: 'ellipsis',
};

const notFoundRootSx: SxProps<Theme> = {
  borderTop: (t) => `1px solid ${t.palette.divider}`,
  gap: 1,
  px: 3,
  py: 1.5,
};

const listSx = {
  borderTop: (t) => `1px solid ${t.palette.divider}`,
  maxHeight: 200,
  overflowY: 'auto',
};
