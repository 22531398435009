import { SingleSourceModel } from '../SingleSourceModel';
import { localStoreProvider } from '../data/LocalStoreProvider';
import { TestClipboard } from '../clipboard/TestClipboard';
import { Clipboard } from '../clipboard/Clipboard';
import { ModelFile } from '../data/ModelFile';
import { IStore } from '../data/Store';

export class SingleSourceModelTestFactory {
  public static create(scopes: string[], modelFile: ModelFile, store: IStore) {
    localStoreProvider.setPersistedStore(store);
    const singleSourceModel = SingleSourceModel.getInstance(scopes, modelFile, store);
    //singleSourceModel.observer.disableDebounce();
    const clipboard = new Clipboard(
      new TestClipboard(),
      singleSourceModel.entityRepository,
      singleSourceModel.entitySelectionTracker,
      singleSourceModel.messageBus,
    );
    Object.assign(singleSourceModel, { clipboard: clipboard });
    return singleSourceModel;
  }
}
