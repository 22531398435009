import { EntityBase } from '../EntityBase';
import { Z_INDEXES } from '../../ZIndexes';
import { EntityType } from '../EntityType';
import { z } from 'zod';

export class Preview extends EntityBase {
  static baseSchema = EntityBase.abstractBaseSchema.extend({
    width: z.number().default(200),
    height: z.number().default(163),
    zIndex: z.number().default(Z_INDEXES.Attachment),
    parent: z.lazy(() => EntityBase.abstractBaseSchema),
    isOpen: z.boolean().default(false),
  });

  public isOpen: boolean;

  public get type(): EntityType {
    return EntityType.Preview;
  }

  serialize(reference: boolean = false): unknown {
    if (reference) return super.serialize(reference);
    return {
      ...(super.serialize() as any),
    };
  }

  static parse(data: unknown, omitReferences = false): Preview {
    if (omitReferences) {
      const validatedData = Preview.baseSchema.omit({ parent: true }).parse(data);
      return EntityBase.parseBase.call(Preview, validatedData);
    }
    return super.parseBase(data, Preview.baseSchema) as Preview;
  }

  isValid(): boolean {
    return Preview.baseSchema.safeParse(this).success;
  }
}
