import { CommandBase, IParams } from '../framework/CommandBase';
import { EventBase } from '../framework/EventBase';
import { CommandError } from '../../ErrorStore';
import { Preview } from '../../entities/assets/Preview';
import { Attachment } from '../../entities/assets/Attachment';
import { EntityBase } from '../../entities/EntityBase';
import { AssetTypes } from '../../entities/assets/AssetTypes';
import { Errors } from '@xspecs/errors';

interface RenameEntityCommandParams extends IParams {
  entityId: string;
  newName: string;
}

interface EntityRenamedEventParams extends IParams {
  entityId: string;
  newName: string;
}

export class EntityRenamedEvent extends EventBase {
  static eventType = 'EntityRenamedEvent';

  constructor(public readonly params: EntityRenamedEventParams, public readonly source = RenameEntityCommand) {
    super();
  }
}

export class RenameEntityCommand extends CommandBase<RenameEntityCommandParams> {
  execute(params: RenameEntityCommandParams): EntityRenamedEvent | CommandError {
    let entity = this.model.entities.get(params.entityId);
    if (!entity) return CommandError.of(new Error('Entity not found'), 'error');
    if (entity instanceof Preview && entity.parent instanceof Attachment && entity.parent.asset)
      entity = entity.parent.asset;
    const response = this.rename(entity, params.newName);
    if (response instanceof Error) return CommandError.of(response, 'error');
    return new EntityRenamedEvent({
      entityId: entity.id,
      newName: params.newName,
    });
  }

  public rename(entity: EntityBase, newName: string): void | Error {
    if (this.hasDuplicateAssetName(entity, newName)) return new Error(Errors.DuplicateAssetName);
    entity.name = newName;
    entity.attributes.fontSize = -1;
    this.model.entityRepository.update(entity);
  }

  private hasDuplicateAssetName(entity: EntityBase, newName?: string): boolean {
    if (!AssetTypes.isAsset(entity.type)) return false;
    const nameToCheck = newName?.toLowerCase() || entity.name?.toLowerCase();
    return this.model.entityRepository.list().some((asset) => {
      return AssetTypes.isAsset(asset.type) && asset.id !== entity.id && asset.name?.toLowerCase() === nameToCheck;
    });
  }
}
