import { UploadLink, UploadLinkProps } from './upload-link';
import { Handle, Node, NodeProps, NodeResizer, OnResize, Position } from '@xyflow/react';
import { MouseEventHandler, useCallback, useMemo, useState } from 'react';
import { PreviewNodeData, UploadType } from '@xspecs/single-source-model';
import { useUpdateEntity } from '../hooks/use-update-entity';
import { ImageCanvasNode } from '../../../image-canvas-node/image-canvas-node';
import { useApplication } from '../../../../wrappers/application-context/application-context';
import { useWindowEvent } from '@mantine/hooks';
import { Box, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';

export const PreviewNode = (props: NodeProps<Node<PreviewNodeData>>) => {
  const { id, data } = props;
  const [keepAspectRatio, setKeepAspectRatio] = useState(true);
  const { application } = useApplication();
  const { onRename } = useUpdateEntity(id);

  const uploadLinkProps = useMemo<UploadLinkProps>(() => {
    if (data.uploadType === UploadType.Image) {
      return {
        title: data.name,
        url: data.url,
        favicon: data.metadata?.favicon ?? '',
        image: data.url,
        onNameChange: onRename,
      } satisfies UploadLinkProps;
    }

    return {
      title: data.name,
      url: data.url,
      favicon: data.metadata?.favicon ?? '',
      image: data.metadata?.imageUrl ?? '',
      onNameChange: onRename,
    } satisfies UploadLinkProps;
  }, [data.metadata?.favicon, data.metadata?.imageUrl, data.name, data.uploadType, data.url, onRename]);

  const onResize = useCallback<OnResize>(
    (event, params) => {
      application?.model.interactor.resizeEntity(id, params);
    },
    [application?.model.interactor, id],
  );

  const onResizeStart = useCallback(
    () => application?.model.interactor.selectEntity(id),
    [application?.model.interactor, id],
  );

  const onToggleEntityVisibility = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (e) => {
      e.stopPropagation();
      application?.model.interactor.toggleEntityVisibilityExpanded(data.attachmentId);
    },
    [application?.model.interactor, data.attachmentId],
  );

  useWindowEvent('keydown', (e) => {
    if (e.shiftKey) setKeepAspectRatio(false);
  });
  useWindowEvent('keyup', () => setKeepAspectRatio(true));

  return (
    <>
      {data.capabilities?.isClosable ? (
        <IconButton sx={expandButtonSx} onClick={onToggleEntityVisibility}>
          <Close fontSize="small" />
        </IconButton>
      ) : null}
      <NodeResizer
        nodeId={id}
        minWidth={200}
        minHeight={163}
        onResize={onResize}
        handleStyle={{ backgroundColor: data.color }}
        lineStyle={{ borderColor: data.color }}
        onResizeStart={onResizeStart}
        keepAspectRatio={keepAspectRatio}
      />
      <Box sx={{ opacity: data.isDragging ? 0.3 : 0.9, width: '100%', height: '100%' }}>
        {data.uploadType === UploadType.Image ? (
          <ImageCanvasNode url={data.url} title={data.name} />
        ) : (
          <UploadLink {...uploadLinkProps} />
        )}
      </Box>
      <Handle id={Position.Left} type="source" position={Position.Left} style={style} />
      <Handle id={Position.Right} type="source" position={Position.Right} style={style} />
      <Handle id={Position.Top} type="source" position={Position.Top} style={style} />
      <Handle id={Position.Bottom} type="source" position={Position.Bottom} style={style} />
    </>
  );
};

const style = {
  opacity: 0,
};

const expandButtonSx = {
  p: 0.25,
  position: 'absolute',
  right: -35,
  top: -30,
  backgroundColor: 'background.paper',
  border: '1px solid #aeaeae',
  zIndex: 100000,
  '&:hover': {
    backgroundColor: 'background.default',
  },
};
