import { useQuery } from '@apollo/client';
import { SUBSCRIPTION_PLANS_QUERY } from '../graphql/queries';
import { useMemo } from 'react';
import { useActiveOrganization } from './use-active-organization';

export const usePlans = () => {
  const { organization } = useActiveOrganization();

  const { data, loading } = useQuery(SUBSCRIPTION_PLANS_QUERY, {
    variables: {
      organizationId: organization ? organization.id : '',
    },
    skip: !organization,
  });

  const currentPlan = useMemo(
    () => data?.subscriptionPlans.find((plan) => plan.isCurrentPlan),
    [data?.subscriptionPlans],
  );

  return {
    plans: data?.subscriptionPlans ?? [],
    currentPlan,
    loading,
  } as const;
};
