import { Divider } from '@mui/material';
import { useSingleSourceStore } from '../../../store/single-source-store/single-source-store';
import { useApplication } from '../../../wrappers/application-context/application-context';
import { UnsavedSelectedFilters } from '../../filters/unsaved-selected-filters/unsaved-selected-filters';
import { SavedSelectedFilters } from '../../filters/saved-selected-filters/saved-selected-filters';
import { Fragment } from 'react';
import { DeleteFilterParams, UpdateSavedFilterParams } from '@xspecs/single-source-model';

export const SingleSourceFilters = () => {
  const { application } = useApplication();

  const unsavedFilters = useSingleSourceStore.use.unsavedFilter();
  const appliedSavedFilter = useSingleSourceStore.use.appliedSavedFilter();
  const labels = useSingleSourceStore.use.labels();
  const assets = useSingleSourceStore.use.assets();
  const filtersMenu = useSingleSourceStore.use.filtersMenu();

  const onDeleteCriteria = (criteriaId: string) => {
    if (unsavedFilters)
      application?.model.filtersInteractor.removeFilterCriteria({
        id: unsavedFilters.id,
        type: unsavedFilters.filterType,
        criteriaIds: [criteriaId],
      });
  };

  const onClearAll = () => {
    if (unsavedFilters)
      application?.model.filtersInteractor.removeFilter({ id: unsavedFilters.id, type: unsavedFilters.filterType });
  };

  const onSaveFilter = (params: { name: string }) => {
    if (unsavedFilters) application?.model.filtersInteractor.saveFilter({ ...params, id: unsavedFilters.id });
  };

  const onClearSavedFilter = () => {
    if (appliedSavedFilter) application?.model.filtersInteractor.removeSavedFilter(appliedSavedFilter.id);
  };

  const onSaveSavedFilter = (params: UpdateSavedFilterParams) => {
    application?.model.filtersInteractor.updateSavedFilters(params);
  };

  const onDeleteSavedFilter = (params: DeleteFilterParams) => {
    application?.model.filtersInteractor.deleteSavedFilter(params);
  };

  if (appliedSavedFilter) {
    return (
      <Fragment>
        <SavedSelectedFilters
          appliedSavedFilter={appliedSavedFilter}
          labels={labels}
          onClear={onClearSavedFilter}
          onSave={onSaveSavedFilter}
          onDeleteFilter={onDeleteSavedFilter}
          assets={Object.values(assets).flatMap((asset) => asset)}
          filterItems={filtersMenu.items}
        />
        <Divider />
      </Fragment>
    );
  }

  if (unsavedFilters && unsavedFilters.criteria.length !== 0) {
    return (
      <Fragment>
        <UnsavedSelectedFilters
          unsavedFilter={unsavedFilters}
          onDeleteCriteria={onDeleteCriteria}
          onClearAll={onClearAll}
          onSave={onSaveFilter}
        />
        <Divider />
      </Fragment>
    );
  }

  return null;
};
