import { CommandBase, IParams } from '../framework/CommandBase';
import { EventBase } from '../framework/EventBase';
import { CommandError } from '../../ErrorStore';

interface SetAssetExplorerSearchParams extends IParams {
  search: string;
}

export class SetAssetExplorerSearchEvent extends EventBase {
  static eventType = 'SetAssetExplorerSearchEvent';

  constructor(
    public readonly params: SetAssetExplorerSearchParams,
    public readonly source = SetAssetExplorerSearchCommand,
  ) {
    super();
  }
}

export class SetAssetExplorerSearchCommand extends CommandBase<SetAssetExplorerSearchParams> {
  execute(params: SetAssetExplorerSearchParams): SetAssetExplorerSearchEvent | CommandError | undefined {
    const { search } = params;
    this.applySearch(search);
    return new SetAssetExplorerSearchEvent(params);
  }

  private applySearch(search: string) {
    this.model.assetExplorer.updateSearch(search);
  }
}
