import { memo } from 'react';
import { wrapNodesWithHoc } from '../utils';
import { NodeTypes } from '@xyflow/react';
import { constructs } from './constructs';
import { attachables } from './attachments';
import { EntityType } from '@xspecs/single-source-model';
import { ThreadNode } from './nodes/thread-node/thread-node';
import { DebugNode } from './debug-node/debug-node';
import { PreviewNode } from './links/preview-node';
// import { DefaultEdge } from './edges/default-edge';
import { SimpleFloatingEdge } from './edges/simple-floating-edge';

const withMemo = (nodes: NodeTypes) => wrapNodesWithHoc(nodes, memo);

const nodes = {
  ...constructs,
  ...attachables,
  [EntityType.Thread]: ThreadNode,
  DebugNode: DebugNode,
  [EntityType.Preview]: PreviewNode,
};

export const nodeTypes = withMemo(nodes);

export const edgeTypes = {
  default: SimpleFloatingEdge,
  // default: DefaultEdge,
};
