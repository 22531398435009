import { EntityType } from '../EntityType';
import { LaneConfig } from './LaneConfig';

export class ScriptConfig {
  constructor(
    public readonly lanes: LaneConfig[] = [],
    public readonly defaultLaneConfig?: LaneConfig,
    public autoIngestInCorrectLane: boolean = false,
    public readonly laneMutationAllowed: boolean = true,
    public readonly conflictingEntityTypeGroups: (EntityType | string)[][] = [],
    public readonly defaultFrameWidth: number = 200,
    public readonly defaultLaneHeight: number = 200,
  ) {}

  getLaneConfig(laneIndex: number): LaneConfig {
    return this.lanes[laneIndex] || this.defaultLaneConfig || new LaneConfig([]);
  }
}
