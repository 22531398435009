import { CacheEntry, LocalStorageCache } from '../auth';
import { cloneDeep } from 'lodash';
import randomColor from 'randomcolor';
import { v4 as uuidv4 } from 'uuid';
import { AUTH0_LOCAL_TOKEN_KEY } from '../config/auth0/Auth0Config';
import { CursorData } from '../types/editor-common.types';
import { logger } from '@xspecs/logger';

export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const isValidUrl = (string: string) => {
  let givenUrl: URL;
  try {
    givenUrl = new URL(string);
  } catch (error) {
    return false;
  }
  return givenUrl.protocol === 'http:' || givenUrl.protocol === 'https:';
};

export function getSharedInfrastructureHost() {
  if (window.location.hostname === 'app.narrative.tech' || window.location.hostname === 'app.xspecs.ai') {
    return 'main.narrative.tech';
  } else {
    return 'dev.xspecs.io';
  }
}

export function getBranchName() {
  const hostname = window.location.hostname;

  if (hostname === 'localhost') {
    return 'local';
  }

  const prodRegex = /^(main|app)\.xspecs\.(io|ai)$/;
  if (prodRegex.test(hostname)) {
    return 'main';
  }

  const subdomainRegex = /^([a-zA-Z0-9_-]+)\.xspecs\.io$/;
  const subdomainMatch = hostname.match(subdomainRegex);

  if (subdomainMatch) {
    return subdomainMatch[1];
  }

  return null;
}

export const FILE_CACHE_PREFIX = 'file-cache';

export const getBlockByIdRescursively = (blocks: any[], id: string): any => {
  for (const block of blocks) {
    if (block.id === id) {
      return block;
    }
    if (block.children) {
      const child = getBlockByIdRescursively(block.children, id);
      if (child) {
        return child;
      }
    }
  }
};

export const countKeyOccurrences = (obj: object, key: string, valuesToExclude: any[]) => {
  let count = 0;
  const traverseObject = (obj) => {
    for (const prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        if (prop === key && !valuesToExclude.includes(obj[prop])) {
          count++;
        }
        if (typeof obj[prop] === 'object') {
          traverseObject(obj[prop]);
        }
      }
    }
  };

  traverseObject(obj);
  return count;
};

export const removeProperty = (obj: object, prop: string) => {
  const clonedObj = cloneDeep(obj);

  const traverseObject = (obj, prop) => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (key === prop) {
          delete obj[key];
        } else if (typeof obj[key] === 'object') {
          return traverseObject(obj[key], prop);
        }
      }
    }
  };

  return traverseObject(clonedObj, prop);
};

export const isOrganizationNameValid = (name: string) => {
  const organizationNameRegex = /^[a-z\d.\-_]+$/;
  return organizationNameRegex.test(name);
};

export function getCursorData(): CursorData {
  const localStorageCache = new LocalStorageCache();
  const result = localStorageCache.get(AUTH0_LOCAL_TOKEN_KEY) as CacheEntry;

  const anonymusUser = {
    color: randomColor({ luminosity: 'dark', alpha: 1, format: 'hex' }),
    id: uuidv4(),
    sub: '',
    name: '',
    picture: '',
  };
  const decodedToken = result.decodedToken;
  if (!decodedToken) {
    logger.error('No decoded token found in local storage');
    return anonymusUser;
  }

  const user = decodedToken.user;
  const color = randomColor({ luminosity: 'dark', alpha: 1, format: 'hex' });

  // const colorsToExclude = []
  // const color =`
  //   colorsToExclude.length === cursorUserColors.length
  //     ? randomColor({ luminosity: 'dark', alpha: 1, format: 'hex' })
  //     : cursorUserColors.find((color) => !colorsToExclude.includes(color));

  return {
    color,
    id: uuidv4(),
    sub: user.sub ?? user.email ?? '',
    name: user.name || user.nickname || user.email || '',
    picture: user.picture ?? '',
  };
}

export function addAlpha(hexColor: string, opacity: number): string {
  const normalized = Math.round(Math.min(Math.max(opacity, 0), 1) * 255);
  return hexColor + normalized.toString(16).toUpperCase();
}

export const isProd =
  location.hostname.startsWith('app.xspecs.ai') || location.hostname.startsWith('app.narrative.tech');

export const isDev = location.hostname.startsWith('localhost');

export const resolveProviderUrl = () => {
  if (location.origin.includes('localhost')) return 'ws://127.0.0.1:8080';
  if (location.origin.includes('app.xspecs.ai')) return 'wss://fs.narrative.tech';
  if (location.origin.includes('app.narrative.tech')) return 'wss://fs.narrative.tech';
  return 'wss://fsdev.xspecs.io';
};

export const resolveHost = () => {
  if (location.origin.includes('localhost')) return undefined;
  if (location.origin.includes('app.xspecs.ai') || location.origin.includes('app.narrative.tech')) return undefined;
  return `${location.origin.split('.')[0].split('//')[1]}`;
};

export function isValidDomainName(domain: string): boolean {
  const pattern = /^(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}$/;
  return pattern.test(domain);
}

export function arraysAreEqualUnsorted(arr1: string[], arr2: string[]): boolean {
  if (arr1.length !== arr2.length) {
    return false;
  }

  const sortedArr1 = [...arr1].sort();
  const sortedArr2 = [...arr2].sort();

  for (let i = 0; i < sortedArr1.length; i++) {
    if (sortedArr1[i] !== sortedArr2[i]) {
      return false;
    }
  }

  return true;
}

export const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
