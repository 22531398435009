import { CommandBase, IParams } from '../framework/CommandBase';
import { CommandError } from '../../ErrorStore';
import { EventBase } from '../framework/EventBase';
import { EntityChanges } from '../../types';

interface BroadcastChangesCommandParams extends IParams {
  changes: EntityChanges;
}

export class ChangesSavedEvent extends EventBase {
  static eventType = 'ChangesSavedEvent';
  constructor(public readonly changes: EntityChanges) {
    super();
  }
}

export class BroadcastSavedChangesCommand extends CommandBase<BroadcastChangesCommandParams> {
  execute(params: BroadcastChangesCommandParams): ChangesSavedEvent | CommandError | undefined {
    const currentScopes = this.model.entityRepository.getScopes();
    const scopedChanged = this.filterChangesByScope(params.changes, currentScopes);
    if (
      !(scopedChanged.added.length === 0 && scopedChanged.updated.length === 0 && scopedChanged.deleted.length === 0)
    ) {
      return new ChangesSavedEvent(scopedChanged);
    }
  }

  private filterChangesByScope(changes: EntityChanges, scopes: string[]): EntityChanges {
    const filteredChanges: EntityChanges = {
      added: [],
      updated: [],
      deleted: [],
    };

    filteredChanges.added = changes.added.filter((entity) => this.entityHasScope(entity, scopes));
    filteredChanges.updated = changes.updated.filter((entity) => this.entityHasScope(entity.entity, scopes));
    filteredChanges.deleted = changes.deleted.filter((entity) => this.entityHasScope(entity, scopes));

    return filteredChanges;
  }

  private entityHasScope(entity: { scopes: string[] }, scopes: string[]): boolean {
    return entity.scopes?.some((scope) => scopes.includes(scope) || scope === '*');
  }
}
