import { FileBase } from './FileBase';
import { YMap } from 'yjs/dist/src/types/YMap';
import { FileType, Status } from './File.types';

export class ModelFile extends FileBase {
  public storageMap: YMap<any>;
  public versionMap: YMap<any>;

  getType(): FileType {
    return FileType.Model;
  }

  load() {
    this.storageMap = this.document!.getMap<any>('single-source-model');
    this.versionMap = this.document!.getMap<any>('version-history');
    this.status = Status.Initial;
  }
}
