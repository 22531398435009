import { IconComponentProps } from '../icon';
import { FC } from 'react';

export const GoogleSheets: FC<IconComponentProps> = () => {
  return (
    <>
      <mask
        id="mask0_1600_1002"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="4"
        y="1"
        width="13"
        height="18"
      >
        <path
          d="M11.6972 1.66675H5.29625C4.67498 1.66675 4.16667 2.1697 4.16667 2.78443V16.9417C4.16667 17.5564 4.67498 18.0594 5.29625 18.0594H15.0859C15.7072 18.0594 16.2155 17.5564 16.2155 16.9417V6.13746L11.6972 1.66675Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_1600_1002)">
        <path
          d="M11.6972 1.66675H5.29625C4.67498 1.66675 4.16667 2.1697 4.16667 2.78443V16.9417C4.16667 17.5564 4.67498 18.0594 5.29625 18.0594H15.0859C15.7072 18.0594 16.2155 17.5564 16.2155 16.9417V6.13746L13.5798 4.27466L11.6972 1.66675Z"
          fill="#0F9D58"
        />
      </g>
      <mask
        id="mask1_1600_1002"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="4"
        y="1"
        width="13"
        height="18"
      >
        <path
          d="M11.6972 1.66675H5.29625C4.67498 1.66675 4.16667 2.1697 4.16667 2.78443V16.9417C4.16667 17.5564 4.67498 18.0594 5.29625 18.0594H15.0859C15.7072 18.0594 16.2155 17.5564 16.2155 16.9417V6.13746L11.6972 1.66675Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_1600_1002)">
        <path
          d="M7.17891 9.67676V15.0789H13.2033V9.67676H7.17891ZM9.81459 14.3338H7.93196V13.4024H9.81459V14.3338ZM9.81459 12.8435H7.93196V11.9121H9.81459V12.8435ZM9.81459 11.3533H7.93196V10.4219H9.81459V11.3533ZM12.4503 14.3338H10.5676V13.4024H12.4503V14.3338ZM12.4503 12.8435H10.5676V11.9121H12.4503V12.8435ZM12.4503 11.3533H10.5676V10.4219H12.4503V11.3533Z"
          fill="#F1F1F1"
        />
      </g>
      <mask
        id="mask2_1600_1002"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="4"
        y="1"
        width="13"
        height="18"
      >
        <path
          d="M11.6972 1.66675H5.29625C4.67498 1.66675 4.16667 2.1697 4.16667 2.78443V16.9417C4.16667 17.5564 4.67498 18.0594 5.29625 18.0594H15.0859C15.7072 18.0594 16.2155 17.5564 16.2155 16.9417V6.13746L11.6972 1.66675Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask2_1600_1002)">
        <path d="M12.0276 5.81055L16.2155 9.95341V6.13747L12.0276 5.81055Z" fill="url(#paint0_linear_1600_1002)" />
      </g>
      <mask
        id="mask3_1600_1002"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="4"
        y="1"
        width="13"
        height="18"
      >
        <path
          d="M11.6972 1.66675H5.29625C4.67498 1.66675 4.16667 2.1697 4.16667 2.78443V16.9417C4.16667 17.5564 4.67498 18.0594 5.29625 18.0594H15.0859C15.7072 18.0594 16.2155 17.5564 16.2155 16.9417V6.13746L11.6972 1.66675Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask3_1600_1002)">
        <path
          d="M11.6972 1.66675V5.01978C11.6972 5.6373 12.2027 6.13746 12.8268 6.13746H16.2155L11.6972 1.66675Z"
          fill="#87CEAC"
        />
      </g>
      <mask
        id="mask4_1600_1002"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="4"
        y="1"
        width="13"
        height="18"
      >
        <path
          d="M11.6972 1.66675H5.29625C4.67498 1.66675 4.16667 2.1697 4.16667 2.78443V16.9417C4.16667 17.5564 4.67498 18.0594 5.29625 18.0594H15.0859C15.7072 18.0594 16.2155 17.5564 16.2155 16.9417V6.13746L11.6972 1.66675Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask4_1600_1002)">
        <path
          d="M5.29625 1.66675C4.67498 1.66675 4.16667 2.1697 4.16667 2.78443V2.87757C4.16667 2.26284 4.67498 1.75989 5.29625 1.75989H11.6972V1.66675H5.29625Z"
          fill="white"
          fillOpacity="0.2"
        />
      </g>
      <mask
        id="mask5_1600_1002"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="4"
        y="1"
        width="13"
        height="18"
      >
        <path
          d="M11.6972 1.66675H5.29625C4.67498 1.66675 4.16667 2.1697 4.16667 2.78443V16.9417C4.16667 17.5564 4.67498 18.0594 5.29625 18.0594H15.0859C15.7072 18.0594 16.2155 17.5564 16.2155 16.9417V6.13746L11.6972 1.66675Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask5_1600_1002)">
        <path
          d="M15.0859 17.9663H5.29625C4.67498 17.9663 4.16667 17.4634 4.16667 16.8486V16.9418C4.16667 17.5565 4.67498 18.0595 5.29625 18.0595H15.0859C15.7072 18.0595 16.2155 17.5565 16.2155 16.9418V16.8486C16.2155 17.4634 15.7072 17.9663 15.0859 17.9663Z"
          fill="#263238"
          fillOpacity="0.2"
        />
      </g>
      <mask
        id="mask6_1600_1002"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="4"
        y="1"
        width="13"
        height="18"
      >
        <path
          d="M11.6972 1.66675H5.29625C4.67498 1.66675 4.16667 2.1697 4.16667 2.78443V16.9417C4.16667 17.5564 4.67498 18.0594 5.29625 18.0594H15.0859C15.7072 18.0594 16.2155 17.5564 16.2155 16.9417V6.13746L11.6972 1.66675Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask6_1600_1002)">
        <path
          d="M12.8268 6.13745C12.2027 6.13745 11.6972 5.63729 11.6972 5.01978V5.11292C11.6972 5.73043 12.2027 6.23059 12.8268 6.23059H16.2155V6.13745H12.8268Z"
          fill="#263238"
          fillOpacity="0.1"
        />
      </g>
      <path
        d="M11.9792 1.66675H5.33855C4.69402 1.66675 4.16667 2.17811 4.16667 2.80311V17.1971C4.16667 17.8221 4.69402 18.3334 5.33855 18.3334H15.4948C16.1393 18.3334 16.6667 17.8221 16.6667 17.1971V6.2122L11.9792 1.66675Z"
        fill="url(#paint1_radial_1600_1002)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1600_1002"
          x1="221.446"
          y1="41.3816"
          x2="221.446"
          y2="420.154"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#263238" stopOpacity="0.2" />
          <stop offset="1" stopColor="#263238" stopOpacity="0.02" />
        </linearGradient>
        <radialGradient
          id="paint1_radial_1600_1002"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(43.7673 34.6074) scale(2015.61 1954.53)"
        >
          <stop stopColor="white" stopOpacity="0.1" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </radialGradient>
      </defs>
    </>
  );
};
