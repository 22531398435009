import { Alert, Stack } from '@mui/material';
import { AssetAccordion } from './asset-accordion';
import { DeleteAssetModal } from '../../delete-asset-modal/delete-asset-modal';
import { AssetExplorerResult, AssetScope } from '@xspecs/single-source-model';
import { useIntl } from 'react-intl';
import { useSingleSourceStore } from '../../../store/single-source-store/single-source-store';
import { NoSearchResults } from '../../no-search-results/no-search-results';
import { memo } from 'react';
import { SwitchAssetScope } from './switch-asset-scope/switch-asset-scope';

export type AssetExplorerProps = {
  assets: AssetExplorerResult;
  scope: AssetScope;
};

const _AssetExplorerList = (props: Pick<AssetExplorerProps, 'assets'>) => {
  const { assets } = props;

  return (
    <Stack gap={1}>
      {assets.map((asset) => (
        <AssetAccordion key={asset.id} {...asset} />
      ))}
    </Stack>
  );
};

const AssetExplorerList = memo(_AssetExplorerList);

export const AssetExplorer = (props: AssetExplorerProps) => {
  const { assets, scope } = props;
  const { formatMessage: f } = useIntl();

  const { searchQuery } = useSingleSourceStore.use.assetExplorer();
  const hasAssets = assets && assets.length > 0;

  if (!hasAssets && searchQuery) {
    return <NoSearchResults />;
  }

  if (!hasAssets) {
    return (
      <>
        <SwitchAssetScope scope={scope} />
        <Alert severity="info" sx={{ pl: 1.1 }}>
          {f({ id: 'no-assets-found' })}
        </Alert>
      </>
    );
  }

  return (
    <>
      <SwitchAssetScope scope={scope} />
      <AssetExplorerList assets={assets} />
      <DeleteAssetModal />
    </>
  );
};
