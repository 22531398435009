import { Alert, Button, Snackbar, Stack, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { RESTORE_VERSION_MUTATION } from '../graphql/mutations';
import { Restore } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { useSnackStack } from '../wrappers/snack-stack-context';
import { useActiveWorkspace } from '../hooks/use-active-workspace';
import { useActiveOrganization } from '../hooks/use-active-organization';

type PreviewModeBannerProps = {
  version: string;
};

export const PreviewModeBanner = (props: PreviewModeBannerProps) => {
  const { version } = props;

  const { formatMessage: f } = useIntl();
  const { addToast } = useSnackStack();

  const [restoreVersion, { loading: restoringVersion }] = useMutation(RESTORE_VERSION_MUTATION);

  const { organization } = useActiveOrganization();
  const { workspace } = useActiveWorkspace();
  const disabled = !version || !organization || !workspace;

  const onGoBack = useCallback(() => {
    if (window.opener) {
      window.close();
      window.opener.focus();
    } else {
      window.location.href = window.location.href.split('?')[0];
    }
  }, []);

  const onRestore = useCallback(async () => {
    if (disabled) return;
    const result = await restoreVersion({
      variables: { args: { organizationId: organization.id, scope: workspace.id, fileVersion: version } },
    });
    if (result.data?.restoreVersion.error)
      addToast({
        message: f({ id: result.data.restoreVersion.error }),
        severity: 'error',
      });
    else window.location.reload();
  }, [addToast, disabled, f, organization, restoreVersion, version, workspace]);

  return (
    <Snackbar open anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
      <Alert severity="info" sx={{ alignItems: 'center' }}>
        <Stack direction="row" gap={1} alignItems="center">
          <Typography>{f({ id: 'preview-mode-info' })}</Typography>
          <Stack direction="row" gap={1}>
            <Button size="small" sx={buttonSx} onClick={onGoBack}>
              {f({ id: 'go-back' })}
            </Button>
            <LoadingButton
              size="small"
              color="error"
              sx={buttonSx}
              disabled={!version}
              onClick={onRestore}
              startIcon={<Restore />}
              loadingPosition="start"
              loading={restoringVersion}
            >
              {f({ id: 'restore' })}
            </LoadingButton>
          </Stack>
        </Stack>
      </Alert>
    </Snackbar>
  );
};

const buttonSx = {
  whiteSpace: 'nowrap',
};
