import { EntityType } from '../EntityType';

export class LaneConfig {
  constructor(
    public ingests: (EntityType | string)[],
    public displayName?: string,
    public maxEntities?: number,
    public color?: string,
    public conflictingEntityTypeGroups: EntityType[][] = [],
  ) {}
}
