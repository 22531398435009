import { ChooseOrganization } from '../../pages/choose-organization/choose-organization';
import { useQuery } from '@apollo/client';
import { ORGANIZATIONS_QUERY } from '../../graphql/queries';
import { Box } from '@mui/material';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getRoutePathFromParams, RoutePaths } from '../../config/route-paths/route-paths';
import { LAST_SELECTED_ORGANIZATION_ID_KEY } from '../../lib/utils';
import { Loading } from '../../components/loading/loading';

export const ChooseOrganizationRoute = () => {
  const { data, loading } = useQuery(ORGANIZATIONS_QUERY, {
    fetchPolicy: 'no-cache',
  });

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (loading) return;
    if (data?.organizations.length === 0) navigate(RoutePaths.CreateOrganization);
  }, [data?.organizations.length, loading, navigate]);

  useEffect(() => {
    const lastSelectedOrganizationId = localStorage.getItem(LAST_SELECTED_ORGANIZATION_ID_KEY);
    if (!lastSelectedOrganizationId || loading) return;
    const organization = data?.organizations.find((org) => org.id === lastSelectedOrganizationId);
    if (organization)
      navigate(
        getRoutePathFromParams(RoutePaths.Organization, {
          organizationName: organization.name,
        }),
      );
    else localStorage.removeItem(LAST_SELECTED_ORGANIZATION_ID_KEY);
  }, [data?.organizations, loading, location, navigate]);

  if (loading) {
    return <Loading rootProps={{ sx: { width: '100%', height: '100%' } }} />;
  }

  return (
    <Box data-testid="choose-organization-route">
      <ChooseOrganization items={data?.organizations || []} loading={loading} />
    </Box>
  );
};
