import { Stack } from '@mui/material';
import { useCallback } from 'react';
import { QueryDetails } from '../../entity-details/query-details';
import { useReactFlow, useStoreApi } from '@xyflow/react';

type SidePanelProps = {
  queryId: string;
  queryText: string;
};

export const SidePanel = (props: SidePanelProps) => {
  const { queryId, queryText } = props;

  const { screenToFlowPosition } = useReactFlow();
  const storeApi = useStoreApi();

  const getViewport = useCallback(() => {
    const domNode = storeApi.getState().domNode;
    if (!domNode) throw new Error('Reactflow dom node not found');
    const { x, y, width, height } = domNode.getBoundingClientRect();
    const flowPosition = screenToFlowPosition({ x, y });
    const flowPosition2 = screenToFlowPosition({ x: x + width, y: y + height });
    return {
      minX: flowPosition.x,
      minY: flowPosition.y,
      maxX: flowPosition2.x,
      maxY: flowPosition2.y,
    };
  }, [screenToFlowPosition, storeApi]);

  return (
    <Stack width="100%" height="100%" p={1.5} gap={1} borderLeft="1px solid #E0E0E0" borderRadius="inherit">
      <Stack flexGrow={1}>
        <QueryDetails id={queryId} noAnnotateDesign queryText={queryText} getViewport={getViewport} />
      </Stack>
    </Stack>
  );
};
