import { Box, Stack, SxProps, Theme } from '@mui/material';
import { AttachmentType, EntityDetailsState, EntityType } from '@xspecs/single-source-model';
import { useMemo } from 'react';
import { useSingleSourceStore } from '../../../store/single-source-store/single-source-store';
import { DocEditor } from '../../doc/editor/doc-editor';
import { Spec } from '../../spec/spec';
import { EntityDescription } from '../entity/entity-description/entity-description';
import { EntityHeader } from '../entity/entity-header/entity-header';
import { QueryDetails } from './query-details';
import { SchemaEditor } from './schema-editor';

type EntityDetailsProps = {
  entity: EntityDetailsState;
};

export const EntityDetails = (props: EntityDetailsProps) => {
  const { entity } = props;

  const { isSpec, isDoc, isQuery, isSchema } = useMemo(
    () => ({
      isSpec: Boolean(
        entity.type === EntityType.Spec ||
          (entity.type === EntityType.Attachment && entity.subType === AttachmentType.Spec),
      ),
      isDoc: Boolean(
        entity.type === EntityType.Doc ||
          (entity.type === EntityType.Attachment && entity.subType === AttachmentType.Doc),
      ),
      isQuery: Boolean(
        entity.type === EntityType.Query ||
          (entity.type === EntityType.Attachment && entity.subType === AttachmentType.Query),
      ),
      isSchema: Boolean(
        entity.type === EntityType.Schema ||
          (entity.type === EntityType.Attachment && entity.subType === AttachmentType.Schema),
      ),
    }),
    [entity],
  );

  const Content = useMemo(() => {
    const asset = entity.type === EntityType.Attachment ? entity.asset : entity;
    if (!asset) return null;

    if (isSchema) {
      return <SchemaEditor id={asset.id} />;
    }

    if (isQuery || isSchema) {
      return <QueryDetails id={asset.id} queryText={entity.queryText ?? ''} />;
    }

    if (isDoc) {
      return <DocEditor documentId={asset.id} />;
    }

    if (isSpec) {
      return (
        <Stack gap={1} height="100%">
          <Spec id={asset.id} />
        </Stack>
      );
    }

    return null;
  }, [entity, isDoc, isQuery, isSchema, isSpec]);

  const annotator = useSingleSourceStore.use.annotator();

  const containerProps = useMemo<SxProps<Theme>>(
    () => ({
      overflowY: 'scroll',
      border: !isQuery ? '1px solid #eee' : undefined,
      borderRadius: 2,
      p: 1,
      flexGrow: 1,
    }),
    [isQuery],
  );

  return (
    <Stack height="100%">
      <EntityHeader id={entity.id} type={entity.type} name={entity.name} iconUrl={entity.iconUrl} />
      {!isDoc && !isSpec && !isQuery && !isSchema ? (
        <Box sx={containerProps}>
          <EntityDescription entityId={entity.id} />{' '}
        </Box>
      ) : null}
      {!annotator?.showAnnotatorView && Content ? <Stack sx={containerProps}>{Content}</Stack> : null}
    </Stack>
  );
};
