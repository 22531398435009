import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useIntl } from 'react-intl';
import { reset } from './demo-workspace';
import { useSnackStack } from '../../wrappers/snack-stack-context';
import { useSingleSourceStore } from '../../store/single-source-store/single-source-store';
import axios from 'axios';
import { logger } from '@xspecs/logger';
import { useActiveWorkspace } from '../../hooks/use-active-workspace';

interface WorkspaceResetStateModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const WorkspaceResetStateModal = (props: WorkspaceResetStateModalProps) => {
  const { isOpen, onClose } = props;
  const { addToast } = useSnackStack();

  const instantMockUrl = useSingleSourceStore.use.instantMockUrl();

  const { workspace } = useActiveWorkspace();

  const { formatMessage: f } = useIntl();

  const onResetWorkspace = async () => {
    try {
      if (workspace?.name) {
        await axios.post(`${instantMockUrl}/graphs/${workspace.name}/reset`, undefined, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
      }

      reset();

      addToast({ message: f({ id: 'reset-state-success' }), severity: 'success' });
    } catch (e) {
      logger.log(e);
      addToast({ message: f({ id: 'reset-state-error' }), severity: 'error' });
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>{f({ id: 'are-you-sure-you-want-to-reset' })}</DialogTitle>
      <DialogContent>
        <DialogContentText>{f({ id: 'resetting-this-irreversible' })}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {f({ id: 'cancel' })}
        </Button>
        <Button onClick={onResetWorkspace} color="primary">
          {f({ id: 'reset-button' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
