import { makeVar } from '@apollo/client';
import { NavBarProps } from '../components/nav-bar/nav-bar';
import { MediaType } from '../components/insert-media-modal/insert-media-modal';
import { WebSocketStatus } from '@hocuspocus/provider';

export const navBarOverridePropsVar = makeVar<NavBarProps>({});

export const showInsertMenuVar = makeVar(false);

export const showDocLinkTreeViewVar = makeVar(false);

export const showSearchVar = makeVar<boolean>(false);

export const showOrganizationSettingsVar = makeVar(false);

export const showWorkspaceSettingsVar = makeVar(false);

export const showInviteUsersVar = makeVar(false);

export const authStatusVar = makeVar<{ status: 'unauthorized' | 'expired' | null }>({ status: null });

export const insertMediaModalToShow = makeVar<MediaType | null>(null);

export const activeCommentIdVar = makeVar<string | null>(null);

export const connectionStatusVar = makeVar<Record<string, { synced?: boolean; status?: WebSocketStatus }>>({});
