import { IconButton, MenuItem as MuiMenuItem, Stack, Typography } from '@mui/material';
import { ChangeEventHandler, FC, useCallback, useRef } from 'react';
import {
  MenuButtonMenuItemToolbarElement,
  MenuButtonToolbarElement,
  ToolbarElementProps,
  UploadType,
} from '@xspecs/single-source-model';
import { Menu } from '@szhsin/react-menu';
import { Icon } from '../../../icons/icon';
import { useCommandDispatch } from '../../../wrappers/application-context/application-context';
import { sid } from '@xspecs/short-id';
import { useSnackStack } from '../../../wrappers/snack-stack-context';
import { FILE_UPLOAD_URLS_QUERY } from '../../../graphql/queries';
import { useLazyQuery } from '@apollo/client';
import { useImageDimensions } from '../../single-source-model/canvas/hooks/use-image-dimensions';
import { useIntl } from 'react-intl';
import { useCanvasCenter } from '../../../hooks/use-canvas-center';
import { resizeImage } from '../../../utils/resizeImage';

export const MenuButton: FC<ToolbarElementProps<MenuButtonToolbarElement>> = (props) => {
  const { element } = props;

  return (
    <Menu
      menuButton={
        <IconButton sx={buttonSx}>
          <Icon name={element.icon} />
        </IconButton>
      }
      position="anchor"
      direction="top"
    >
      {element.menu.map((item, index) => (
        <MenuItem key={`ToolbarMenuButton${index}`} element={item} />
      ))}
    </Menu>
  );
};

const buttonSx = {
  borderRadius: 1,
};

type MenuItemProps = {
  element: MenuButtonMenuItemToolbarElement;
};

const MenuItem = (props: MenuItemProps) => {
  const { element } = props;
  const dispatch = useCommandDispatch();
  const { addToast } = useSnackStack();
  const [getPreSignedUrl] = useLazyQuery(FILE_UPLOAD_URLS_QUERY, {
    fetchPolicy: 'no-cache',
  });
  const { getImageDimensions } = useImageDimensions();
  const { formatMessage: f } = useIntl();
  const getCanvasCenter = useCanvasCenter();

  const inputRef = useRef<HTMLInputElement>(null);
  const supportsUpload = Boolean(element.onUpload);

  const onClick = useCallback(() => {
    if (supportsUpload) {
      inputRef.current?.click();
    } else if (element.onClick) {
      dispatch(element.onClick.command, element.onClick.params);
    }
  }, [dispatch, element.onClick, supportsUpload]);

  const onChangeInternal = useCallback<ChangeEventHandler<HTMLInputElement>>(
    async (e) => {
      if (!element.onUpload) return;

      const assetId = sid();
      if (!e.target.files) return;
      const file = e.target.files[0];
      if (!file) return;
      const { data } = await getPreSignedUrl({ variables: { parentId: assetId } });
      if (!data?.signedMediaUploadUrl) {
        addToast({
          message: f({ id: 'failed-to-get-signed-media-upload-url' }),
          severity: 'error',
        });
        return;
      }
      const uploadFileResult = await fetch(data.signedMediaUploadUrl.putUrl, {
        method: 'PUT',
        headers: { 'Content-Type': file.type || 'application/octet-stream' },
        body: file,
      });
      if (!uploadFileResult.ok) {
        addToast({
          message: f({ id: 'failed-to-upload-file' }),
          severity: 'error',
        });
        return;
      }
      const type = file.type.startsWith('image') ? UploadType.Image : UploadType.File;
      const attachmentId = sid();
      const center = getCanvasCenter();

      let imgDimensions;
      if (type === UploadType.Image) {
        let originalDimensions;

        try {
          originalDimensions = await getImageDimensions(file);
        } catch (error) {
          originalDimensions = {};
        } finally {
          imgDimensions = resizeImage(originalDimensions);
        }
      }

      dispatch(element.onUpload.command, {
        assetId,
        id: attachmentId,
        name: file.name,
        position: { x: center.x, y: center.y },
        type,
        url: data.signedMediaUploadUrl.getUrl,
        metadata: imgDimensions,
      });
    },
    [addToast, dispatch, element.onUpload, f, getCanvasCenter, getImageDimensions, getPreSignedUrl],
  );

  const onChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      onChangeInternal(e);
      if (inputRef.current) inputRef.current.value = '';
    },
    [onChangeInternal],
  );

  return (
    <MuiMenuItem onClick={onClick}>
      {supportsUpload ? <input type="file" style={{ display: 'none' }} ref={inputRef} onChange={onChange} /> : null}
      <Stack direction="row" gap={1}>
        <Icon name={element.icon} />
        <Typography>{element.label}</Typography>
      </Stack>
    </MuiMenuItem>
  );
};
