import { NotificationTypes } from './observable/SingleSourceObserver';
import { SingleSourceModel } from './SingleSourceModel';
import { sid } from '@xspecs/short-id';

type Severity = 'error' | 'success' | 'info' | 'warning';
export class CommandError {
  key: string;
  private constructor(public error: Error, public severity: Severity) {
    this.key = sid();
  }

  static of(error: Error | string, severity: Severity): CommandError {
    return new CommandError(error instanceof Error ? error : new Error(error), severity);
  }
}

export class ErrorStore {
  public constructor(private readonly model: SingleSourceModel) {}

  public errors: CommandError[] = [];

  public addError(error: CommandError) {
    this.errors.push(error);
    this.model.observer.notify(NotificationTypes.OnError);
  }

  public clearError(error: CommandError) {
    this.errors = this.errors.filter((e) => e.key !== error.key);
    this.model.observer.notify(NotificationTypes.OnError);
  }
}
