import { FC } from 'react';
import { IconComponentProps } from '../icon';

export const Explorer: FC<IconComponentProps> = () => {
  return (
    <>
      <rect x="0.333008" width="16.6667" height="13.33333" rx="1" fill="white" />
      <rect x="0.833008" y="0.5" width="15.66667" height="12.33333" rx="0.5" stroke="black" strokeOpacity="0.54" />
      <rect x="1.66602" y="2.3335" width="16.6667" height="13.33333" rx="1" fill="white" />
      <rect x="2.16602" y="2.8335" width="15.66667" height="12.33333" rx="0.5" stroke="black" strokeOpacity="0.54" />
      <rect x="3" y="4.6665" width="16.6667" height="13.33333" rx="1" fill="white" />
      <rect x="3.5" y="5.1665" width="15.66667" height="12.33333" rx="0.5" stroke="black" strokeOpacity="0.54" />
    </>
  );
};
