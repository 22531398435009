import { IconComponentProps } from '../icon';
import { FC } from 'react';

export const Figma: FC<IconComponentProps> = () => {
  return (
    <>
      <path
        d="M10.3188 10.0002C10.3188 8.46606 11.5624 7.22241 13.0965 7.22241C14.6306 7.22241 15.8743 8.4661 15.8743 10.0002C15.8743 11.5343 14.6306 12.7779 13.0965 12.7779C11.5624 12.7779 10.3188 11.5343 10.3188 10.0002Z"
        fill="#00BCFF"
      />
      <path
        d="M4.76318 15.5556C4.76318 14.0215 6.00684 12.7779 7.54095 12.7779L9.02126 11.9963L10.3187 12.7779V15.5556C10.3187 17.0898 9.07506 18.3334 7.54095 18.3334C6.00684 18.3334 4.76318 17.0898 4.76318 15.5556Z"
        fill="#00CF7F"
      />
      <path
        d="M10.3187 1.66675L8.80722 4.29272L10.3187 7.22228H13.0575C14.5917 7.22228 15.8353 5.97863 15.8353 4.44451C15.8353 2.9104 14.5917 1.66675 13.0575 1.66675H10.3187Z"
        fill="#FF7361"
      />
      <path
        d="M4.72418 4.44451C4.72418 5.97863 5.96783 7.22228 7.50195 7.22228L8.97548 7.79142L10.3187 7.22228V1.66675H7.50192C5.96783 1.66675 4.72418 2.9104 4.72418 4.44451Z"
        fill="#FF4D12"
      />
      <path
        d="M4.76318 10.0002C4.76318 11.5344 6.00684 12.778 7.54095 12.778H10.3187V7.22241H7.54095C6.00684 7.22241 4.76318 8.4661 4.76318 10.0002Z"
        fill="#B659FF"
      />
    </>
  );
};
