import { FC } from 'react';
import { IconComponentProps } from '../icon';

export const SortingAZ: FC<IconComponentProps> = ({ color }) => {
  return (
    <>
      <path d="M17 6H21L17 17H21" stroke={color} strokeWidth="2" strokeLinecap="square" strokeLinejoin="round" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.47217 7.47813C4.76245 7.15882 5.1346 7 5.5 7C5.8654 7 6.23755 7.15882 6.52783 7.47813C6.82099 7.80061 7 8.25674 7 8.75V11.7227H4V8.75C4 8.25674 4.17901 7.80061 4.47217 7.47813ZM2 12.7227V8.75C2 7.78457 2.34777 6.84175 2.99229 6.13278C3.63969 5.42064 4.53932 5 5.5 5C6.46068 5 7.36031 5.42064 8.00771 6.13278C8.65223 6.84175 9 7.78457 9 8.75V17V18H7V17V13.7227H4V17V18H2V17V12.7227Z"
        fill={color}
      />
      <path d="M11 12H14" stroke={color} strokeWidth="2" strokeLinecap="square" strokeLinejoin="round" />
    </>
  );
};
