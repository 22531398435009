import { FC } from 'react';
import { Stack } from '@mui/material';
import { DetailElementComponent } from './render-element';
import { DetailsElementType, TabsDetailsElement } from '@xspecs/single-source-model';
import { EntityDetailsState } from '@xspecs/single-source-model';

const layout = {
  elements: [
    {
      type: DetailsElementType.Tabs,
      elements: [
        {
          type: DetailsElementType.Tab,
          label: 'Details',
          elements: [
            {
              type: DetailsElementType.Textbox,
              label: 'Summary',
              value: '{{ticket.summary}}',
            },
            {
              type: DetailsElementType.Textbox,
              label: 'Issue Type',
              value: '{{ticket.issueType}}',
            },
            {
              type: DetailsElementType.Textbox,
              label: 'Priority',
              value: '{{ticket.priority.name}}',
            },
            {
              type: DetailsElementType.Textbox,
              label: 'Status',
              value: '{{ticket.status}}',
            },
          ],
        },
        {
          type: DetailsElementType.Tab,
          label: 'Description',
          elements: [],
        },
        {
          type: DetailsElementType.Tab,
          label: 'Comments',
          elements: [],
        },
      ],
    } satisfies TabsDetailsElement,
  ],
};

type CustomEntityDetailsProps = {
  layout: EntityDetailsState['detailsPaneLayout'];
};

export const CustomEntityDetails: FC<CustomEntityDetailsProps> = (props) => {
  const { layout } = props;

  return (
    <Stack>
      {layout?.map((element, index) => (
        <DetailElementComponent key={`CustomEntityDetailsDetailElementComponent${index}`} {...element} />
      ))}
    </Stack>
  );
};
