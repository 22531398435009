import { useAuth, withAuthenticationRequired } from '../../auth';
import { Navigate, Outlet } from 'react-router-dom';
import { RoutePaths } from '../../config/route-paths/route-paths';
import { useReactiveVar } from '@apollo/client';
import { authStatusVar } from '../../state/state';
import { useEffect } from 'react';
import { AppTypeEvent, useTrackEvents } from '../../hooks/use-track-events';
import { useSnackStack } from '../../wrappers/snack-stack-context';
import { useIntl } from 'react-intl';

export const PrivateRoute = withAuthenticationRequired(() => {
  const { formatMessage: f } = useIntl();
  const { user, logout } = useAuth();
  const { trackEvent } = useTrackEvents();
  const { addToast } = useSnackStack();
  const authStatus = useReactiveVar(authStatusVar);

  useEffect(() => {
    if (authStatus.status === 'expired') {
      trackEvent(AppTypeEvent.TokenExpired, null);
      addToast({
        title: f({ id: 'token-expired' }),
        severity: 'warning',
      });
      logout({ logoutParams: { returnTo: window.location.origin + RoutePaths.Login } }).catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e);
      });
    }

    if (authStatus.status === 'unauthorized') {
      addToast({
        title: f({ id: 'unauthorized-to-access' }),
        severity: 'error',
      });
    }
  }, [addToast, authStatus, authStatus.status, f, logout, trackEvent]);

  if (authStatus.status === 'unauthorized') {
    return <Navigate data-testid="no-access" to={RoutePaths.NoAccess} />;
  }

  if (!user?.email_verified) {
    return <Navigate data-testid="confirm-email" to={RoutePaths.ConfirmEmail} />;
  }

  return <Outlet />;
});
