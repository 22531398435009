export const DEBUG_KEYS = {
  analytics: 0,
  boundaries: 0,
  graph: 0,
  clipboard: 0,
  disableIndexedDb: 0,
  entitySelectionTracker: 0,
  fontResizer: 0,
  interactor: 0,
  intersections: 0,
  nodesAndEdges: 0,
  observer: 0,
  yjs: 0,
  messageBus: 0,
  fps: 0,
  workers: 0,
  reactScan: 0,
};

// TEMPLATE
// if (DEBUG_CONFIG.KEY) logger.log('source', objects...);
