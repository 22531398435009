import { CommandBase, IParams } from '../framework/CommandBase';
import { EventBase } from '../framework/EventBase';
import { Label } from '../../entities/assets/Label';
import { CommandError } from '../../ErrorStore';

interface DeleteLabelParams extends IParams {
  id: string;
}

export class LabelDeletedEvent extends EventBase {
  static eventType = 'LabelDeletedEvent';

  constructor(public readonly params: DeleteLabelParams, public readonly source = DeleteLabelCommand) {
    super();
  }
}

export class DeleteLabelCommand extends CommandBase<DeleteLabelParams> {
  execute(params: DeleteLabelParams): EventBase | CommandError | undefined {
    const label = this.model.entityRepository.get<Label>(params.id)!;
    label.entities.forEach((entity) => {
      entity.removeLabel(label);
      this.model.entityRepository.update(entity);
    });
    this.model.entityRepository.delete(params.id);
    return new LabelDeletedEvent(params);
  }
}
