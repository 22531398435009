import { FC, useEffect, useState } from 'react';
import { useSingleSourceStore } from '../../store/single-source-store/single-source-store';
import { FileStatusModal } from './file-status-modal';
import { Status } from '@xspecs/single-source-model';

type FileStatusProps = {
  fileId: string;
};

export const FileStatus: FC<FileStatusProps> = (props) => {
  const { fileId } = props;

  const file = useSingleSourceStore.use.filesById()[fileId];
  const [status, setStatus] = useState(file?.status);

  useEffect(() => {
    // This is to prevent status flickering
    const timeoutId = setTimeout(() => {
      setStatus(file?.status);
    }, 3000);
    return () => clearTimeout(timeoutId);
  }, [file?.status]);

  if (!(status === Status.AuthenticationFailed)) return null;

  return <FileStatusModal isOpen={status === Status.AuthenticationFailed} status={status} />;
};
