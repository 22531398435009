import { CommandBase, IParams } from '../framework/CommandBase';
import { EventBase } from '../framework/EventBase';
import { CommandError } from '../../ErrorStore';

interface ToggleResolvedThreadsVisibilityParams extends IParams {
  value: boolean;
}

interface ResolvedThreadVisibilityToggledEventParams extends IParams {
  value: boolean;
}

export class ResolvedThreadVisibilityToggledEvent extends EventBase {
  static eventType = 'ResolvedThreadVisibilityToggledEvent';

  constructor(
    public readonly params: ResolvedThreadVisibilityToggledEventParams,
    public readonly source = ToggleResolvedThreadsVisibilityCommand,
  ) {
    super();
  }
}

export class ToggleResolvedThreadsVisibilityCommand extends CommandBase<ToggleResolvedThreadsVisibilityParams> {
  execute(params: ToggleResolvedThreadsVisibilityParams): ResolvedThreadVisibilityToggledEvent | CommandError {
    this.model.graph.updatePreferences({ showResolvedThreads: params.value });
    return new ResolvedThreadVisibilityToggledEvent({ value: params.value });
  }
}
