import { CommandBase, IParams } from '../framework/CommandBase';
import { EventBase } from '../framework/EventBase';
import { CommandError } from '../../ErrorStore';
import { DynamicEntityRegistry } from '../../apps/DynamicEntityRegistry';

interface DeRegisterEntitiesParams extends IParams {
  entities: {
    constructs: Array<{ type: string }>;
    assets: Array<{ type: string }>;
  };
}

export class DeRegisterEntitiesCommand extends CommandBase<DeRegisterEntitiesParams> {
  execute(params: DeRegisterEntitiesParams): EventBase | CommandError | undefined {
    const { entities } = params;

    DynamicEntityRegistry.deRegisterEntities(entities.constructs.map((asset) => asset.type));
    this.model.palette.removeConstructs(entities.constructs);

    DynamicEntityRegistry.deRegisterEntities(entities.assets.map((construct) => construct.type));
    this.model.palette.removeAssets(entities.assets);

    return;
  }
}
