import { IconComponentProps } from '../icon';
import { FC } from 'react';

export const Miro: FC<IconComponentProps> = () => {
  return (
    <>
      <path
        d="M5.75 2.5H14.25C16.0312 2.5 17.5 3.96875 17.5 5.75V14.25C17.5 16.0312 16.0312 17.5 14.25 17.5H5.75C3.96875 17.5 2.5 16.0312 2.5 14.25V5.75C2.5 3.96875 3.96875 2.5 5.75 2.5Z"
        fill="#FFD02F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9062 4.375H11.25L12.625 6.78125L9.625 4.375H7.96875L9.46875 7.3125L6.34375 4.375H4.6875L6.34375 8.125L4.6875 15.625H6.34375L9.5 7.59375L7.96875 15.625H9.625L12.6562 7.0625L11.2813 15.625H12.9375L15.9375 6.25L12.9062 4.375Z"
        fill="#050038"
      />
    </>
  );
};
