import { LogoutOutlined, PersonAddAltRounded } from '@mui/icons-material';
import { Box, Button, IconButton } from '@mui/material';
import { NavBarProps } from './nav-bar';
import { SettingsMenu } from './settings-menu/settings-menu';
import { useAuth } from '../../auth';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { showInviteUsersVar } from '../../state/state';
import { RoutePaths } from '../../config/route-paths/route-paths';
import { AppTypeEvent, useTrackEvents } from '../../hooks/use-track-events';
import { UpgradeButton } from './upgrade-button/upgrade-button';
import { HelpMenu } from './help-menu/help-menu';
import { StatusIndicator } from './status-indicator/status-indicator';

type NavRightItemsProps = { isPrimary?: boolean } & Pick<NavBarProps, 'hasOrganizations' | 'isLoggedIn'>;

export const NavRightItems = (props: NavRightItemsProps) => {
  const { isLoggedIn, hasOrganizations, isPrimary } = props;

  const { logout } = useAuth();

  const { trackEvent } = useTrackEvents();

  const { formatMessage: f } = useIntl();

  const onLogout = useCallback(() => {
    trackEvent(AppTypeEvent.LoggedOut, null);
    logout({ logoutParams: { returnTo: window.location.origin + RoutePaths.Login } });
  }, [logout, trackEvent]);

  const onInvite = useCallback(() => showInviteUsersVar(true), []);

  return (
    <Box
      sx={{
        color: { xs: 'primary.contrastText', md: isPrimary ? 'primary.contrastText' : 'action.active' },
        display: 'flex',
        alignItems: 'center',
        height: '100%',
      }}
    >
      {isLoggedIn && !hasOrganizations ? (
        <IconButton data-testid="logout-button" sx={{ color: 'inherit', ml: 'auto', mr: 1 }} onClick={onLogout}>
          <LogoutOutlined color="inherit" />
        </IconButton>
      ) : null}
      {isLoggedIn && hasOrganizations ? (
        <Box sx={{ height: '100%', display: 'flex', alignItems: 'center', gap: 1 }}>
          <Button
            data-testid="nav-right-items-invite-button"
            sx={{ mr: 1, display: { xs: 'none', md: 'flex' } }}
            startIcon={<PersonAddAltRounded />}
            onClick={onInvite}
            size="small"
            variant="outlined"
          >
            {f({ id: 'invite-users' })}
          </Button>
          <UpgradeButton />
          <HelpMenu />
          <StatusIndicator />
          <SettingsMenu />
        </Box>
      ) : null}
    </Box>
  );
};
