import { Box, Icon, Stack, TextField, Typography } from '@mui/material';
import { useCallback, useState } from 'react';
import { Modal } from '../../../modal/modal';
import { useSingleSourceStore } from '../../../../store/single-source-store/single-source-store';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { useIntl } from 'react-intl';
import { useMutation } from '@apollo/client';
import { UPLOAD_APP_MANIFEST_MUTATION } from '../../../../graphql/mutations';
import { useActiveOrganization } from '../../../../hooks/use-active-organization';
import { sid } from '@xspecs/short-id';
import { LoadingButton } from '@mui/lab';
import { logger } from '@xspecs/logger';

const headerSxOverrides = {
  justifyContent: 'space-between',
};
const rootSxOverrides = { width: '700px' };

export const UploadAppModal = () => {
  const [manifestUrl, setManifestUrl] = useState('');
  const [error, setError] = useState('');
  const { formatMessage: f } = useIntl();

  const { organization, refreshActiveOrganization } = useActiveOrganization();

  const [registerApp, { loading }] = useMutation(UPLOAD_APP_MANIFEST_MUTATION);

  const showUploadAppModal = useSingleSourceStore.use.showUploadAppModal();
  const setShowUploadAppModal = useSingleSourceStore.use.setShowUploadAppModal();

  const onClose = useCallback(() => {
    setManifestUrl('');
    setError('');
    setShowUploadAppModal(false);
  }, [setShowUploadAppModal]);

  const onChange = useCallback((e) => {
    setManifestUrl(e.target.value);
  }, []);

  const onUploadManifest = useCallback(async () => {
    if (!organization) {
      logger.error('Organization not found');
      return;
    }

    if (!manifestUrl) {
      setError(f({ id: 'upload-app-error' }));
      return;
    }

    const id = sid();
    const response = await registerApp({
      variables: {
        args: {
          organizationId: organization?.id,
          url: manifestUrl,
          appId: id,
        },
      },
    });
    const responseError = response?.data?.registerApp?.error;
    if (responseError) {
      setError(responseError);
    } else {
      await refreshActiveOrganization();
      onClose();
    }
  }, [f, manifestUrl, onClose, organization, refreshActiveOrganization, registerApp]);

  return (
    <Modal
      id="UploadAppModal"
      isOpen={showUploadAppModal}
      onClose={onClose}
      headerSxOverrides={headerSxOverrides}
      rootSxOverrides={rootSxOverrides}
      headerStartContent={
        <Stack direction="row" alignItems="center" gap={2}>
          <Icon sx={{ fontSize: 32 }} color="primary" component={CloudUploadOutlinedIcon} />
          <Typography variant="h6">{f({ id: 'upload-app' })}</Typography>
        </Stack>
      }
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Typography variant="subtitle1" color="text.secondary">
          {f({ id: 'enter-app-manifest' })}
        </Typography>
        <TextField
          autoFocus
          size="small"
          value={manifestUrl}
          placeholder={f({ id: 'manifest-placeholder' })}
          onChange={onChange}
        />
        {error ? (
          <Typography variant="body1" color="error.main">
            {f({ id: error })}
          </Typography>
        ) : null}
      </Box>
      <Stack direction="row" gap={1} mt={1.5} alignSelf="flex-end">
        <LoadingButton sx={{ display: { xs: 'none', md: 'flex' } }} onClick={onClose} size="small" variant="outlined">
          {f({ id: 'cancel' })}
        </LoadingButton>
        <LoadingButton
          sx={{ display: { xs: 'none', md: 'flex' } }}
          onClick={onUploadManifest}
          size="small"
          variant="contained"
          loading={loading}
        >
          {f({ id: 'upload' })}
        </LoadingButton>
      </Stack>
    </Modal>
  );
};
