import { EntityBase } from '../EntityBase';
import { EntityType } from '../EntityType';
import { ScriptBase } from './ScriptBase';
import { NarrativeScriptLaneNames } from './NarrativeScriptLaneNames';
import { LaneConfig } from './LaneConfig';
import { ScriptConfig } from './ScriptConfig';

export class NarrativeScript extends ScriptBase {
  //static version = '1.0.1'; // Renames entity from Narrative to Script
  //static version = '1.0.2'; // Renames tags to labels
  static version = '1.0.3'; // Renames tags to NarrativeScript

  get config(): ScriptConfig {
    return new ScriptConfig(
      [
        new LaneConfig([EntityType.Interface], NarrativeScriptLaneNames.Interaction, 1, '#F8F8F8'),
        new LaneConfig([EntityType.Moment], NarrativeScriptLaneNames.Context, 1, '#F5F0F8'),
        new LaneConfig([EntityType.Action], NarrativeScriptLaneNames.System, 1, '#F0E1F9'),
      ],
      undefined,
      false,
      false,
      [[EntityType.Interface, EntityType.Action]],
    );
  }

  get type(): EntityType {
    return EntityType.NarrativeScript;
  }

  public entities(displayName: NarrativeScriptLaneNames): EntityBase[] {
    let allEntities: EntityBase[] = [];
    // first we need to find the lane index by displayName of the lane
    let laneIndex: number | undefined;
    this.lanes.forEach((lane, index) => {
      if (lane.displayName === displayName) {
        laneIndex = index;
      }
    });
    // If a matching lane index is found, we fetch entities from all frames at that lane index
    if (laneIndex !== undefined) {
      this.frames.forEach((frame) => {
        if (laneIndex! < frame.entities.length) {
          allEntities = allEntities.concat(frame.entities[laneIndex!]);
        }
      });
    }
    return allEntities;
  }
}
