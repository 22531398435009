import { MessageBus } from '../../commands/framework/MessageBus';
import { ToolbarStateChangedEvent } from '../../commands/toolbar/SetToolbarStateCommand';
import { Toolbar } from './Toolbar';
import { logger } from '@xspecs/logger';
import { ResolvedThreadVisibilityToggledEvent } from '../../commands/comments/ToggleResolvedThreadsVisibilityCommand';

export class ToolbarProjection {
  constructor(private readonly messageBus: MessageBus, private readonly toolbar: Toolbar) {
    messageBus.subscribe([ToolbarStateChangedEvent], this.onToolbarStateChangedEvent.bind(this));
    this.messageBus.subscribe(
      [ResolvedThreadVisibilityToggledEvent],
      this.onResolvedThreadVisibilityToggledEvent.bind(this),
    );
  }

  private onToolbarStateChangedEvent(event: ToolbarStateChangedEvent) {
    switch (event.params.key) {
      case 'compactMode':
        this.toolbar.setCompactMode(event.params.value as boolean);
        break;
      case 'reset':
        this.toolbar.reset();
        break;
      case 'search':
        this.toolbar.search(event.params.value as string);
        break;
      case 'scheme':
        this.toolbar.changeScheme(event.params.value as string);
        break;
      case 'selectedEntity':
        this.toolbar.selectEntity(event.params.value as string);
        break;
      case 'typeFilter':
        // let baseClass: AbstractConstructor<EntityBase> | null = null;
        // if (event.params.value === 'Asset') {
        //   baseClass = AssetBase;
        // } else if (event.params.value === 'Construct') {
        //   baseClass = ConstructBase;
        // }
        this.toolbar.setFilter(event.params.value as string);
        break;
      case 'close':
        this.toolbar.close();
        break;
      default:
        logger.warn(`Invalid key projecting : ${event.params.key}`);
    }
  }

  private onResolvedThreadVisibilityToggledEvent(event: ResolvedThreadVisibilityToggledEvent) {
    this.toolbar.showResolvedComments(event.params.value);
  }
}
