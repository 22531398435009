import React, { FC, useCallback } from 'react';
import { styled, Tab as MuiTab, Tabs as MuiTabs } from '@mui/material';
import { ToolbarElementProps, TabsToolbarElement, TabToolbarElement } from '@xspecs/single-source-model';
import { useCommandDispatch } from '../../../wrappers/application-context/application-context';

export const Tabs: FC<ToolbarElementProps<TabsToolbarElement>> = (props) => {
  const { element } = props;

  const dispatch = useCommandDispatch();

  const onTabClick = useCallback(
    (tab: TabToolbarElement) => () => {
      dispatch(tab.onChange.command, {
        ...tab.onChange.params,
        value: tab.value,
      });
    },
    [dispatch],
  );

  return (
    <StyledTabs value={element.value}>
      {element.tabs.map((tab, index) => (
        <StyledTab
          key={index}
          label={tab.label}
          value={tab.value}
          disableRipple
          disableTouchRipple
          disableFocusRipple
          onClick={onTabClick(tab)}
        />
      ))}
    </StyledTabs>
  );
};

const StyledTabs = styled(MuiTabs)({
  alignItems: 'center',
  '& .MuiTabs-indicator': {
    display: 'none',
  },
});

const StyledTab = styled(MuiTab)(({ theme }) => ({
  textTransform: 'none',
  fontWeight: theme.typography.fontWeightRegular,
  color: '#666666',
  fontSize: theme.typography.pxToRem(14),
  padding: '0px 12px',
  borderRadius: 4,
  minHeight: '20px',
  height: '20px',
  minWidth: 'unset',
  width: 'fit-content',
  '&.Mui-selected': {
    backgroundColor: `${theme.palette.text.primary} !important`,
    color: `${theme.palette.getContrastText(theme.palette.text.primary)} !important`,
  },
  '&:hover': {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.action.hover,
  },
}));
