import { useEffect, useState } from 'react';
import axios, { AxiosRequestConfig, AxiosError } from 'axios';
import { logger } from '@xspecs/logger';

type FetchOptions = AxiosRequestConfig | undefined;

type FetchResult<T> = {
  data: T | null;
  loading: boolean;
  error: AxiosError | null;
};

export function useFetch<T>(
  url: string,
  options?: FetchOptions,
  shouldFetch: boolean = true,
  defaultValue: T | null = null,
): FetchResult<T> {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<AxiosError | null>(null);

  useEffect(() => {
    if (!shouldFetch || !url) return;

    const controller = new AbortController();
    const signal = controller.signal;

    setLoading(true);
    setError(null);

    axios
      .get<T>(url, { ...options, signal })
      .then((response) => {
        setData(response.data);
        setLoading(false);
      })
      .catch((error: AxiosError) => {
        if (axios.isCancel(error)) {
          logger.log('Fetch aborted');
        } else {
          setError(error);
          setLoading(false);
        }
      });

    return () => {
      controller.abort();
    };
  }, [url, options, shouldFetch]);

  return { data: data ?? defaultValue, loading, error };
}
