import { useCallback, useMemo } from 'react';
import { useAuth } from '../auth';
import { Analytics, AppTypeEvent } from '@xspecs/single-source-model';
import { useActiveWorkspace } from './use-active-workspace';
import { useActiveOrganization } from './use-active-organization';

export { AppTypeEvent, NodeTypeEvent } from '@xspecs/single-source-model';

export const useTrackEvents = () => {
  const { organization } = useActiveOrganization();
  const { workspace } = useActiveWorkspace();

  const analytics = useMemo(() => {
    return Analytics.setInstance(window, organization?.name, workspace?.name);
  }, [organization, workspace]);

  const trackPageView = useCallback(
    (path: string, title: string, pageType: string) => analytics?.trackPageView(path, title, pageType),
    [analytics],
  );

  const trackEvent = useCallback((event: AppTypeEvent, data: any) => analytics?.trackEvent(event, data), [analytics]);

  return {
    trackEvent,
    trackPageView,
    useAuth,
  } as const;
};
