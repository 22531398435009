import { sid } from '@xspecs/short-id';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { AddEntityCommand, AssetBase, AttachmentNodeData, AttachmentType } from '@xspecs/single-source-model';
import { LinkAssetToAttachmentCommand } from 'domain/single-source-model/src/commands/attachments/LinkAssetToAttachmentCommand';
import { AssetAssignerAutoComplete } from '../../../../auto-complete/asset-assigner-auto-complete';
import { useApplication } from '../../../../../wrappers/application-context/application-context';
import { useReactiveState } from '../../../../../hooks/use-reactive-state';
import { AssetAssignerAutoCompleteOption } from '../../../../auto-complete/asset-assigner-auto-complete-item';

type AssetAssignerProps = {
  attachmentId: string;
  data: AttachmentNodeData;
};

export const AssetAssigner = (props: AssetAssignerProps) => {
  const { attachmentId, data } = props;

  const { formatMessage: f } = useIntl();
  const { application } = useApplication();

  const [value, setValue] = useState(data.asset?.id ?? null);
  const options = useReactiveState<AssetBase[]>(data.assetListDataSource)?.map((asset) => ({
    ...asset,
    type: asset.type,
  }));

  const createNew = useCallback(
    (name: string) => {
      const assetId = sid();
      application?.model.messageBus.send(AddEntityCommand, {
        id: assetId,
        name: name,
        type: data.subType as any,
        position: { x: 0, y: 0 },
      });
      application?.model.messageBus.send(LinkAssetToAttachmentCommand, { attachmentId, assetId });
      setValue(assetId);
    },
    [attachmentId, data.subType, application?.model.messageBus],
  );

  const onChange = useCallback(
    (option: AssetAssignerAutoCompleteOption | null | string) => {
      if (typeof option === 'string') {
        createNew(option);
      }
      if (typeof option === 'object') {
        application?.model.messageBus.send(LinkAssetToAttachmentCommand, {
          attachmentId,
          assetId: options?.find((ops) => ops.id === option?.id)?.id,
        });
      }
    },
    [application?.model.messageBus, attachmentId, createNew, options],
  );

  const items = useMemo(() => options?.map((option) => ({ id: option.id, name: option.name })) ?? [], [options]);

  useEffect(() => {
    setValue(data.asset?.id ?? null);
  }, [data.asset, data.asset?.id, data.asset?.name, data.subType, application?.model.entities]);

  return (
    <AssetAssignerAutoComplete
      options={items ?? []}
      value={value ?? ''}
      disableCreate={data.subType === AttachmentType.Upload}
      onChange={onChange}
      onCreateNew={createNew}
      notFoundText={f({ id: 'asset-not-found' })}
    />
  );
};
