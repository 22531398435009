import { Box, Typography } from '@mui/material';
import { AssetScope } from '@xspecs/single-source-model';
import { useIntl } from 'react-intl';
import { useCallback } from 'react';
import { useApplication } from '../../../../wrappers/application-context/application-context';
import { AssetScopeMenu } from './asset-scope-menu';

type AssetsScopeProps = { scope: AssetScope };

export const SwitchAssetScope = (props: AssetsScopeProps) => {
  const { scope } = props;

  const { formatMessage: f } = useIntl();
  const { application } = useApplication();

  const onScopeChange = useCallback(
    (value: string) => {
      application?.model.sidebarInteractor.setAssetScope(value as AssetScope);
    },
    [application?.model.sidebarInteractor],
  );

  return (
    <Box display="flex" gap={1} py={1} mb={1} justifyContent="flex-start" alignItems="center">
      <Typography variant="inputLabel" color="text.secondary" width="auto">
        {f({ id: 'showing' })}
      </Typography>
      <Typography variant="inputLabel" color="text.secondary" width="auto">
        {scope}
      </Typography>
      <AssetScopeMenu onChange={onScopeChange} value={scope} />
    </Box>
  );
};
