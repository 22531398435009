import { Tab as MuiTab, TabProps as MuiTabProps } from '@mui/material';
import { TabDetailsElement } from '@xspecs/single-source-model';

export const Tab = (props: TabDetailsElement & MuiTabProps) => {
  const { value } = props;

  if (typeof value !== 'number') {
    throw new Error('Tab was rendered without context of a Tabs component');
  }

  return <MuiTab {...props} />;
};
