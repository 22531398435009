import { useCallback } from 'react';
import { writeText } from 'clipboard-polyfill';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { useSnackStack } from '../../../../wrappers/snack-stack-context';
import { showOrganizationSettingsVar } from '../../../../state/state';
import { getRoutePathFromParams, RoutePaths } from '../../../../config/route-paths/route-paths';
import { kebabCase } from 'lodash';

export const useCopyEntityLink = () => {
  const { addToast } = useSnackStack();
  const { formatMessage: f } = useIntl();
  const { organizationName, workspaceName } = useParams();

  const openOrganizationSettings = useCallback(() => {
    showOrganizationSettingsVar(true);
  }, []);

  const getConstructUrl = useCallback(
    (id: string, type: string, name: string) => {
      if (!organizationName || !workspaceName) return;
      const path = getRoutePathFromParams(RoutePaths.Entity, {
        organizationName: organizationName,
        workspaceName: workspaceName,
        entityId: id,
      });
      const url = window.location.origin + path;
      const formattedName = kebabCase(name);
      return name && type ? `${url}-${type}-${formattedName}` : url;
    },
    [organizationName, workspaceName],
  );

  const onCopyEntityLink = useCallback(
    async (id: string, type: string, name: string) => {
      if (!id) {
        addToast({ message: f({ id: 'copy-link-error' }), severity: 'error' });
        return;
      }

      const url = getConstructUrl(id, type, name);
      if (!url) return;

      try {
        await writeText(url);
        const settingsLink = (
          <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={openOrganizationSettings}>
            {f({ id: 'copy-link-settings' })}
          </span>
        );
        addToast({
          message: f({ id: 'copy-link-success' }),
          severity: 'success',
          children: settingsLink,
        });
      } catch (error) {
        addToast({ message: f({ id: 'copy-link-error' }), severity: 'error' });
      }
    },
    [addToast, getConstructUrl, f, openOrganizationSettings],
  );

  return {
    onCopyEntityLink,
  } as const;
};
