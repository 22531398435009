import { CommandBase, IParams } from '../framework/CommandBase';
import { CommandError } from '../../ErrorStore';
import { Query } from '../../entities/assets/Query';
import { EntitiesAddedEvent } from './AddEntityCommand';

interface UpdateQueryUploadParams extends IParams {
  id: string;
  queryText?: string;
  viewport?: {
    minX: number;
    minY: number;
    maxX: number;
    maxY: number;
  };
}

export class UpdateQueryTextCommand extends CommandBase<UpdateQueryUploadParams> {
  execute(params: UpdateQueryUploadParams): EntitiesAddedEvent | CommandError | undefined {
    const queryEntity = this.model.entityRepository.get<Query>(params.id)!;
    if (!(queryEntity instanceof Query)) return CommandError.of(new Error('Entity is not a Query'), 'error');
    queryEntity.queryText = params.queryText!;
    const gqlEntityChangeset = queryEntity.syncQueryToGqlEntities(
      params.queryText ?? '',
      params.viewport ?? {
        minX: 0,
        minY: 0,
        maxX: 1000,
        maxY: 1000,
      },
    );
    gqlEntityChangeset.deleted.forEach((entity) => {
      this.model.entityRepository.delete(entity.id);
    });
    gqlEntityChangeset.added.forEach((entity) => {
      this.model.entityRepository.add(entity);
    });
    gqlEntityChangeset.updated.forEach((entity) => {
      this.model.entityRepository.update(entity.entity);
    });
    this.model.entityRepository.update(queryEntity);
  }
}
