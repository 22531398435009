import { Box, TextField } from '@mui/material';
import { TextboxDetailsElement } from '@xspecs/single-source-model';
import { useId } from 'react';

export const Textbox = (props: TextboxDetailsElement) => {
  const { label, value } = props;

  const id = useId();

  return (
    <Box key={id}>
      <TextField label={label} margin="normal" size="small" variant="outlined" fullWidth value={value} />
    </Box>
  );
};
