import { EntityBase } from '../EntityBase';
import { z } from 'zod';
import { Z_INDEXES } from '../../ZIndexes';
import { AssetBase } from './AssetBase';
import { Preview } from './Preview';

export const ATTACHMENT_DIMENSIONS = {
  width: 128,
  height: 40,
} as const;

export const attachmentBaseSchema = EntityBase.abstractBaseSchema.extend({
  subType: z.string(),
  width: z.number().default(ATTACHMENT_DIMENSIONS.width),
  height: z.number().default(ATTACHMENT_DIMENSIONS.height),
  zIndex: z.number().default(Z_INDEXES.Attachment),
  asset: AssetBase.baseSchema.optional(),
  parent: z.lazy(() => EntityBase.abstractBaseSchema).optional(),
  position: z
    .object({
      x: z.number(),
      y: z.number(),
    })
    .optional(),
  preview: z.lazy(() => Preview.baseSchema).optional().describe(`
      * the preview of the upload
    `),
});
