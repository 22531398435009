import { useMutation } from '@apollo/client';
import { Box, Button, TextField, Typography, useTheme } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ChangeEvent, FormEventHandler, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { getRoutePathFromParams, RoutePaths } from '../../config/route-paths/route-paths';
import { ADD_WORKSPACE_MUTATION } from '../../graphql/mutations';
import { Icon } from '../../icons/icon';
import { sid } from '@xspecs/short-id';
import { AppTypeEvent, useTrackEvents } from '../../hooks/use-track-events';
import { useActiveOrganization } from '../../hooks/use-active-organization';

export const CreateWorkspaceContent = () => {
  const [workspaceName, setWorkspaceName] = useState('');
  const [workspaceCreationError, setWorkspaceCreationError] = useState('');

  const { organization } = useActiveOrganization();

  const theme = useTheme();

  const { trackEvent } = useTrackEvents();

  const { formatMessage: f } = useIntl();
  const navigate = useNavigate();

  const [addWorkspace, { client, loading }] = useMutation(ADD_WORKSPACE_MUTATION);

  const onWorkspaceNameChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setWorkspaceName(event.target.value);
    setWorkspaceCreationError('');
  }, []);

  const onCreateWorkspace = useCallback<FormEventHandler<HTMLFormElement>>(
    async (e) => {
      if (!organization) return;
      e.preventDefault();
      const workspaceId = sid();
      const response = await addWorkspace({
        variables: { args: { organizationId: organization?.id, workspaceId, workspaceName } },
      });
      const responseError = response.data?.addWorkspace?.error;
      if (responseError) {
        setWorkspaceCreationError(responseError);
      } else {
        client.cache.evict({ id: 'ROOT_QUERY', fieldName: 'organizations' });
        trackEvent(AppTypeEvent.WorkspaceCreated, { workspaceId, workspaceName, organizationId: organization?.id });
        navigate(
          getRoutePathFromParams(RoutePaths.Workspace, {
            organizationName: organization?.name,
            workspaceName,
            fileId: '',
          }),
        );
      }
    },
    [organization, addWorkspace, workspaceName, client.cache, trackEvent, navigate],
  );

  const onCancelClick = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <Box
      sx={{
        backgroundColor: 'background.paper',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        mt: 4.375,
        pt: { md: 6.5 },
        pb: { md: 7.75 },
        px: { xs: 2, md: 4.25 },
        mx: { md: 'auto' },
        width: { md: '415px' },
        boxShadow: {
          md: ' 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)',
        },
        position: { md: 'absolute' },
        zIndex: { md: 1000 },
        top: { md: '50%' },
        left: { md: '50%' },
        transform: { md: 'translate(-50%, -50%)' },
        borderRadius: { md: '4px' },
      }}
    >
      <Icon
        data-testid="NarrativeLogo"
        name="narrative-filled"
        height={48}
        width={46}
        color={theme.palette.primary.main}
      />
      <Typography variant="h5" sx={{ width: '289px', textAlign: 'center', mt: 4.375 }}>
        {f({ id: 'create-workspace-for-organization' }, { name: organization?.name })}
      </Typography>
      <form onSubmit={onCreateWorkspace}>
        <TextField
          autoFocus
          fullWidth
          sx={{ mt: 4.375, mb: 2.375, '.MuiFormHelperText-root': { mx: 0 } }}
          placeholder={f({ id: 'workspace-name' })}
          onChange={onWorkspaceNameChange}
          error={Boolean(workspaceCreationError)}
          helperText={workspaceCreationError ? f({ id: workspaceCreationError }) : ''}
        />
        <LoadingButton
          type="submit"
          variant="contained"
          fullWidth
          sx={{ mb: 2.375 }}
          disabled={!workspaceName}
          loading={loading}
        >
          {f({ id: 'create-workspace' })}
        </LoadingButton>
        <Button variant="outlined" fullWidth onClick={onCancelClick}>
          {f({ id: 'cancel' })}
        </Button>
      </form>
    </Box>
  );
};
