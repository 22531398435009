import { CommandBase, IParams } from '../framework/CommandBase';
import { Position } from '../../types';
import { EventBase } from '../framework/EventBase';
import { EntityBase } from '../../entities/EntityBase';
import { CommandError } from '../../ErrorStore';
import { EntityType } from '../../entities/EntityType';
import { logger } from '@xspecs/logger';

interface MoveEntitiesParams extends IParams {
  entityIds: string[];
  skipIngestIds?: string[];
  dxDy: Position;
  isModPressed?: boolean;
  cursorPosition?: Position;
}

export class EntitiesMovedEvent extends EventBase {
  static eventType = 'EntitiesMovedEvent';

  constructor(public readonly params: MoveEntitiesParams, public readonly source = MoveEntitiesCommand) {
    super();
  }
}

export class MoveEntitiesCommand extends CommandBase<MoveEntitiesParams> {
  execute(params: MoveEntitiesParams): EntitiesMovedEvent | CommandError | undefined {
    if (params.entityIds.length === 0) {
      logger.error('MoveEntitiesCommand was called with no entityIds');
      return;
    }
    const firstEntity = this.model.entityRepository.get(params.entityIds[0]);
    let allEntitiesToMove: Set<EntityBase>;
    if (
      params.isModPressed &&
      params.entityIds.length === 1 &&
      firstEntity &&
      [EntityType.Narrative, EntityType.Action].includes(firstEntity.type as EntityType)
    ) {
      allEntitiesToMove = new Set([firstEntity]);
    } else {
      allEntitiesToMove = this.model.entityRepository.getAllUniqueChildren(params.entityIds);
    }
    const skipIngest = this.findEntitiesWhoseParentAreSelected(allEntitiesToMove);
    allEntitiesToMove.forEach((entity) => this.moveEntity(entity, params));
    return new EntitiesMovedEvent({
      entityIds: params.entityIds,
      skipIngestIds: skipIngest,
      dxDy: params.dxDy,
      cursorPosition: params.cursorPosition,
    });
  }

  private findEntitiesWhoseParentAreSelected(allEntitiesToMove: Set<EntityBase>): string[] {
    const entities: string[] = [];
    allEntitiesToMove.forEach((entity) => {
      if (entity.parent && allEntitiesToMove.has(entity.parent)) {
        entities.push(entity.id);
      }
    });
    return entities;
  }

  private moveEntity(entity: EntityBase, params: MoveEntitiesParams) {
    entity.position = {
      x: entity.position.x + params.dxDy.x,
      y: entity.position.y + params.dxDy.y,
    };
    this.model.entityRepository.update(entity);
  }
}
