import { default as AutosizeInput } from 'react-input-autosize';
import { useIntl } from 'react-intl';
import { EdgeLabelRenderer } from '@xyflow/react';
import { ChangeEventHandler, CSSProperties, useCallback, useMemo } from 'react';
import { Z_INDEXES } from '@xspecs/single-source-model';

type EdgeLabelProps = {
  value: string;
  onChange: (value: string) => void;
  labelX: number;
  labelY: number;
};

export const EdgeLabel = (props: EdgeLabelProps) => {
  const { value, onChange: onChangeProp, labelX, labelY } = props;

  const { formatMessage: f } = useIntl();

  const inputStyle = useMemo<CSSProperties>(
    () =>
      ({
        minWidth: 20,
        maxWidth: 200,
        outline: 'none',
        fontWeight: 500,
        padding: '0px 10px',
      } satisfies CSSProperties),
    [],
  );

  const onChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      onChangeProp(event.target.value);
    },
    [onChangeProp],
  );

  const containerStyle = useMemo<CSSProperties>(
    () =>
      ({
        position: 'absolute',
        top: labelY,
        left: labelX,
        transform: 'translate(-50%, -50%)',
        background: 'white',
        borderRadius: 5,
        fontSize: 12,
        fontWeight: 700,
        pointerEvents: 'all',
        zIndex: Z_INDEXES.Edge + 2000,
      } satisfies CSSProperties),
    [labelX, labelY],
  );

  return (
    <EdgeLabelRenderer>
      <div style={containerStyle} className="nodrag nopan">
        <AutosizeInput
          autoFocus
          value={value}
          placeholder={f({ id: 'untitled' })}
          inputStyle={inputStyle}
          onChange={onChange}
        />
      </div>
    </EdgeLabelRenderer>
  );
};
