import { FormControlLabel, Switch as MuiSwitch } from '@mui/material';
import { FC, SyntheticEvent, useCallback } from 'react';
import { ToolbarElementProps, SwitchToolbarElement } from '@xspecs/single-source-model';
import { useCommandDispatch } from '../../../wrappers/application-context/application-context';

export const Switch: FC<ToolbarElementProps<SwitchToolbarElement>> = (props) => {
  const { element } = props;

  const dispatch = useCommandDispatch();

  const onChange = useCallback(
    (_: SyntheticEvent, checked: boolean) => {
      dispatch(element.onChange.command, {
        ...element.onChange.params,
        value: checked,
      });
    },
    [dispatch, element.onChange.command, element.onChange.params],
  );

  return (
    <FormControlLabel
      control={<MuiSwitch onChange={onChange} checked={element.value} />}
      label={element.label}
      sx={sx}
    />
  );
};

const sx = {
  margin: 'none',
  textWrapMode: 'nowrap',
};
