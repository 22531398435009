import { Settings } from '@mui/icons-material';
import { Menu, MenuItem, PaperProps, Stack, SxProps, Theme, Typography } from '@mui/material';
import { MouseEvent, useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { getRoutePathFromParams, RoutePaths } from '../../../config/route-paths/route-paths';
import { NavBarIconButton } from '../nav-bar-icon-button';
import { showInviteUsersVar, showOrganizationSettingsVar, showWorkspaceSettingsVar } from '../../../state/state';
import { useSingleSourceStore } from '../../../store/single-source-store/single-source-store';
import { useActiveWorkspace } from '../../../hooks/use-active-workspace';
import { useActiveOrganization } from '../../../hooks/use-active-organization';
import { LAST_SELECTED_ORGANIZATION_ID_KEY } from '../../../lib/utils';

const overflowSx: SxProps<Theme> = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

export const SettingsMenu = () => {
  const [profileAnchorEl, setProfileAnchorEl] = useState<HTMLElement | null>(null);

  const { organization } = useActiveOrganization();
  const { workspace } = useActiveWorkspace();

  const navigate = useNavigate();

  const setShowManageLabelsModal = useSingleSourceStore.use.setShowManageLabelsModal();
  const setShowRestorePreviousVersionModal = useSingleSourceStore.use.setShowRestorePreviousVersionModal();
  const setShowWorkspaceResetModal = useSingleSourceStore.use.setShowWorkspaceResetModal();

  const { setOrganization } = useActiveOrganization();
  const { setWorkspace } = useActiveWorkspace();

  const { formatMessage: f } = useIntl();

  const handleClick = useCallback((event: MouseEvent<HTMLElement>) => {
    setProfileAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setProfileAnchorEl(null);
  }, []);

  const openWorkspaceSettings = useCallback(() => {
    showWorkspaceSettingsVar(true);
  }, []);

  const openOrganizationSettings = useCallback(() => {
    showOrganizationSettingsVar(true);
  }, []);

  const navigateToOrganizationSwitch = useCallback(() => {
    setOrganization(null);
    setWorkspace(null);
    navigate(RoutePaths.ChooseOrganization);
    localStorage.removeItem(LAST_SELECTED_ORGANIZATION_ID_KEY);
  }, [navigate, setOrganization, setWorkspace]);

  const navigateToSettings = useCallback(() => {
    if (!organization) return;
    navigate(
      getRoutePathFromParams(RoutePaths.Settings, {
        organizationName: organization?.name,
      }) + '#plans',
      {
        state: {
          preventNavigation: true,
        },
      },
    );
  }, [navigate, organization]);

  const openInviteUsers = useCallback(() => {
    showInviteUsersVar(true);
  }, []);

  const onManageLabels = useCallback(() => {
    setShowManageLabelsModal(true);
  }, [setShowManageLabelsModal]);

  const onRestorePreviousVersion = useCallback(() => {
    setShowRestorePreviousVersionModal(true);
  }, [setShowRestorePreviousVersionModal]);

  const openWorkspaceResetModal = useCallback(() => {
    setShowWorkspaceResetModal(true);
  }, [setShowWorkspaceResetModal]);

  const showResetOption = useMemo(() => {
    return organization?.name.startsWith('summit');
  }, [organization?.name]);

  const navigateToOrganizationApps = useCallback(() => {
    if (!organization) return;
    navigate(
      getRoutePathFromParams(RoutePaths.Settings, {
        organizationName: organization?.name,
      }) + '#apps',
      {
        state: {
          preventNavigation: true,
        },
      },
    );
  }, [navigate, organization]);

  return (
    <>
      <NavBarIconButton id="nav-settings-button" icon={<Settings color="inherit" />} onClick={handleClick} />
      <Menu
        data-testid="nav-settings-menu"
        anchorEl={profileAnchorEl}
        open={Boolean(profileAnchorEl)}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={MenuPaperProps}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        TransitionProps={{
          timeout: { enter: 200, exit: 200 },
          easing: { enter: 'ease-out', exit: 'ease-out' },
        }}
      >
        <Typography variant="overline" sx={{ ml: 2 }}>
          {f({ id: 'workspace' })}
        </Typography>
        <MenuItem data-testid="settings-menu-workspace-settings" onClick={openWorkspaceSettings}>
          <Typography sx={overflowSx} variant="body1">
            {f({ id: 'entity-settings' }, { name: workspace?.name })}
          </Typography>
        </MenuItem>
        {showResetOption ? (
          <MenuItem data-testid="settings-menu-workspace-reset" onClick={openWorkspaceResetModal}>
            <Typography sx={overflowSx} variant="body1">
              {f({ id: 'reset-state' })}
            </Typography>
          </MenuItem>
        ) : null}
        <Typography variant="overline" sx={{ ml: 2, mt: 1 }}>
          {f({ id: 'organization' })}
        </Typography>
        <MenuItem onClick={navigateToSettings}>
          <Stack gap={1} direction="row">
            {/*<SettingsRounded color="action" />*/}
            <Typography sx={overflowSx} variant="body1">
              {f({ id: 'plans-and-billing' }, { name: workspace?.name })}
            </Typography>
          </Stack>
        </MenuItem>
        <MenuItem data-testid="settings-menu-organization-settings" onClick={openOrganizationSettings}>
          <Typography sx={overflowSx} variant="body1">
            {f({ id: 'entity-settings' }, { name: organization?.name })}
          </Typography>
        </MenuItem>
        <MenuItem data-testid="settings-menu-switch-organization" onClick={navigateToOrganizationSwitch}>
          <Typography variant="body1">{f({ id: 'switch-organization' })}</Typography>
        </MenuItem>
        <MenuItem data-testid="settings-menu-organization-apps" onClick={navigateToOrganizationApps}>
          <Typography variant="body1">{f({ id: 'apps' })}</Typography>
        </MenuItem>
        <Typography variant="overline" sx={{ ml: 2, mt: 1 }}>
          {f({ id: 'others' })}
        </Typography>
        <MenuItem data-testid="settings-menu-invite-users" onClick={openInviteUsers} sx={{ mt: 1 }}>
          <Typography variant="body1">{f({ id: 'invite-users' })}</Typography>
        </MenuItem>
        <MenuItem data-testid="settings-menu-manage-labels" onClick={onManageLabels} sx={{ mt: 1 }}>
          <Typography variant="body1">{f({ id: 'manage-labels' })}</Typography>
        </MenuItem>
        {/*<MenuItem data-testid="settings-menu-restore-previous-version" onClick={onRestorePreviousVersion} sx={{ mt: 1 }}>*/}
        {/*  <Typography variant="body1">{f({ id: 'restore-previous-version' })}</Typography>*/}
        {/*</MenuItem>*/}
      </Menu>
    </>
  );
};

const MenuPaperProps: Partial<PaperProps> = {
  elevation: 0,
  sx: {
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    mt: 1.5,
    maxWidth: '220px',
    '& .MuiAvatar-root': {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 1,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: 'background.paper',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0,
    },
  },
};
