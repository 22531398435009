import { ChangeEvent, FC, useCallback, useId, useState } from 'react';
import { Box, Tabs as MuiTabs, Typography } from '@mui/material';
import { TabsDetailsElement } from '@xspecs/single-source-model';
import { DetailElementComponent } from '../render-element';

export const Tabs: FC<TabsDetailsElement> = ({ elements }) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const id = useId();

  const handleTabChange = useCallback((_event: ChangeEvent, newValue: number) => {
    setSelectedTab(newValue);
  }, []);

  return (
    <Box key={id}>
      <MuiTabs value={selectedTab} onChange={handleTabChange}>
        {elements.map((tab, index) => (
          <DetailElementComponent key={`Tabs${id}Tab${index}`} {...tab} />
        ))}
      </MuiTabs>
      <Box mt={1}>
        {elements[selectedTab].elements.length > 0 ? (
          elements[selectedTab].elements.map((element, index) => (
            <DetailElementComponent key={`Tabs${id}Tab${selectedTab}DetailElementComponent${index}`} {...element} />
          ))
        ) : (
          <Typography variant="body2" color="textSecondary">
            No content available
          </Typography>
        )}
      </Box>
    </Box>
  );
};
