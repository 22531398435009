import { useIntl } from 'react-intl';
import { Fragment } from 'react';
import { Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { FiltersList, FiltersListProps } from '../../../filters-list/filters-list';
import { FilterByItem } from '@xspecs/single-source-model';
import { View } from '../filters-button-menu';

export type FiltersButtonMenuListProps = {
  filterByAssetsList: FilterByItem[];
  onViewSelect: (view: View) => void;
  filtersListProps?: FiltersListProps;
};

export const FiltersButtonMenuList = (props: FiltersButtonMenuListProps) => {
  const { onViewSelect, filtersListProps, filterByAssetsList } = props;

  const { formatMessage: f } = useIntl();

  return (
    <List>
      {filterByAssetsList.map((filterByItem) => (
        <ListItem disablePadding key={filterByItem.type}>
          <ListItemButton sx={{ gap: 1, py: 0.5 }} onClick={() => onViewSelect(filterByItem.type)}>
            <ListItemIcon sx={{ minWidth: 'fit-content' }}>
              <img width={24} height={24} src={filterByItem.icon} />
            </ListItemIcon>
            <ListItemText primary={filterByItem.type} />
          </ListItemButton>
        </ListItem>
      ))}
      {filtersListProps ? (
        <Fragment>
          <Divider sx={{ mb: 1.5 }} />
          {filtersListProps.savedFilters.length > 0 ? (
            <FiltersList {...filtersListProps} />
          ) : (
            <Typography variant="caption" textAlign="center">
              {f({ id: 'no-saved-filters' })}
            </Typography>
          )}
        </Fragment>
      ) : null}
    </List>
  );
};
