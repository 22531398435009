import { ConstructShape } from '../../entities/constructs/ConstructBase';

export type AssetExplorerState = {
  results: AssetExplorerResult;
  searchQuery: string;
  sortQuery: AssetSort;
  scope: AssetScope;
};

export enum AssetScope {
  Current = 'Current Workspace',
  All = 'All Workspaces',
}

export enum AssetSort {
  Newest = 'newest',
  Ascending = 'ascending',
  Descending = 'descending',
}

export type AssetExplorerResult = AssetExplorerItem[];

export type AssetExplorerUsageItem = {
  id: string;
  type: string;
  name: string;
  shape: ConstructShape;
  scopes: string[];
};

export type AssetExplorerItem = {
  id: string;
  name: string;
  type: string;
  usages: AssetExplorerUsageItem[];
  url?: string;
  icon: string;
  scopes: string[];
};
