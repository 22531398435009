import { ListItem, Stack } from '@mui/material';
import { CommentTextArea } from '../../../../comment-text-area/comment-text-area';
import { CommentUserInfo } from '../../../../../../../../comment-user-info/comment-user-info';
import { Value } from '@udecode/plate-common';
import { CommentItemMoreMenu } from '../../../../../../../../comment-item-more-menu/comment-item-more-menu';
import { useCallback, useState } from 'react';
import { useSingleSourceModel } from '../../../../../../../../../hooks/use-single-source-model';
import { useMutation } from '@apollo/client';
import { MENTION_USERS_MUTATION } from '../../../../../../../../../graphql/mutations';
import { useAuth } from '../../../../../../../../../auth';
import { AppTypeEvent, useTrackEvents } from '../../../../../../../../../hooks/use-track-events';
import { useActiveWorkspace } from '../../../../../../../../../hooks/use-active-workspace';
import { Comment } from '@xspecs/single-source-model';

type CommentsListItemProps = {
  data: Comment;
};

export const CommentsListItem = (props: CommentsListItemProps) => {
  const { data } = props;

  const { user } = useAuth();
  const model = useSingleSourceModel();
  const { trackEvent } = useTrackEvents();
  const { workspace } = useActiveWorkspace();

  const [readOnly, setReadOnly] = useState(true);
  const isMyComment = data.createdBy === user.sub;

  const [mentionUsers] = useMutation(MENTION_USERS_MUTATION);

  const onEdit = useCallback(() => {
    setReadOnly(false);
  }, []);

  const onDelete = useCallback(() => {
    model?.entities.delete(data.id);
    trackEvent(AppTypeEvent.CommentDeleted, { threadId: data.threadId, commentId: data.id });
  }, [model?.entities, trackEvent, data.id, data.threadId]);

  const onUpdateComment = useCallback(
    async (value: Value, id: string, mentionedUserIds: string[]) => {
      if (!workspace) return;
      model?.entities.updateComment(id, JSON.stringify(value));

      trackEvent(AppTypeEvent.CommentUpdated, { threadId: data.threadId, commentId: id });

      setReadOnly(true);
      if (mentionedUserIds.length > 0)
        await mentionUsers({
          variables: {
            args: {
              mentionedUserIds: mentionedUserIds,
              workspaceId: workspace.id,
              commentId: id,
              threadId: data.threadId,
            },
          },
        });

      mentionedUserIds.forEach((userId) => {
        trackEvent(AppTypeEvent.CommentUserMentioned, { threadId: data.threadId, commentId: id, userId: userId });
      });
    },
    [workspace, model?.entities, trackEvent, data.threadId, mentionUsers],
  );

  return (
    <ListItem itemID={data.id} sx={rootSx} divider disableGutters>
      <Stack width="100%" direction="row" justifyContent="space-between" alignItems="flex-start">
        <CommentUserInfo userId={data.createdBy} createdAt={data.createdAt} updatedAt={data.updatedAt} />
        {isMyComment ? <CommentItemMoreMenu onEdit={onEdit} onDelete={onDelete} /> : null}
      </Stack>
      <CommentTextArea
        key={JSON.stringify({ temp: data.value, readOnly })}
        id={data.id}
        disablePlaceholder
        readOnly={readOnly}
        initialValue={typeof data.value === 'string' ? JSON.parse(data.value) : data.value}
        onSubmitComment={onUpdateComment}
      />
    </ListItem>
  );
};

const rootSx = {
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: 1.5,
};
