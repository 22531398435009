import { FC, ReactNode, useCallback, useEffect, useMemo } from 'react';
import { useSingleSourceStore } from '../../store/single-source-store/single-source-store';
import { ErrorBoundary } from '../../components/error-boundary/error-boundary';
import { FileStatus } from '../../components/file-status/file-status';
import { useSearchParams } from 'react-router-dom';
import { PreviewModeBanner } from '../../preview-mode-banner/preview-mode-banner';
import { useSnackStack } from '../snack-stack-context';
import { useSingleSourceModel } from '../../hooks/use-single-source-model';
import { CommandError } from '@xspecs/single-source-model';
import { UrlPreviewModal } from '../../components/single-source-model/url-preview-modal/url-preview-modal';
import { useActiveOrganization } from '../../hooks/use-active-organization';
import { ModelStatusWrapper } from './model-status-wrapper';

export const SingleSourceModelWrapper: FC<{ children: ReactNode }> = ({ children }) => {
  const { organization } = useActiveOrganization();
  const singleSourceModelId = organization?.singleSourceModel?.id;
  const singleSourceModel = useSingleSourceStore.use.singleSourceModel();

  const errors = useSingleSourceStore.use.errors();
  const { addToast, removeToast, toastsPack } = useSnackStack();
  const model = useSingleSourceModel();
  const [searchParams] = useSearchParams();
  const version = useMemo(() => searchParams.get('version') ?? null, [searchParams]);

  const clearError = useCallback(
    (error: CommandError | CommandError[], key: string) => {
      removeToast(key);
      model?.interactor.clearError(error);
    },
    [model?.interactor, removeToast],
  );

  useEffect(() => {
    const uniqueNewErrors = errors.slice(-1).filter((err) => !toastsPack.some((toast) => toast.key === err.key));

    if (uniqueNewErrors.length === 0) return;

    uniqueNewErrors.forEach((error) => {
      addToast({
        key: error.key,
        title: error.error?.message || 'Unknown error occurred',
        severity: error.severity,
        onClose: () => clearError(errors, error.key),
      });
    });
  }, [addToast, clearError, errors, toastsPack]);

  if (!singleSourceModel || !singleSourceModelId) {
    return null;
  }

  return (
    <ModelStatusWrapper id={singleSourceModelId}>
      <ErrorBoundary>{children}</ErrorBoundary>
      <FileStatus fileId={singleSourceModelId} />
      {version ? <PreviewModeBanner version={version} /> : null}
      <UrlPreviewModal />
    </ModelStatusWrapper>
  );
};
