import { IconComponentProps } from '../icon';
import { FC } from 'react';

export const Confluence: FC<IconComponentProps> = () => {
  return (
    <>
      <path
        d="M2.27242 13.842C2.09633 14.1189 1.89507 14.4462 1.7441 14.6979C1.59317 14.9497 1.66865 15.2769 1.92022 15.428L5.3668 17.5425C5.61837 17.6936 5.94542 17.6181 6.09638 17.3663C6.22217 17.1398 6.39825 16.8377 6.59952 16.5104C7.95798 14.27 9.34167 14.5217 11.8071 15.7049L15.2285 17.316C15.5052 17.4419 15.8071 17.316 15.9329 17.0642L17.5682 13.3637C17.694 13.112 17.5682 12.7847 17.3166 12.6588C16.587 12.3315 15.153 11.6519 13.8952 11.0226C9.241 8.78215 5.29132 8.93318 2.27242 13.842Z"
        fill="url(#paint0_linear_1600_968)"
      />
      <path
        d="M17.7275 5.4448C17.9037 5.16788 18.1049 4.84064 18.2559 4.58888C18.4068 4.33715 18.3313 4.00987 18.0798 3.85884L14.6332 1.74424C14.3816 1.59321 14.0545 1.66872 13.9036 1.92045C13.7778 2.14703 13.6017 2.4491 13.4005 2.77637C12.042 5.01682 10.6583 4.76509 8.19285 3.58193L4.77145 1.97082C4.4947 1.84494 4.19283 1.97082 4.06705 2.22255L2.4318 5.92304C2.30602 6.1748 2.4318 6.50205 2.68337 6.62796C3.41295 6.95524 4.8469 7.63488 6.1048 8.26422C10.7589 10.4795 14.7086 10.3536 17.7275 5.4448Z"
        fill="url(#paint1_linear_1600_968)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1600_968"
          x1="17.4898"
          y1="18.6309"
          x2="7.07968"
          y2="12.6528"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0052CC" />
          <stop offset="0.9182" stopColor="#2380FB" />
          <stop offset="1" stopColor="#2684FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1600_968"
          x1="2.50724"
          y1="0.654284"
          x2="12.9175"
          y2="6.6324"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0052CC" />
          <stop offset="0.9182" stopColor="#2380FB" />
          <stop offset="1" stopColor="#2684FF" />
        </linearGradient>
      </defs>
    </>
  );
};
