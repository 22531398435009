import { Box, Menu, MenuItem, PaperProps, SxProps, Theme, Typography } from '@mui/material';
import { AccountCircleRounded, Logout } from '@mui/icons-material';
import { MouseEvent, useCallback, useState } from 'react';
import { RoutePaths } from '../../../config/route-paths/route-paths';
import { useAuth } from '../../../auth';
import { useIntl } from 'react-intl';

export type ProfileDetailsProps = {
  name: string;
  email: string;
  picture: string;
  collapsed: boolean;
};

export const ProfileDetails = (props: ProfileDetailsProps) => {
  const { name, email, picture, collapsed } = props;
  const [profileAnchorEl, setProfileAnchorEl] = useState<HTMLElement | null>(null);
  const { logout } = useAuth();

  const { formatMessage: f } = useIntl();

  const handleClick = useCallback((event: MouseEvent<HTMLElement>) => {
    setProfileAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setProfileAnchorEl(null);
  }, []);

  const handleLogout = useCallback(() => {
    logout({ logoutParams: { returnTo: window.location.origin + RoutePaths.Login } });
  }, [logout]);

  return (
    <>
      <Box sx={profileDetailsSx} onClick={handleClick} data-testid="profile-menu-button">
        {picture ? (
          <img alt="Profile Picture" src={picture} style={{ width: '40px', height: '40px', borderRadius: '50%' }} />
        ) : (
          <AccountCircleRounded color="inherit" />
        )}
        {!collapsed ? (
          <Box>
            <Typography variant="subtitle2" color="textPrimary">
              {name}
            </Typography>
            <Typography variant="caption" color="textSecondary">
              {email}
            </Typography>
          </Box>
        ) : null}
      </Box>
      <Menu
        data-testid="profile-menu"
        anchorEl={profileAnchorEl}
        open={Boolean(profileAnchorEl)}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={MenuPaperProps}
        transformOrigin={{ horizontal: 'left', vertical: 'center' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'center' }}
        TransitionProps={{
          timeout: { enter: 200, exit: 200 },
          easing: { enter: 'ease-out', exit: 'ease-out' },
        }}
      >
        <MenuItem data-testid="profile-menu-logout" onClick={handleLogout} sx={menuItemSx}>
          <Logout color="action" fontSize="small" />
          <Typography variant="body1">{f({ id: 'logout' })}</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

const profileDetailsSx: SxProps = {
  px: 1.5,
  py: 3,
  display: 'flex',
  gap: 1.5,
  borderTop: '1px solid',
  borderColor: 'divider',
  cursor: 'pointer',
};

const MenuPaperProps: Partial<PaperProps> = {
  elevation: 0,
  sx: {
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    maxWidth: '220px',
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: '50%',
      left: -4,
      width: 10,
      height: 10,
      backgroundColor: 'background.paper',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0,
    },
  },
};

const menuItemSx: SxProps<Theme> = { backgroundColor: 'transparent', minWidth: '200px', gap: 1 };
