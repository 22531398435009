import { useCallback } from 'react';
import { useSingleSourceModel } from '../../../../hooks/use-single-source-model';
import { RenameEntityCommand } from '@xspecs/single-source-model';

export const useUpdateEntity = (id: string) => {
  const model = useSingleSourceModel();

  const onRename = useCallback(
    (newName: string) => {
      model?.messageBus.send(RenameEntityCommand, { entityId: id, newName });
    },
    [id, model],
  );

  const onFontSizeChange = useCallback(
    (fontSize: number) => {
      model?.entities.setAttribute(id, 'fontSize', fontSize);
    },
    [id, model],
  );

  return {
    onRename,
    onFontSizeChange,
  } as const;
};
