import { CommandBase, IParams } from '../framework/CommandBase';
import { EventBase } from '../framework/EventBase';
import { CommandError } from '../../ErrorStore';

interface SetExplorerSearchParams extends IParams {
  search: string;
}

export class SetExplorerSearchEvent extends EventBase {
  static eventType = 'SetExplorerSearchEvent';

  constructor(public readonly params: SetExplorerSearchParams, public readonly source = SetExplorerSearchCommand) {
    super();
  }
}

export class SetExplorerSearchCommand extends CommandBase<SetExplorerSearchParams> {
  execute(params: SetExplorerSearchParams): SetExplorerSearchEvent | CommandError | undefined {
    const { search } = params;
    this.applySearch(search);
    return new SetExplorerSearchEvent(params);
  }

  private applySearch(search: string) {
    this.model.explorer.updateSearch(search);
  }
}
