import { graphql } from '../gql';

export const ADD_ORGANIZATION_MUTATION = graphql(`
  mutation addOrganization($args: AddOrganizationArgs!) {
    addOrganization(args: $args) {
      error
    }
  }
`);

export const ADD_WORKSPACE_MUTATION = graphql(`
  mutation addWorkspace($args: AddWorkspaceArgs!) {
    addWorkspace(args: $args) {
      error
    }
  }
`);

export const INVITE_USERS_MUTATION = graphql(`
  mutation inviteUsers($args: InviteUsersArgs!) {
    inviteUsers(args: $args) {
      error
    }
  }
`);

export const VERIFY_EMAIL_MUTATION = graphql(`
  mutation verifyEmail {
    verifyEmail {
      error
    }
  }
`);

export const ACCEPT_INVITATION_MUTATION = graphql(`
  mutation acceptInvite($args: AcceptInviteArgs!) {
    acceptInvite(args: $args) {
      error
    }
  }
`);

export const LINK_NEW_SPEC_TO_DOC_MUTATION = graphql(`
  mutation linkNewSpecToDoc($args: LinkNewSpecToDocArgs!) {
    linkNewSpecToDoc(args: $args) {
      error
    }
  }
`);

export const LINK_EXISTING_SPEC_TO_DOC_MUTATION = graphql(`
  mutation linkExistingSpecToDoc($args: LinkExistingSpecToDocArgs!) {
    linkExistingSpecToDoc(args: $args) {
      error
    }
  }
`);

export const UNLINK_SPEC_FROM_DOC_MUTATION = graphql(`
  mutation unlinkSpecFromDoc($args: UnlinkSpecFromDocArgs!) {
    unlinkSpecFromDoc(args: $args) {
      error
    }
  }
`);

export const DELETE_WORKSPACE_MUTATION = graphql(`
  mutation deleteWorkspace($args: DeleteWorkspaceArgs!) {
    deleteWorkspace(args: $args) {
      error
    }
  }
`);

export const RENAME_WORKSPACE_MUTATION = graphql(`
  mutation renameWorkspace($args: RenameWorkspaceArgs!) {
    renameWorkspace(args: $args) {
      error
    }
  }
`);

export const DELETE_ORGANIZATION_MUTATION = graphql(`
  mutation deleteOrganization($args: DeleteOrganizationArgs!) {
    deleteOrganization(args: $args) {
      error
    }
  }
`);

export const RENAME_ORGANIZATION_MUTATION = graphql(`
  mutation renameOrganization($args: RenameOrganizationArgs!) {
    renameOrganization(args: $args) {
      error
    }
  }
`);

export const UPDATE_USER_ROLE_MUTATION = graphql(`
  mutation updateUserRole($args: UpdateUserRoleArgs!) {
    updateUserRole(args: $args) {
      error
    }
  }
`);

export const REMOVE_USER_FROM_ORGANIZATION_MUTATION = graphql(`
  mutation removeUserFromOrganization($args: RemoveUserFromOrganizationArgs!) {
    removeUserFromOrganization(args: $args) {
      error
    }
  }
`);

export const UPDATE_ORGANIZATION_EMAIL_DOMAINS = graphql(`
  mutation updateOrganizationEmailDomains($args: UpdateOrganizationEmailDomainsArgs!) {
    updateOrganizationEmailDomains(args: $args) {
      error
    }
  }
`);

export const RENAME_FILE_MUTATION = graphql(`
  mutation renameFile($args: RenameFileArgs!) {
    renameFile(args: $args) {
      error
    }
  }
`);

export const MOVE_FILE_MUTATION = graphql(`
  mutation moveFile($args: MoveFileArgs!) {
    moveFile(args: $args) {
      error
    }
  }
`);

export const ADD_FILE_MUTATION = graphql(`
  mutation addFile($args: AddFileArgs!) {
    addFile(args: $args) {
      error
    }
  }
`);

export const DELETE_FILE_MUTATION = graphql(`
  mutation deleteFile($args: DeleteFileArgs!) {
    deleteFile(args: $args) {
      error
    }
  }
`);

export const MENTION_USERS_MUTATION = graphql(`
  mutation mentionUsers($args: MentionUsersArgs!) {
    mentionUsers(args: $args) {
      error
    }
  }
`);

export const RESOLVE_THREAD_MUTATION = graphql(`
  mutation resolveThread($args: ResolveThreadArgs!) {
    resolveThread(args: $args) {
      data
      error
    }
  }
`);

export const MAKE_SUGGESTION_MUTATION = graphql(`
  mutation makeSuggestion($args: SuggestionArgs!) {
    makeSuggestion(args: $args) {
      error
      data
    }
  }
`);

export const RESTORE_VERSION_MUTATION = graphql(`
  mutation restoreVersion($args: RestoreVersionArgs!) {
    restoreVersion(args: $args) {
      error
    }
  }
`);

export const UPDATE_CONTENT_SHARING_SCOPE_MUTATION = graphql(`
  mutation updateContentSharingScope($args: UpdateContentSharingScopeArgs!) {
    updateContentSharingScope(args: $args) {
      error
    }
  }
`);

export const UPLOAD_APP_MANIFEST_MUTATION = graphql(`
  mutation registerApp($args: RegisterAppArgs!) {
    registerApp(args: $args) {
      data
      error
    }
  }
`);

export const REMOVE_APP_MUTATION = graphql(`
  mutation removeApp($args: RemoveAppArgs!) {
    removeApp(args: $args) {
      data
      error
    }
  }
`);

export const UPDATE_APP_SETTINGS_MUTATION = graphql(`
  mutation updateAppSettings($args: UpdateAppSettingsArgs!) {
    updateAppSettings(args: $args) {
      data
      error
    }
  }
`);
