import { Textbox } from './elements/textbox';
import { DetailsElement, DetailsElementProps, DetailsElementType } from '@xspecs/single-source-model';
import { logger } from '@xspecs/logger';
import { Tabs } from './elements/tabs';
import { Tab } from './elements/tab';

const ComponentsMap: Record<DetailsElementType, React.FC<DetailsElementProps[DetailsElementType]>> = {
  [DetailsElementType.Textbox]: Textbox,
  [DetailsElementType.Tabs]: Tabs,
  [DetailsElementType.Tab]: Tab,
  [DetailsElementType.Select]: () => {
    throw new Error('Select component not implemented');
  },
};

export const DetailElementComponent = (element: DetailsElement) => {
  const Component = ComponentsMap[element.type];

  if (!Component) {
    logger.error(`Component not found for element type: ${element.type}`);
    return null;
  }
  return <Component {...element} />;
};
