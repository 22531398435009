import { CommandBase, IParams } from '../framework/CommandBase';
import { EventBase } from '../framework/EventBase';
import { CommandError } from '../../ErrorStore';

export type ToolbarStateKeys =
  | 'compactMode'
  | 'search'
  | 'scheme'
  | 'typeFilter'
  | 'selectedEntity'
  | 'reset'
  | 'close';

interface SetToolbarStateCommandParams extends IParams {
  key: ToolbarStateKeys;
  value?: boolean | string;
}

interface ToolbarStateSetEventEventParams extends IParams {
  key: ToolbarStateKeys;
  value?: boolean | string;
}

export class ToolbarStateChangedEvent extends EventBase {
  static eventType = 'ToolbarStateSetEvent';

  constructor(
    public readonly params: ToolbarStateSetEventEventParams,
    public readonly source = SetToolbarStateCommand,
  ) {
    super();
  }
}

export class SetToolbarStateCommand extends CommandBase<SetToolbarStateCommandParams> {
  execute(params: SetToolbarStateCommandParams): ToolbarStateChangedEvent | CommandError {
    const { key, value } = params;
    if (key === 'compactMode' && typeof value !== 'boolean') {
      return CommandError.of(new Error('Invalid value for CompactMode.'), 'error');
    }
    if (['searchString', 'scheme', 'typeFilter', 'selectedEntity'].includes(key) && typeof value !== 'string') {
      return CommandError.of(new Error(`Invalid value for ${key}. No value provided.`), 'error');
    }
    return new ToolbarStateChangedEvent({
      key: key,
      value: value,
    });
  }
}
