import { IconButton, IconButtonProps, Tooltip, TooltipProps } from '@mui/material';
import { useIntl } from 'react-intl';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

export type CopyLinkButtonProps = Omit<IconButtonProps, 'id' | 'type' | 'ref' | 'component'> &
  Pick<TooltipProps, 'placement'> & {
    onClick: () => void;
  };

export const DeleteAssetButton = (props: CopyLinkButtonProps) => {
  const { onClick, placement, ...rest } = props;

  const { formatMessage: f } = useIntl();

  return (
    <Tooltip title={f({ id: 'delete-asset' })} disableInteractive placement={placement ?? 'right'}>
      <IconButton size="small" sx={{ padding: 0.3 }} {...rest} onClick={onClick}>
        <DeleteOutlineOutlinedIcon height={16} width={16} />
      </IconButton>
    </Tooltip>
  );
};
