import { FC, useMemo } from 'react';
import { List as MuiList } from '@mui/material';
import { ToolbarElementProps, ToolbarListElement } from '@xspecs/single-source-model';
import { renderPaletteComponent } from './palette-renderer';

export const List: FC<ToolbarElementProps<ToolbarListElement>> = (props) => {
  const { element } = props;

  const rootSx = useMemo(
    () => ({
      display: 'flex',
      flexDirection: 'row',
      flexGrow: element.grow,
      alignItems: 'center',
      justifyItems: 'flex-start',
      overflowY: 'scroll',
    }),
    [element.grow],
  );

  return (
    <MuiList sx={rootSx}>
      {element.elements.map((element, index) =>
        renderPaletteComponent({
          element,
          key: `CanvasToolbarCompactRow${index}Element${index}`,
        }),
      )}
    </MuiList>
  );
};
