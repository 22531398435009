import { IconComponentProps } from '../icon';
import { FC } from 'react';

export const Linear: FC<IconComponentProps> = () => {
  return (
    <>
      <path
        d="M15.4334 16.3332C15.6 16.1999 15.7334 16.0666 15.8667 15.8999C19.1334 12.6332 19.1334 7.36657 15.8667 4.0999C12.6 0.833236 7.33335 0.833236 4.06669 4.0999C3.93335 4.26657 3.80002 4.3999 3.66669 4.56657L15.4334 16.3332Z"
        fill="#5E6AD2"
      />
      <path
        d="M14.4333 17.0667L2.93331 5.56665C2.69998 5.93332 2.49998 6.29998 2.33331 6.69998L13.3 17.6667C13.7 17.5 14.0666 17.3 14.4333 17.0667Z"
        fill="#5E6AD2"
      />
      <path
        d="M12.0333 18.1001L1.89999 7.9668C1.76666 8.4668 1.69999 8.9668 1.66666 9.4668L10.5333 18.3335C11.0333 18.3001 11.5333 18.2335 12.0333 18.1001Z"
        fill="#5E6AD2"
      />
      <path
        d="M8.7 18.2667L1.73334 11.3C2 13 2.76667 14.6 4.06667 15.9334C5.4 17.2 7.03334 18 8.7 18.2667Z"
        fill="#5E6AD2"
      />
    </>
  );
};
