import { ConstructBase } from './ConstructBase';
import { EntityType } from '../EntityType';
import { z } from 'zod';
import { ScriptBase } from '../scripts/ScriptBase';
import { NarrativeScript } from '../scripts/NarrativeScript';

export class Narrative extends ConstructBase {
  static schema = ConstructBase.baseSchema.extend({
    script: z.lazy(() => ScriptBase.schema),
  });

  public script: NarrativeScript;

  static parse(data: object, omitReferences = false): Narrative {
    // const validatedData = Narrative.schema.parse(data);
    // const narrative = ConstructBase.parseBase.call(Narrative, validatedData);
    // narrative.script = validatedData.script;
    // return narrative;
    if (omitReferences) {
      const validatedData = Narrative.schema.omit({ script: true }).parse(data);
      return ConstructBase.parseBase.call(Narrative, validatedData);
    }
    return super.parseBase<Narrative>(data, Narrative.schema);
  }

  isValid(): boolean {
    return Narrative.schema.safeParse(this).success;
  }

  get type(): EntityType {
    return EntityType.Narrative;
  }

  removeSubscript() {
    this.script = null!;
  }

  static references = ['script'];

  serialize(reference: boolean = false): unknown {
    if (reference) return super.serialize(reference);
    return {
      ...(super.serialize() as any),
      script: this?.script?.serialize(true),
    };
  }
}
