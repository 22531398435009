import { CommandBase, IParams } from '../framework/CommandBase';
import { Filter } from '../../entities/Filter';
import { ApplyFiltersCommand } from './ApplyFiltersCommand';
import { EventBase } from '../framework/EventBase';
import { CommandError } from '../../ErrorStore';

interface ApplySavedFilterParams extends IParams {
  id: string;
}

export class SavedFilterAppliedEvent extends EventBase {
  static eventType = 'SavedFilterAppliedEvent';
  constructor(public readonly params: ApplySavedFilterParams, public readonly source = ApplySavedFilterCommand) {
    super();
  }
}

export class ApplySavedFilterCommand extends CommandBase<ApplySavedFilterParams> {
  execute(params: ApplySavedFilterParams): SavedFilterAppliedEvent | CommandError {
    const filter = this.model.entityRepository.get<Filter>(params.id)!;
    if (!filter) return CommandError.of('Filter not found', 'error');
    this.model.messageBus.send(ApplyFiltersCommand, { filter });
    return new SavedFilterAppliedEvent(params);
  }
}
