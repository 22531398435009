import { HocuspocusProviderWebsocket } from '@hocuspocus/provider';
import { IStore } from './Store';
import { FileBase } from './FileBase';
import { FileEvents, FileType, Status } from './File.types';
import { ModelFile } from './ModelFile';
import { DocFile } from './DocFile';
import { SpecFile } from './SpecFile';
import { User } from '../types';

class TestProvider {
  private static instanceRef: TestProvider;

  static instance() {
    if (!TestProvider.instanceRef) TestProvider.instanceRef = new TestProvider();
    return TestProvider.instanceRef as unknown as HocuspocusProviderWebsocket;
  }

  on(_event: string, _cb: (e: Event) => void) {
    // console.log('TestProvider on', event);
  }

  attach() {
    // console.log('TestProvider attach');
  }

  send() {
    // console.log('TestProvider send');
  }

  destroy() {
    // console.log('TestProvider destroy');
  }
}

export class FileStoreClient {
  public readonly status: Status;
  private readonly store: IStore;
  private readonly websocketProvider: HocuspocusProviderWebsocket;
  private user: User;
  private scopes: string[];
  private token: string;
  private host: string | undefined;

  public files: Record<string, FileBase> = {};
  private readonly appVersion: number;

  constructor(url: string, store: IStore, appVersion: number, testEnv: boolean) {
    this.store = store;
    this.appVersion = appVersion;
    if (testEnv) this.websocketProvider = TestProvider.instance();
    else
      this.websocketProvider = new HocuspocusProviderWebsocket({
        url,
        connect: true,
        messageReconnectTimeout: 10000,
      });
    this.initStatusListeners();
  }

  setContext({ token, user, scopes, host }: { token: string; user: User; scopes: string[]; host?: string }) {
    this.updateInternals({ token, user, scopes, host });
    this.updateScopesForFiles(scopes);
  }

  private updateInternals({
    token,
    user,
    scopes,
    host,
  }: {
    token: string;
    user: User;
    scopes: string[];
    host?: string;
  }) {
    this.token = token;
    this.user = user;
    this.host = host;
    this.scopes = scopes;
  }

  private updateScopesForFiles(scopes: string[]) {
    Object.values(this.files).forEach((file) => {
      file.updateScopes(scopes);
    });
  }

  private initStatusListeners() {
    Object.entries({
      // open: FileEvents.open,
      // connect: FileEvents.connect,
      // status: FileEvents.status,
      // message: FileEvents.message, // very noise
      // outgoingMessage: FileEvents.outgoingMessage, // very noise
      close: FileEvents.close,
      destroy: FileEvents.destroy,
      disconnect: FileEvents.disconnect,
      awarenessUpdate: FileEvents.awarenessUpdate,
    }).forEach(([event, eventType]) => this.websocketProvider.on(event, (e: Event) => this.setStatus(eventType, e)));
  }

  private setStatus(eventType: FileEvents, _e: Event) {
    if (eventType === FileEvents.awarenessUpdate) {
      // console.log('FileStoreClient AWARENESS UPDATE', e);
    }
    if ([FileEvents.close, FileEvents.disconnect, FileEvents.destroy].includes(eventType)) {
      // console.log('FileStoreClient WS DISCONNECTED');
    }
  }

  loadModel({ fileId, version }: { fileId: string; version?: string; type: FileType }): ModelFile {
    const file =
      (this.files[fileId] as ModelFile) ||
      new ModelFile({
        fileId,
        version,
        token: this.token,
        scopes: this.scopes,
        host: this.host,
        websocketProvider: this.websocketProvider,
        user: this.user,
        store: this.store,
        appVersion: this.appVersion,
      });
    file.load();
    this.files[fileId] = file;
    return file;
  }

  loadModelVersion({ fileId, version }: { fileId: string; version?: string }): ModelFile {
    const modelFile = new ModelFile({
      fileId,
      version,
      token: this.token,
      scopes: this.scopes,
      host: this.host,
      websocketProvider: this.websocketProvider,
      user: this.user,
      store: this.store,
      appVersion: this.appVersion,
    });
    modelFile.load();
    return modelFile;
  }

  loadDoc({ fileId, version }: { fileId: string; version?: string }): void {
    const file =
      (this.files[fileId] as DocFile) ||
      new DocFile({
        fileId,
        version,
        token: this.token,
        scopes: this.scopes,
        host: this.host,
        websocketProvider: this.websocketProvider,
        user: this.user,
        store: this.store,
        appVersion: this.appVersion,
      });
    file.load();
    this.files[fileId] = file;
  }

  loadSpec({ fileId, version }: { fileId: string; version?: string }): void {
    const file =
      (this.files[fileId] as SpecFile) ||
      new SpecFile({
        fileId,
        version,
        token: this.token,
        scopes: this.scopes,
        host: this.host,
        websocketProvider: this.websocketProvider,
        user: this.user,
        store: this.store,
        appVersion: this.appVersion,
      });
    file.load();
    this.files[fileId] = file;
  }

  dispose() {
    this.websocketProvider.destroy();
  }
}

// const isActiveUser = (obj: any): obj is ActiveUser => {
//   return obj.name && obj.color && obj.sub && obj.id;
// };
