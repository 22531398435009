import { SingleSourceModelCanvas } from './canvas/single-source-model-canvas';
import { Stack } from '@mui/material';

export const SingleSourceModel = () => {
  return (
    <Stack width="100%" height="100%" position="relative">
      <SingleSourceModelCanvas />
    </Stack>
  );
};
