import { useCallback, useEffect } from 'react';
import { useEditorSelection } from '@udecode/plate-common';
import { autoUpdate, useFloating, FloatingPortal, flip } from '@floating-ui/react';
import { Box, Button, ClickAwayListener, Divider, Drawer, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useReactiveVar } from '@apollo/client';
import { InsertMenu } from './insert-menu/insert-menu';
import { useIntl } from 'react-intl';
import { getSelectionBoundingClientRect } from '@udecode/plate-floating';
import { showInsertMenuVar } from '../../../state/state';

export const FloatingInsertMenu = () => {
  const showMenu = useReactiveVar(showInsertMenuVar);

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'));

  const { formatMessage: f } = useIntl();

  const selection = useEditorSelection();

  const { x, y, strategy, refs } = useFloating({
    middleware: [flip({ fallbackPlacements: ['bottom', 'top'] })],
    whileElementsMounted: autoUpdate,
    placement: 'right-start',
  });

  const closeMenu = useCallback(() => {
    showInsertMenuVar(false);
  }, []);

  useEffect(() => {
    refs.setReference({ getBoundingClientRect: getSelectionBoundingClientRect });
  }, [refs, selection]);

  // It is necessary to check for isMd here because MUI controls the visibility of the menu in mobile view
  // If a drawer component is not returned on initial render, MUI creates a new instance of a drawer on every render,
  // causing a loss of animation on menu open.
  if (isMd && !showMenu) {
    return null;
  }

  if (isMd)
    return (
      <FloatingPortal>
        <ClickAwayListener onClickAway={closeMenu}>
          <div
            data-testid="floating-balloon-menu"
            ref={refs.setFloating}
            style={{
              position: strategy,
              top: y,
              left: x,
              backgroundColor: 'white',
              border: '1px solid #D8D8D8',
              borderRadius: '8px',
              width: '303px',
              padding: '12px 0px',
              minHeight: '320px',
              overflowY: 'auto',
              height: '50vh',
              zIndex: '1000',
            }}
          >
            <InsertMenu parentRef={refs.floating} />
          </div>
        </ClickAwayListener>
      </FloatingPortal>
    );

  return (
    <Drawer
      data-testid="floating-balloon-menu-drawer"
      transitionDuration={{ appear: 300, enter: 300, exit: 300 }}
      open={showMenu}
      anchor="bottom"
      onClose={closeMenu}
    >
      <Box px="10px" py="12px">
        <Box display="flex" pb="12px" alignItems="center">
          <Typography variant="h6" sx={{ flexGrow: 1, ml: '91.2px' }} textAlign="center">
            Insert Block
          </Typography>
          <Button variant="outlined" onClick={closeMenu}>
            {f({ id: 'cancel' })}
          </Button>
        </Box>
        <Divider />
        <InsertMenu />
      </Box>
    </Drawer>
  );
};
