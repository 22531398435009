import { CommandBase, IParams } from '../framework/CommandBase';
import { EventBase } from '../framework/EventBase';
import { Filter, FilterType } from '../../entities/Filter';
import { CommandError } from '../../ErrorStore';

interface ApplyFilterParams extends IParams {
  filter?: Filter;
}

export class FilterAppliedEvent extends EventBase {
  static eventType = 'FilterAppliedEvent';

  constructor(public readonly params: ApplyFilterParams, public readonly source = ApplyFiltersCommand) {
    super();
  }
}

export class ApplyFiltersCommand extends CommandBase<ApplyFilterParams> {
  execute(params: ApplyFilterParams): FilterAppliedEvent | CommandError | undefined {
    const { filter } = params;
    if (filter) {
      this.applyFilter(filter);
      return undefined;
    }
    this.removeFilter();
    return new FilterAppliedEvent(params);
  }

  private applyFilter(filter: Filter) {
    this.model.entityRepository.filterEntities(this.model.entityRepository.list(), filter);

    if (filter.filterType === FilterType.unsaved) {
      this.model.store.getState().setUnsavedFilter(filter);
      this.model.store.getState().setAppliedSavedFilter(undefined);
    }

    if (filter.filterType === FilterType.publicSaved) {
      this.model.store.getState().setAppliedSavedFilter(filter);
      this.model.store.getState().setUnsavedFilter(undefined);
    }
  }

  private removeFilter() {
    this.model.store.getState().setUnsavedFilter(undefined);
    this.model.store.getState().setAppliedSavedFilter(undefined);
    this.model.entityRepository.filterEntities(this.model.entityRepository.list());
  }
}
