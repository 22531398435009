import { SingleSourceModel } from '../SingleSourceModel';
import { DeleteAssetCommand } from '../commands/entities/DeleteAssetCommand';
import { SetAssetExplorerSearchCommand } from '../commands/explorer/SetAssetExplorerSearchCommand';
import { AssetScope, AssetSort } from '../read-models/asset-explorer/AssetExplorerItem';
import { SetAssetExplorerSortCommand } from '../commands/explorer/SetAssetExplorerSortCommand';
import { SetExplorerSortCommand } from '../commands/explorer/SetExplorerSortCommand';
import { ExplorerSort } from '../read-models/explorer/ExplorerItem';
import { SetExplorerSearchCommand } from '../commands/explorer/SetExplorerSearchCommand';
import { SetAssetExplorerScopeCommand } from '../commands/explorer/SetAssetExplorerScopeCommand';

export class SidebarInteractor {
  constructor(private readonly model: SingleSourceModel) {}

  deleteAsset(id: string) {
    this.model.messageBus.send(DeleteAssetCommand, { entityId: id });
  }

  setAssetSearch(search: string) {
    this.model.messageBus.send(SetAssetExplorerSearchCommand, { search });
  }

  setAssetSort(sort: AssetSort) {
    this.model.messageBus.send(SetAssetExplorerSortCommand, { sort });
  }

  setExplorerSort(sort: ExplorerSort) {
    this.model.messageBus.send(SetExplorerSortCommand, { sort });
  }

  setExplorerSearch(search: string) {
    this.model.messageBus.send(SetExplorerSearchCommand, { search });
  }

  setAssetScope(scope: AssetScope) {
    this.model.messageBus.send(SetAssetExplorerScopeCommand, { scope });
  }
}
