import { FileBase } from './FileBase';
import { FileType, Status } from './File.types';
import { Awareness } from 'y-protocols/awareness';

export class SpecFile extends FileBase {
  getType(): FileType {
    return FileType.Spec;
  }

  public get sharedType() {
    return this.document!.getText('content');
  }

  public get awareness(): Awareness | null {
    return this.provider.awareness;
  }

  load(): void {
    this.status = Status.Initial;
  }
}
