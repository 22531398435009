import { useIntl } from 'react-intl';
import { DocEditor } from '../../../doc/editor/doc-editor';

type EntityDocProps = {
  entityId: string;
};

export const EntityDescription = (props: EntityDocProps) => {
  const { entityId } = props;

  const { formatMessage: f } = useIntl();

  return <DocEditor documentId={`${entityId}-doc`} placeholder={f({ id: 'description' })} />;
};
