import { Box, IconButton, Modal, Stack, styled } from '@mui/material';
import {
  Background,
  Connection,
  NodeMouseHandler,
  NodeRemoveChange,
  OnNodesChange,
  Panel,
  ReactFlow,
  ReactFlowProvider,
  useReactFlow,
  XYPosition,
} from '@xyflow/react';
import { useSingleSourceModel } from '../../../../hooks/use-single-source-model';
import { reactFlowConfig } from '../single-source-model-canvas';
import { DragEventHandler, Fragment, MouseEventHandler, useCallback, useState } from 'react';
import { sid } from '@xspecs/short-id';
import { useApplication } from '../../../../wrappers/application-context/application-context';
import {
  AddEntityCommand,
  AddRelationshipCommand,
  AnnotationType,
  AnnotatorState,
  CloseEntityAnnotatorCommand,
  DeleteEntitiesCommand,
  DeselectAllEntitiesCommand,
  EntityType,
  UpdateEntitiesSelectionsCommand,
  UpdateGqlEntityCommand,
} from '@xspecs/single-source-model';
import { ImageNode } from './image-node';
import { AnnotationNode, annotationNodeClassName } from './annotation-node';
import { useIntl } from 'react-intl';
import { SidePanel } from './side-panel';
import { ProposeSchemaChangesModal } from './propose-schema-changes-modal';
import { Close } from '@mui/icons-material';
import { UploadAssets } from './upload-assets';
import { CanvasControls } from '../controls/canvas-controls';
import { useHotkeys } from '@mantine/hooks';

enum Mode {
  Default = 'Default',
  Insert_GqlOperation = `Insert_${AnnotationType.Operation}`,
  Insert_GqlField = `Insert_${AnnotationType.Field}`,
}

const multiSelectionKeyCode = ['Shift', 'Meta'];

const _Annotator = ({ annotator }: { annotator: AnnotatorState }) => {
  const { nodes, edges, entityId } = annotator;

  const { formatMessage: f } = useIntl();

  const [mode, setMode] = useState<Mode>(Mode.Default);

  const { application } = useApplication();
  const model = useSingleSourceModel();

  const { screenToFlowPosition } = useReactFlow();

  const onDragStart = useCallback<(type: AnnotationType) => DragEventHandler>(
    (type) => (event) => {
      event.dataTransfer.setData('application/reactflow:annotator', type);
      event.dataTransfer.effectAllowed = 'move';
    },
    [],
  );

  const onAnnotatorItemClick = useCallback(
    (type: AnnotationType): MouseEventHandler =>
      () => {
        setMode(Mode[`Insert_${type}` as keyof typeof Mode] ?? Mode.Default);
      },
    [],
  );

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const insertNode = useCallback(
    (type: AnnotationType, position: XYPosition) => {
      application?.model.messageBus.send(AddEntityCommand, {
        id: sid(),
        type: type as unknown as EntityType.GqlField,
        position: position,
        name: '',
        queryId: entityId,
      });
    },
    [application?.model.messageBus, entityId],
  );

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();
      const type = event.dataTransfer.getData('application/reactflow:annotator') as AnnotationType;
      if (!type) return;
      const position = screenToFlowPosition({ x: event.clientX, y: event.clientY });
      insertNode(type, position);
    },
    [insertNode, screenToFlowPosition],
  );

  const onPaneClick = useCallback<MouseEventHandler>(
    (event) => {
      if (mode === Mode.Default) return;
      const position = screenToFlowPosition({ x: event.clientX, y: event.clientY });
      insertNode(mode.replace('Insert_', '') as AnnotationType, position);
      setMode(Mode.Default);
    },
    [insertNode, mode, screenToFlowPosition],
  );

  const onNodeClick = useCallback<NodeMouseHandler>(
    (event) => {
      onPaneClick(event);
    },
    [onPaneClick],
  );

  const onConnect = (connection: Connection) => {
    if (connection.source && connection.target) {
      application?.model.messageBus.send(AddRelationshipCommand, {
        sourceId: connection.source,
        targetId: connection.target,
        sourceHandle: connection.sourceHandle,
        targetHandle: connection.targetHandle,
      });
    }
  };

  const onNodeChanges = useCallback<OnNodesChange>(
    (nodeChanges) => {
      const removeChanges = nodeChanges.filter((change) => change.type === 'remove');
      const otherChanges = nodeChanges.filter((change) => change.type !== 'remove');
      otherChanges.forEach((change) => {
        if (change.type === 'select') {
          application?.model.messageBus.send(UpdateGqlEntityCommand, {
            entityId: change.id,
            selected: change.selected,
          });
        }
        if (change.type === 'position') {
          if (change.position)
            application?.model.messageBus.send(UpdateGqlEntityCommand, {
              entityId: change.id,
              x: change.position.x,
              y: change.position.y,
            });
        }
        if (change.type === 'dimensions' && change.dimensions) {
          application?.model.messageBus.send(UpdateGqlEntityCommand, {
            entityId: change.id,
            width: change.dimensions.width,
            height: change.dimensions.height,
          });
        }
      });

      if (removeChanges.length > 0) {
        application?.model.messageBus.send(DeleteEntitiesCommand, {
          entityIds: removeChanges.map((change: NodeRemoveChange) => change.id),
        });
      }
    },
    [application?.model.messageBus],
  );

  const cursor = mode !== Mode.Default ? `url("/sticky-cursor.svg"), auto` : undefined;

  const stopPropagation = useCallback<MouseEventHandler>((event) => {
    event.stopPropagation();
  }, []);

  const onClose = () => {
    application?.model.messageBus.send(CloseEntityAnnotatorCommand, { entityId: entityId });
  };

  const paste = useCallback(async () => {
    model?.messageBus.send(DeselectAllEntitiesCommand, {});
    model?.clipboard
      .paste({ x: 0, y: 0 })
      .then((pastedEntities) => {
        pastedEntities.map((entity) => {
          model?.messageBus.send(AddEntityCommand, {
            id: entity.id,
            type: EntityType.GqlField,
            position: entity.position,
            name: entity.name,
            queryId: entityId,
          });
        });
        return pastedEntities;
      })
      .then((pasted) => {
        model?.messageBus.send(UpdateEntitiesSelectionsCommand, {
          entitySelections: pasted.map((entity) => ({ id: entity.id, selected: true })),
        });
      });
  }, [entityId, model?.clipboard, model?.messageBus]);

  useHotkeys([
    [
      'Escape',
      () => {
        setMode(Mode.Default);
        application?.model.interactor.onEscape();
      },
    ],
    ['mod+c', () => model?.interactor.onCopy()],
    ['mod+v', () => paste()],
  ]);

  return (
    <>
      <Modal open onClose={onClose} onClick={onClose} disableEscapeKeyDown>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent={'space-between'}
          sx={{ ...rootSx, backgroundColor: 'background.paper', position: 'relative' }}
        >
          <IconButton onClick={onClose} sx={{ position: 'absolute', right: 16, top: 16, zIndex: 100 }} size="small">
            <Close fontSize="inherit" />
          </IconButton>
          <Stack flexGrow={1} gap={1} height="100%" p={1} onClick={stopPropagation}>
            <Fragment>
              <Box
                onClick={stopPropagation}
                sx={{
                  height: 800,
                  flexGrow: 1,
                  backgroundColor: 'background.paper',
                  '.react-flow__pane': { cursor },
                  '.react-flow__node': { cursor },
                }}
              >
                <ReactFlow
                  {...reactFlowConfig}
                  id={`annotator${entityId}`}
                  nodes={nodes}
                  edges={edges}
                  onConnect={onConnect}
                  onNodesChange={onNodeChanges}
                  nodeTypes={nodeTypes}
                  onDragOver={onDragOver}
                  onDrop={onDrop}
                  onPaneClick={onPaneClick}
                  onNodeClick={onNodeClick}
                  fitView
                  fitViewOptions={{ padding: 0.5, maxZoom: 1, duration: 600 }}
                  elementsSelectable={true}
                  multiSelectionKeyCode={multiSelectionKeyCode}
                >
                  <Background />
                  <CanvasControls />
                  <Panel position="top-left">
                    <UploadAssets queryId={entityId} />
                  </Panel>
                </ReactFlow>
                <Stack
                  direction="row"
                  sx={{
                    backgroundColor: 'background.paper',
                    borderRadius: 1,
                    px: 1.1,
                    py: 1,
                    width: 'fit-content',
                    gap: 2,
                    ml: 'auto',
                    mr: 'auto',
                    transform: 'translateY(-150%)',
                    border: '3px solid #E0E0E0',
                  }}
                >
                  <AnnotationContent
                    className={annotationNodeClassName[AnnotationType.Field]}
                    draggable
                    onDragStart={onDragStart(AnnotationType.Field)}
                    onClick={onAnnotatorItemClick(AnnotationType.Field)}
                  >
                    {f({ id: AnnotationType.Field })}
                  </AnnotationContent>
                </Stack>
              </Box>
            </Fragment>
          </Stack>
          <Stack
            onClick={stopPropagation}
            sx={{
              backgroundColor: 'background.paper',
              height: '100%',
              width: 400,
              borderTopRightRadius: modalBorderRadius,
              borderBottomRightRadius: modalBorderRadius,
            }}
          >
            <SidePanel queryId={entityId} queryText={annotator.queryText ?? ''} />
          </Stack>
        </Stack>
      </Modal>
      <ProposeSchemaChangesModal />
    </>
  );
};

export const Annotator = ({ annotator }: { annotator: AnnotatorState }) => {
  return (
    <ReactFlowProvider>
      <_Annotator annotator={annotator} />
    </ReactFlowProvider>
  );
};

const nodeTypes = {
  image: ImageNode,
  [AnnotationType.Operation]: AnnotationNode,
  [AnnotationType.Field]: AnnotationNode,
};

const modalBorderRadius = '8px';
const rootSx = {
  height: '90vh',
  width: '90vw',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  position: 'absolute',
  outline: 'none',
  borderRadius: modalBorderRadius,
};

const AnnotationContent = styled('div')(({ theme }) => ({
  width: 240,
  height: 30,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  paddingLeft: 8,
  paddingRight: 8,
  cursor: 'pointer',
  borderRadius: 4,
  boxShadow: theme.shadows[0],
  '&:hover': {
    transform: 'scale(1.05)',
  },
  transition: 'transform 0.1s ease-in-out',
}));
