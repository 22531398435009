import { withCursors, withYHistory, withYjs } from '@slate-yjs/core';
import { getCursorData } from '../utils';
import { PlateEditor } from '@udecode/plate-common';
import { Application, DocFile } from '@xspecs/single-source-model';
import { singleSourceStore } from '../store/single-source-store/single-source-store';
import { zustandStore } from '../zustand-store';

function getDocFile(editor: PlateEditor) {
  const app = Application.getInstance(zustandStore);
  const organization = singleSourceStore.getState().organization;
  const fileId = `${organization.id}/${editor.id}`;
  app.loadDocCommand({ fileId: fileId, version: undefined });
  return singleSourceStore.getState().filesById[fileId].file as DocFile;
}

function configureEditorWithYjs(editor: PlateEditor, docFile: DocFile, disableAwareness: boolean) {
  const editorWithYjs = withYHistory(withYjs(editor as any, docFile.sharedType, docFile.yjsConfig));
  if (disableAwareness) return editorWithYjs;
  return withCursors(editorWithYjs, docFile.awareness, { data: getCursorData() });
}

type Params = {
  editor: PlateEditor;
  disableAwareness: boolean;
};

export const withPlateYjs = ({ editor, disableAwareness }: Params) => {
  const docFile = getDocFile(editor);
  return configureEditorWithYjs(editor, docFile, disableAwareness);
};
