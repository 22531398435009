import { SingleSourceModel } from '../../SingleSourceModel';
import { EventBase } from './EventBase';
import { CommandError } from '../../ErrorStore';

export interface IParams {
  // modelId: string;
}

export type CommandConstructor<T extends CommandBase<IParams>> = new (model: SingleSourceModel) => T;

export abstract class CommandBase<T extends IParams> {
  constructor(protected readonly model: SingleSourceModel) {}

  shouldSave(): boolean {
    return true;
  }

  abstract execute(params: T): EventBase | CommandError | undefined;
}
