import { IStore } from './Store';
import { Filter } from '../entities/Filter';
import { Label } from '../entities/assets/Label';
import { EntityDetailsState } from '../read-models/entity-details/EntityDetails';
import { CommandError } from '../ErrorStore';
import { AnnotatorState } from '../read-models/annotator/Annotator';
import { AssetExplorerState } from '../read-models/asset-explorer/AssetExplorerItem';
import { ExplorerState } from '../read-models/explorer/ExplorerItem';
import { AttachmentsByAssetIdState } from '../read-models/attachments/Attachments.types';
import { FiltersMenuState } from '../read-models/filters-menu/FiltersMenu';
import { ToolbarState } from '../read-models/toolbar/ToolbarState';

export class ZustandStore implements IStore {
  constructor(private readonly store: any) {}

  setShowUploadLinkModal: (showUploadLinkModal: boolean) => void;

  setToolbar: (toolbar: ToolbarState) => void;

  setState: (state: any) => void;

  setFiltersMenu: (filtersMenu: FiltersMenuState) => void;

  setPalette: (palette: any) => void;

  setAppState: (appName: string, state: object) => void;

  setAssets: (assets: any[]) => void;

  setAnnotator: (annotator: AnnotatorState) => void;

  setErrors: (errors: CommandError[]) => void;

  setShowResolvedThreads: (showResolvedThreads: boolean) => void;

  setEntityDetails: (entity: EntityDetailsState) => void;

  setLabels: (labels: Label[]) => void;

  setUnsavedFilter: (Filter: Filter) => void;

  getState() {
    return this.store.getState();
  }

  setGraph(graph: { nodes: any[]; edges: any[] }) {
    this.store.getState().setGraph(graph);
  }

  setExplorerResult(result: { items: any[]; error?: string }) {
    this.store.getState().setExplorerResult(result);
  }

  setSavedFilters(filters: Filter[]): void {
    this.store.getState().setSavedFilters(filters);
  }

  setAppliedSavedFilter(filter: Filter): void {
    this.store.getState().setAppliedSavedFilter(filter);
  }

  setAssetExplorer(assetExplorer: AssetExplorerState): void {
    this.store.getState().setAssetExplorer(assetExplorer);
  }

  setExplorer(explorer: ExplorerState): void {
    this.store.getState().setExplorer(explorer);
  }

  setAttachmentsByAssetId(attachmentsByAssetId: AttachmentsByAssetIdState): void {
    this.store.getState().setAttachmentsByAssetId(attachmentsByAssetId);
  }
}
