import { Box } from '@mui/material';
import {
  autoPlacement,
  autoUpdate,
  offset,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  FloatingPortal,
  UseFloatingOptions,
} from '@floating-ui/react';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { Node, NodeProps } from '@xyflow/react';
import { ThreadNodePrimitive } from './thread-node-primitive';
import { Thread } from './thread/thread';
import { useSingleSourceModel } from '../../../../../hooks/use-single-source-model';
import { useParams } from 'react-router-dom';
import { AppTypeEvent, useTrackEvents } from '../../../../../hooks/use-track-events';
import { ThreadNodeData } from '@xspecs/single-source-model';

export const ThreadNode = (props: NodeProps<Node<ThreadNodeData>>) => {
  const { id, data } = props;

  const { entityId } = useParams();

  const { trackEvent } = useTrackEvents();

  const [showCommentsList, setShowCommentsList] = useState(data.isNew ?? false);

  const model = useSingleSourceModel();

  const options = useMemo<UseFloatingOptions>(
    () => ({
      placement: 'right',
      middleware: [offset(5), autoPlacement({ allowedPlacements: ['right', 'right-end', 'right-end'] })],
      open: showCommentsList,
      onOpenChange: (open) => {
        setShowCommentsList(open);
        if (!open && data.comments.length === 0) {
          model?.entities.delete(id);
          trackEvent(AppTypeEvent.CommentThreadDeleted, { threadId: id });
        }
      },
      whileElementsMounted: autoUpdate,
    }),
    [data.comments.length, id, model?.entities, showCommentsList, trackEvent],
  );
  const { refs, context, floatingStyles } = useFloating(options);
  const click = useClick(context);
  const dismiss = useDismiss(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss, click]);

  const onClose = useCallback(() => {
    setShowCommentsList(false);
    if (data.comments.length === 0) {
      model?.entities.delete(id);
      trackEvent(AppTypeEvent.CommentThreadDeleted, { threadId: id });
    }
  }, [data.comments.length, model?.entities, id, trackEvent]);

  const floatingSx = useMemo(
    () => ({
      ...floatingStyles,
      width: 291,
      borderRadius: '0px 8px 8px 8px',
      backgroundColor: '#FFF',
      boxShadow: '1px 0px 4px 0px rgba(0, 0, 0, 0.40)',
      p: 1.5,
      cursor: 'default',
    }),
    [floatingStyles],
  );

  useEffect(() => {
    if (id === entityId) {
      setShowCommentsList(true);
    }
  }, [entityId, id, model?.entities]);

  return (
    <Fragment>
      <Box className="dragHandle" ref={refs.setReference} {...getReferenceProps()} sx={rootSx}>
        <ThreadNodePrimitive
          participantIds={data.participantIds}
          isResolved={data.isResolved}
          getReferenceProps={getReferenceProps}
        />
      </Box>
      {showCommentsList ? (
        <FloatingPortal root={document.body}>
          <Box className="nodrag nowheel" ref={refs.setFloating} {...getFloatingProps()} sx={floatingSx}>
            <Thread onClose={onClose} threadId={id} comments={data.comments} isResolved={data.isResolved} />
          </Box>
        </FloatingPortal>
      ) : null}
    </Fragment>
  );
};

const rootSx = {
  width: 'max-content',
};
