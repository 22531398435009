import { CommandBase, IParams } from '../framework/CommandBase';
import { EventBase } from '../framework/EventBase';
import { Label } from '../../entities/assets/Label';
import { EntityBase } from '../../entities/EntityBase';
import { CommandError } from '../../ErrorStore';

interface AddEntitiesToLabelParams extends IParams {
  labelId: string;
  entityIds: string[];
}

export class EntitiesAddedToLabelEvent extends EventBase {
  static eventType = 'EntitiesAddedToLabelEvent';

  constructor(public readonly params: AddEntitiesToLabelParams, public readonly source = AddEntitiesToLabelCommand) {
    super();
  }
}

export class AddEntitiesToLabelCommand extends CommandBase<AddEntitiesToLabelParams> {
  execute(params: AddEntitiesToLabelParams): EventBase | CommandError | undefined {
    const label = this.model.entityRepository.get<Label>(params.labelId)!;
    const entities = params.entityIds.map((id) => this.model.entityRepository.get<EntityBase>(id)!);
    label.addEntities(entities);
    for (const entity of entities) {
      this.model.entityRepository.update(entity);
    }
    this.model.entityRepository.update(label);
    this.model.entityRepository.filterEntities(
      entities,
      this.model.store.getState().savedFilters[0] ?? this.model.store.getState().unsavedFilter,
    );
    return new EntitiesAddedToLabelEvent(params);
  }
}
