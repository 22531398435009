import {
  AssetBase,
  DeleteFilterParams,
  EntityBase,
  Filter,
  FilterByItem,
  FilterType,
  Label,
  UpdateSavedFilterParams,
} from '@xspecs/single-source-model';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { useIntl } from 'react-intl';
import { Add, ChevronRight, Close, DeleteOutlined } from '@mui/icons-material';
import { SavedSelectedFiltersItem } from './item/saved-selected-filters-item';
import { ChangeEventHandler, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { FiltersButton } from '../filters-button/filters-button';
import { FiltersButtonMenuProps } from '../filters-button/menu/filters-button-menu';

type SavedSelectedFiltersProps = {
  appliedSavedFilter: Filter;
  onClear: () => void;
  onDeleteFilter: (params: DeleteFilterParams) => void;
  labels: Label[];
  onSave: (params: UpdateSavedFilterParams) => void;
  assets: AssetBase[];
  filterItems: FilterByItem[];
};

export const SavedSelectedFilters = (props: SavedSelectedFiltersProps) => {
  const {
    appliedSavedFilter,
    onClear,
    labels,
    onDeleteFilter: onDeleteFilterProp,
    onSave,
    assets,
    filterItems,
  } = props;

  const [name, setName] = useState(appliedSavedFilter.name);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isConfirmDelete, setIsConfirmDelete] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState<EntityBase[]>(appliedSavedFilter.criteria);

  const { formatMessage: f } = useIntl();

  const onEdit = () => {
    setIsEditMode(true);
  };

  const filtersButtonMenuProps = useMemo<FiltersButtonMenuProps>(
    () =>
      ({
        filtersButtonMenuListProps: {
          filterByAssetsList: filterItems,
        },
        labelsSelectorProps: {
          labels,
          selectedLabels: selectedFilters as Label[],
          onSelectedLabelsChange: setSelectedFilters,
          createAndSelectLabel: () => {},
        },
        assetsSelectorProps: {
          assets,
          selectedAssets: selectedFilters as AssetBase[],
          onSelectedAssetsChange: setSelectedFilters,
        },
      } satisfies FiltersButtonMenuProps),
    [filterItems, labels, selectedFilters, assets],
  );

  const onDelete = useCallback((id: string) => {
    setSelectedFilters((prevFilters) => prevFilters.filter((filterCriteria) => filterCriteria.id !== id));
  }, []);

  const onDeleteFilter = useCallback(() => {
    setIsConfirmDelete(true);
  }, []);

  const onConfirmDeleteFilter = useCallback(() => {
    onDeleteFilterProp({ id: appliedSavedFilter.id, type: appliedSavedFilter.filterType });
  }, [onDeleteFilterProp, appliedSavedFilter.filterType, appliedSavedFilter.id]);

  const onSaveChanges = useCallback(() => {
    onSave({
      id: appliedSavedFilter.id,
      name,
      criteria: selectedFilters,
      filterType: FilterType.publicSaved,
    });
    setIsEditMode(false);
  }, [name, onSave, appliedSavedFilter.id, selectedFilters]);

  const onNameChange = useCallback<ChangeEventHandler<HTMLInputElement>>((e) => {
    setName(e.target.value);
  }, []);

  useEffect(() => {
    setName(appliedSavedFilter.name);
    setSelectedFilters(appliedSavedFilter?.criteria);
  }, [appliedSavedFilter?.criteria, appliedSavedFilter.filterType, appliedSavedFilter.name]);

  return (
    <Fragment>
      <Stack direction="row" gap={1} alignItems="center" p={1}>
        <Typography variant="subtitle1" color="text.secondary" sx={{ textWrap: 'nowrap' }}>
          {f({ id: 'saved-filters' })}
        </Typography>
        <ChevronRight color="action" />
        <Stack direction="row" sx={{ maxWidth: 400, overflowY: 'scroll' }}>
          <SavedSelectedFiltersItem
            key={appliedSavedFilter.id}
            isEditMode={isEditMode}
            name={name}
            onNameChange={onNameChange}
            criteria={selectedFilters}
            onDeleteLabel={isEditMode ? onDelete : undefined}
          />
        </Stack>
        {isEditMode ? (
          <Fragment>
            <FiltersButton
              ButtonComponent={
                <IconButton
                  sx={{
                    p: 0.5,
                    borderRadius: '2px',
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                    ':hover': { backgroundColor: 'rgba(0, 0, 0, 0.08)' },
                  }}
                >
                  <Add />
                </IconButton>
              }
              filtersButtonMenuProps={filtersButtonMenuProps}
            />
            <Button onClick={onSaveChanges} disabled={selectedFilters.length === 0}>
              {f({ id: 'save-changes' })}
            </Button>
            <Button onClick={onDeleteFilter}> {f({ id: 'delete-filter' })}</Button>
          </Fragment>
        ) : (
          <Fragment>
            <Button onClick={onEdit}>{f({ id: 'edit' })}</Button>
          </Fragment>
        )}
        <IconButton size="small" sx={{ ml: 'auto' }} onClick={onClear}>
          <Close fontSize="inherit" />
        </IconButton>
      </Stack>
      <Dialog open={isConfirmDelete} onClose={() => setIsConfirmDelete(false)}>
        <DialogTitle>
          <Stack direction="row" gap={1} alignItems="center">
            <DeleteOutlined color="error" />
            {f({ id: 'delete-filter-confirm' })}
          </Stack>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {f({ id: 'delete-filter-confirm-message' }, { name })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsConfirmDelete(false)} autoFocus variant="outlined">
            {f({ id: 'cancel' })}
          </Button>
          <Button onClick={onConfirmDeleteFilter} color="error" variant="contained">
            {f({ id: 'delete' })}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};
