import { onAwarenessUpdateParameters } from '@hocuspocus/provider';
import { TransientStoreProvider } from './Transient';
import { Awareness } from 'y-protocols/awareness';
import { EntitySelectionTracker, ulid } from './EntitySelectionTracker';

export class AwarenessTransientStoreProvider implements TransientStoreProvider {
  private observer: (data: onAwarenessUpdateParameters) => void = () => {};

  constructor(private readonly awareness: Awareness, private readonly entitySelectionTracker: EntitySelectionTracker) {}

  get(key: string, parentKey?: string) {
    const localState = this.awareness.getLocalState() || {};
    if (parentKey) return localState[parentKey]?.[key];
    else return localState[key];
  }

  getGlobalSelection(key: string): any {
    const selection = this.entitySelectionTracker.globalSelections[key];
    return this.entitySelectionTracker.clients[selection];
  }

  getLocalSelection(key: string): any {
    return this.entitySelectionTracker.localSelections[key];
  }

  observe(callback: (data: onAwarenessUpdateParameters) => void) {
    this.observer = callback;
  }

  set(field: string, value: any, parentKey?: string, sync?: boolean) {
    if (value === null || value === undefined || value === '' || value === false) this.deleteEntry(parentKey!, field);
    else this.setEntry(parentKey!, field, value, sync);
  }

  setBoolean(field: string, value: boolean, parentKey?: string, sync?: boolean) {
    if (value === null || value === undefined || value === false) this.deleteEntry(parentKey!, field, sync);
    else this.setEntry(parentKey!, field, ulid(), sync);
  }

  private entryBatchQueue: { action: 'set' | 'delete'; parentKey?: string; field: string; value?: any }[] = [];
  private debounceTimeout: NodeJS.Timeout | null = null;

  private setEntry(parentKey: string, field: string, value: any, sync?: boolean) {
    this.entryBatchQueue.push({ action: 'set', parentKey, field, value });
    if (sync) this.processBatch();
    else this.startDebounceTimer();
  }

  private deleteEntry(parentKey: string, field: string, sync?: boolean) {
    this.entryBatchQueue.push({ action: 'delete', parentKey, field });
    if (sync) this.processBatch();
    else this.startDebounceTimer();
  }

  private startDebounceTimer() {
    if (this.debounceTimeout) clearTimeout(this.debounceTimeout);
    this.debounceTimeout = setTimeout(() => this.processBatch(), 75);
  }

  private processBatch() {
    const states = this.awareness.getLocalState() || {};
    for (const entry of this.entryBatchQueue) {
      if (entry.action === 'set') {
        if (entry.parentKey) {
          states[entry.parentKey] = {
            ...states[entry.parentKey],
            [entry.field]: entry.value,
          };
        } else {
          states[entry.field] = entry.value;
        }
      } else if (entry.action === 'delete') {
        if (entry.parentKey) {
          const parentState = states[entry.parentKey];
          if (parentState) {
            const newState = { ...parentState };
            delete newState[entry.field];
            states[entry.parentKey] = newState;
          }
        } else {
          delete states[entry.field];
        }
      }
    }

    // Set changeType to 'transient'
    states['changeType'] = 'transient';

    // Update the awareness state in one batch
    this.awareness.setLocalState(states);

    // Clear the batch queue and debounce timeout
    this.entryBatchQueue = [];
    this.debounceTimeout = null;
  }

  // private setEntry(parentKey: string, field: string, value: any) {
  //   const states = this.awareness.getLocalState();
  //   if (parentKey) states[parentKey] = { ...this.awareness.getLocalState()?.[parentKey], [field]: value };
  //   else states[field] = value;
  //   console.log('transient set');
  //   states['changeType'] = 'transient';
  //   this.awareness.setLocalState(states);
  // }
  //
  // private deleteEntry(parentKey: string, field: string) {
  //   const state = this.awareness.getLocalState();
  //   if (parentKey) {
  //     const parentState = state?.[parentKey];
  //     if (parentState) {
  //       const newState = { ...parentState };
  //       delete newState[field];
  //       state[parentKey] = newState;
  //       console.log('transient delete1');
  //       state['changeType'] = 'transient';
  //       this.awareness.setLocalState(state);
  //     }
  //   } else {
  //     if (state) {
  //       const newState = { ...state };
  //       delete newState[field];
  //       console.log('transient delete2');
  //       state['changeType'] = 'transient';
  //       this.awareness.setLocalState(newState);
  //     }
  //   }
  // }
}
