import { Box, BoxProps } from '@mui/material';
import { useCallback } from 'react';
import {
  getBlockAbove,
  getLastNode,
  getNodeString,
  getPluginType,
  insertNodes,
  useEditorState,
} from '@udecode/plate-common';
import { Path, Transforms } from 'slate';
import { v4 as uuidv4 } from 'uuid';
import { ReactEditor } from 'slate-react';
import { ELEMENT_PARAGRAPH } from '@udecode/plate-paragraph';
import { ELEMENT_LIC } from '@udecode/plate-list';

export type DocFooterProps = BoxProps;

export const DocEditorFooter = (props: DocFooterProps) => {
  const editor = useEditorState();

  const onClick = useCallback(() => {
    const [, lastPath] = getLastNode(editor, []);
    const blockAboveEntry = getBlockAbove(editor, { at: lastPath });
    if (!blockAboveEntry) return;
    const [lastNode, lastNodePath] = blockAboveEntry;
    if (lastNode.type === getPluginType(editor, ELEMENT_LIC)) {
      const nextPath = Path.next([lastNodePath[0]]);
      insertNodes(editor, { id: uuidv4(), type: ELEMENT_PARAGRAPH, children: [{ text: '' }] }, { at: nextPath });
      Transforms.select(editor, nextPath);
      ReactEditor.focus(editor);
      return;
    }

    const lastNodeText = getNodeString(lastNode).trim();
    const isLastNodeParagraph = lastNode.type === getPluginType(editor, ELEMENT_PARAGRAPH);
    const lastNodeHasText = lastNodeText.length > 0;
    if (!isLastNodeParagraph || lastNodeHasText) {
      const nextPath = Path.next(lastNodePath);
      insertNodes(editor, { id: uuidv4(), type: ELEMENT_PARAGRAPH, children: [{ text: '' }] }, { at: nextPath });
      Transforms.select(editor, nextPath);
      ReactEditor.focus(editor);
      return;
    }
    const isEditorBlurred = !editor.selection;
    if (isEditorBlurred) {
      Transforms.select(editor, lastNodePath);
      ReactEditor.focus(editor);
      return;
    }
    if (!editor.selection) return;
    const isLastNodeSelected = Path.equals(lastNodePath, Path.parent(editor.selection.anchor.path));
    if (!isLastNodeSelected && isLastNodeParagraph && !lastNodeHasText) {
      Transforms.select(editor, lastNodePath);
      ReactEditor.focus(editor);
      return;
    }
  }, [editor]);

  return <Box {...props} data-testid="doc-footer" onClick={onClick} sx={{ height: '20px' }} />;
};
