import { FC } from 'react';
import { IconComponentProps } from '../icon';

export const Rectangle: FC<IconComponentProps> = ({ color }) => {
  return (
    <>
      {/*<rect width="20" height="14" fill={color ?? '#E0E0E0'} />*/}
      {/*<rect x="0.5" y="0.5" width="19" height="13" stroke="black" strokeOpacity="0.12" />*/}

      {/*<rect x="4" y="5" width="20" height="14" fill={color ?? '#E0E0E0'} />*/}
      {/*<rect x="4.5" y="5.5" width="19" height="13" stroke="black" strokeOpacity="0.12" />*/}
      <rect x="2" y="5" width="20" height="14" fill={color ?? '#E0E0E0'} />
      <rect x="2.5" y="5.5" width="19" height="13" stroke="black" strokeOpacity="0.12" />
    </>
  );
};

// <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
// <rect x="2" y="5" width="20" height="14" fill="#E735BF"/>
// <rect x="2.5" y="5.5" width="19" height="13" stroke="black" stroke-opacity="0.12"/>
// </svg>
