import { EntityRepository } from '../data/EntityRepository';
import { ScriptBase } from '../entities/scripts/ScriptBase';

export const getTargetDxDyBetweenEntities = (
  repository: EntityRepository,
  entityId1: string,
  entityId2: string,
  frameIndex?: number,
  laneIndex?: number,
) => {
  const entity1 = repository.get(entityId1)!;
  const entity2 = repository.get(entityId2)!;
  let targetPosition: { x: number; y: number };
  if (laneIndex !== undefined && frameIndex !== undefined && entity2 instanceof ScriptBase) {
    targetPosition = entity2.getIngestPosition(frameIndex, laneIndex);
    return {
      x: targetPosition.x - entity1.position.x,
      y: targetPosition.y - entity1.position.y,
    };
  } else {
    targetPosition = entity2.position;
  }
  return {
    x: entity1.position.x - targetPosition.x,
    y: entity1.position.y - targetPosition.y,
  };
};
