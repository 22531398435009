import { InputAdornment, TextField } from '@mui/material';
import { ChangeEventHandler, FC, useCallback } from 'react';
import { ToolbarElementProps, SearchToolbarElement } from '@xspecs/single-source-model';
import { useCommandDispatch } from '../../../wrappers/application-context/application-context';

export const Textbox: FC<ToolbarElementProps<SearchToolbarElement>> = (props) => {
  const { element } = props;

  const dispatch = useCommandDispatch();

  const onChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      dispatch(element.onChange.command, {
        ...element.onChange.params,
        value: e.target.value,
      });
    },
    [dispatch, element.onChange.command, element.onChange.params],
  );

  return (
    <TextField
      size="small"
      placeholder={element.placeholder}
      variant="outlined"
      onChange={onChange}
      value={element.value}
      slotProps={{
        input: {
          startAdornment: (
            <InputAdornment position="start">
              <img src={element.icon} alt="search" style={{ width: 24, height: 24 }} />
            </InputAdornment>
          ),
        },
      }}
    />
  );
};
