import { Box, SxProps, Theme } from '@mui/material';
import { useMonaco } from '../../spec/use-monaco';
import { useElementSize } from '@mantine/hooks';
import { default as Editor } from '@monaco-editor/react';
import { FileStatus } from '../../file-status/file-status';
import { useMemo } from 'react';

type SchemaEditorProps = {
  id: string;
};

export const SchemaEditor = (props: SchemaEditorProps) => {
  const { id } = props;

  const { onMount } = useMonaco({ id: id, placeholder: 'Type here...' });

  const { ref, height, width } = useElementSize<HTMLDivElement>();

  const options = useMemo(
    () =>
      ({
        language: 'graphql',
        theme: 'default',
        wordWrap: 'on',
        'semanticHighlighting.enabled': true,
        lineNumbers: 'off',
        minimap: { enabled: false },
        scrollBeyondLastLine: false,
        scrollBeyondLastColumn: 0,
        fontFamily: "'JetBrains Mono'",
        scrollbar: {
          horizontal: 'hidden',
          vertical: 'hidden',
        },
        renderLineHighlight: 'none',
        overviewRulerBorder: false,
        glyphMargin: false,
        defaultColorDecorators: false,
        overviewRulerLanes: 0,
        folding: false,
        guides: {
          indentation: false,
        },
        renderWhitespace: 'none',
        quickSuggestions: false,
      } as const),
    [],
  );

  return (
    <Box sx={rootSx} ref={ref}>
      <Editor width={width} height={height} language="graphql" options={options} onMount={onMount} />
      <FileStatus fileId={id} />
    </Box>
  );
};

const rootSx: SxProps<Theme> = {
  backgroundColor: 'background.paper',
  height: '100%',
};
