import { CommandBase, IParams } from '../framework/CommandBase';
import { Filter, FilterType } from '../../entities/Filter';
import { ApplyFiltersCommand } from './ApplyFiltersCommand';
import { EventBase } from '../framework/EventBase';
import { CommandError } from '../../ErrorStore';

interface SaveFilterParams extends IParams {
  unsavedFilterId: string;
  name: string;
  filterType: FilterType.publicSaved;
}

export class FiltersSavedEvent extends EventBase {
  static eventType = 'FiltersSavedEvent';
  constructor(public readonly params: SaveFilterParams, public readonly source = SaveFiltersCommand) {
    super();
  }
}

export class SaveFiltersCommand extends CommandBase<SaveFilterParams> {
  execute(params: SaveFilterParams): FiltersSavedEvent | CommandError | undefined {
    let filter = this.model.store.getState().unsavedFilter as Filter;
    if (!filter) return;
    const existingFilters = this.model.store.getState().savedFilters;
    const index = existingFilters.findIndex((f) => f.name.toLowerCase() === params.name.toLowerCase());
    if (index !== -1) {
      filter = this.model.entityRepository.get(existingFilters[index].id);
      filter.criteria = this.model.store.getState().unsavedFilter.criteria;
    }
    filter.filterType = params.filterType;
    filter.name = params.name;
    filter.createdBy = this.model.modelFile.activeUser.id;
    this.model.entityRepository.add(filter);
    this.model.messageBus.send(ApplyFiltersCommand, { filter: filter });
    return new FiltersSavedEvent(params);
  }
}
