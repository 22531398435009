import { IconComponentProps } from '../icon';
import { FC } from 'react';

export const Slack: FC<IconComponentProps> = () => {
  return (
    <>
      <path
        d="M7.99116 10.4395C7.12579 10.4395 6.42178 11.1435 6.42178 12.0088V15.9306C6.42178 16.796 7.12579 17.5 7.99116 17.5C8.85653 17.5 9.56053 16.796 9.56053 15.9306V12.0088C9.56053 11.1435 8.85653 10.4395 7.99116 10.4395Z"
        fill="#FF4340"
      />
      <path
        d="M5.54285 10.4395H4.02352C3.18574 10.4395 2.50418 11.121 2.50418 11.9588C2.50418 12.7966 3.18577 13.4781 4.02352 13.4781C4.86126 13.4781 5.54285 12.7965 5.54285 11.9588V10.4395Z"
        fill="#FF4340"
      />
      <path
        d="M2.5 7.99125C2.5 8.85662 3.204 9.56063 4.06938 9.56063H7.99114C8.85654 9.56063 9.56055 8.85662 9.56055 7.99125C9.56055 7.12588 8.85654 6.42188 7.99117 6.42188H4.06938C3.204 6.42185 2.5 7.12588 2.5 7.99125Z"
        fill="#36C6FF"
      />
      <path
        d="M8.04122 5.54282H9.56055V4.02349C9.56055 3.18571 8.87896 2.50415 8.04122 2.50415C7.20344 2.50415 6.52188 3.18574 6.52188 4.02349C6.52188 4.86123 7.20344 5.54282 8.04122 5.54282Z"
        fill="#36C6FF"
      />
      <path
        d="M12.0088 9.56055C12.8742 9.56055 13.5782 8.85654 13.5782 7.99117V4.06938C13.5782 3.204 12.8742 2.5 12.0088 2.5C11.1435 2.5 10.4395 3.204 10.4395 4.06938V7.99114C10.4395 8.85654 11.1435 9.56055 12.0088 9.56055Z"
        fill="#0DC46E"
      />
      <path
        d="M14.4572 9.56064H15.9765C16.8143 9.56064 17.4958 8.87905 17.4958 8.04131C17.4958 7.20354 16.8142 6.52197 15.9765 6.52197C15.1387 6.52197 14.4572 7.20356 14.4572 8.04131V9.56064Z"
        fill="#0DC46E"
      />
      <path
        d="M17.5 12.0088C17.5 11.1435 16.796 10.4395 15.9306 10.4395H12.0088C11.1435 10.4395 10.4395 11.1435 10.4395 12.0088C10.4395 12.8742 11.1435 13.5782 12.0088 13.5782H15.9306C16.796 13.5782 17.5 12.8742 17.5 12.0088Z"
        fill="#FDCB02"
      />
      <path
        d="M11.9588 14.457H10.4395V15.9764C10.4395 16.8141 11.121 17.4957 11.9588 17.4957C12.7966 17.4957 13.4781 16.8141 13.4781 15.9764C13.4781 15.1386 12.7966 14.457 11.9588 14.457Z"
        fill="#FDCB02"
      />
    </>
  );
};
