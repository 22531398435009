import { Alert, Box, Stack, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { useSingleSourceStore } from '../../../store/single-source-store/single-source-store';
import { NoSearchResults } from '../../no-search-results/no-search-results';
import { ExplorerResult } from '@xspecs/single-source-model';
import { ExplorerAccordion } from './explorer-accordion';
import { memo } from 'react';

export type ExplorerProps = {
  items: ExplorerResult;
};

const _ExplorerList = (props: Pick<ExplorerProps, 'items'>) => {
  const { items } = props;

  return (
    <Stack gap={1}>
      {items.map((item) => (
        <ExplorerAccordion key={item.id} {...item} />
      ))}
    </Stack>
  );
};

const ExplorerList = memo(_ExplorerList);

export const Explorer = (props: ExplorerProps) => {
  const { items } = props;
  const { formatMessage: f } = useIntl();
  const searchQuery = useSingleSourceStore.use.explorer().searchQuery;

  if (!items.length && searchQuery) {
    return <NoSearchResults />;
  }

  if (!items.length) {
    return (
      <Alert severity="info" sx={{ pl: 1.1 }}>
        {f({ id: 'no-results-found' })}
      </Alert>
    );
  }

  return (
    <>
      {searchQuery ? (
        <Box display="flex" gap={0.5} mb={1}>
          <Typography variant="subtitle2" color="text.primary">
            {items.length}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            result{items.length !== 1 && 's'}
          </Typography>
        </Box>
      ) : null}
      <ExplorerList items={items} />
    </>
  );
};
