import { Button, Checkbox, Stack, TextField, Typography } from '@mui/material';
import { OrganizationsQuery } from '../../../../gql/graphql';
import { useIntl } from 'react-intl';
import { useReducer, useCallback, FormEvent } from 'react';
import { useMutation } from '@apollo/client';
import { UPDATE_APP_SETTINGS_MUTATION } from '../../../../graphql/mutations';
import { useActiveOrganization } from '../../../../hooks/use-active-organization';
import { useSnackStack } from '../../../../wrappers/snack-stack-context';
import { ORGANIZATIONS_QUERY } from '../../../../graphql/queries';
import { LoadingButton } from '@mui/lab';
import { logger } from '@xspecs/logger';

type AppSettingsProps = OrganizationsQuery['organizations'][0]['registeredApps'][0];

type FormState = Record<string, string>;
type FormAction = { type: 'UPDATE'; name: string; value: string };

const formReducer = (state: FormState, action: FormAction): FormState => {
  switch (action.type) {
    case 'UPDATE':
      return { ...state, [action.name]: action.value };
    default:
      return state;
  }
};

const FieldRenderer = ({
  field,
  value,
  onChange,
}: {
  field: { name: string; label: string; type: string };
  value: string;
  onChange: (name: string, value: string) => void;
}) => {
  return (
    <Stack key={`AppSettingsField_${field.name}`} direction="row" gap={1} alignItems="center">
      <Typography variant="body2" width={150}>
        {field.label}
      </Typography>
      {field.type === 'Boolean' ? (
        <Checkbox
          size="small"
          checked={value === 'true'}
          onChange={(e) => onChange(field.name, e.target.checked.toString())}
        />
      ) : (
        <TextField
          type={field.type.toLowerCase()}
          size="small"
          sx={{ flexGrow: 1 }}
          value={value}
          onChange={(e) => onChange(field.name, e.target.value)}
        />
      )}
    </Stack>
  );
};

export const AppSettings = (props: AppSettingsProps) => {
  const { manifest, savedSettings, id } = props;
  const { formatMessage: f } = useIntl();
  const fields = manifest?.settings?.fields ?? [];
  const { organization } = useActiveOrganization();
  const { addToast } = useSnackStack();

  const initialValues: FormState =
    savedSettings?.reduce<Record<string, string>>((acc, setting) => {
      if (setting.name == undefined) {
        logger.error("Received a setting without a 'name' field", setting);
        return acc;
      }
      return {
        ...acc,
        [setting.name]: setting.value ?? '',
      };
    }, {}) ?? {};

  const [formState, dispatch] = useReducer(formReducer, initialValues);

  const [updateAppSettings, { client, loading }] = useMutation(UPDATE_APP_SETTINGS_MUTATION);

  const handleFieldChange = useCallback(
    (name: string, value: string) => {
      dispatch({ type: 'UPDATE', name, value });
    },
    [dispatch],
  );

  const handleSubmit = useCallback(
    async (e: FormEvent) => {
      e.preventDefault();
      if (!organization) return;

      const response = await updateAppSettings({
        variables: {
          args: {
            appId: id,
            organizationId: organization.id,
            settings: Object.entries(formState).map(([name, value]) => ({ name, value })),
          },
        },
      });

      if (response.data?.updateAppSettings.error) {
        addToast({ message: response.data.updateAppSettings.error, severity: 'error' });
      } else {
        await client.refetchQueries({ include: [ORGANIZATIONS_QUERY] });
        addToast({ message: f({ id: 'settings-saved' }), severity: 'success' });
      }
    },
    [addToast, client, f, id, organization, updateAppSettings, formState],
  );

  return (
    <Stack gap={2}>
      <Typography>{f({ id: 'configure' })}</Typography>
      <form onSubmit={handleSubmit}>
        <Stack gap={2}>
          {fields.map((field) => (
            <FieldRenderer
              key={field.name}
              field={field}
              value={formState[field.name] ?? ''}
              onChange={handleFieldChange}
            />
          ))}
          <Stack direction="row" alignSelf="flex-end" gap={1}>
            <Button size="small">{f({ id: 'cancel' })}</Button>
            <LoadingButton size="small" variant="contained" type="submit" loading={loading}>
              {f({ id: 'save' })}
            </LoadingButton>
          </Stack>
        </Stack>
      </form>
    </Stack>
  );
};
