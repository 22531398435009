import { Box, SxProps, Theme, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import SearchIcon from '@mui/icons-material/Search';

export const NoSearchResults = () => {
  const { formatMessage: f } = useIntl();

  return (
    <Box sx={wrapperSx}>
      <SearchIcon color="primary" />
      <Typography variant="subtitle1" color="text.primary">
        {f({ id: 'sorry-no-results-found' })}
      </Typography>
      <Typography variant="body2" color="text.secondary" textAlign="center">
        {f({ id: 'check-the-spelling' })}
      </Typography>
    </Box>
  );
};

const wrapperSx: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 1,
  padding: 1.5,
};
