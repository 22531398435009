import { CommandBase, IParams } from '../framework/CommandBase';
import { EventBase } from '../framework/EventBase';
import { CommandError } from '../../ErrorStore';
import { AssetScope } from '../../read-models/asset-explorer/AssetExplorerItem';

interface SetAssetExplorerScopeParams extends IParams {
  scope: AssetScope;
}

export class SetAssetExplorerScopeEvent extends EventBase {
  static eventType = 'SetAssetExplorerScopeEvent';

  constructor(
    public readonly params: SetAssetExplorerScopeParams,
    public readonly source = SetAssetExplorerScopeCommand,
  ) {
    super();
  }
}

export class SetAssetExplorerScopeCommand extends CommandBase<SetAssetExplorerScopeParams> {
  execute(params: SetAssetExplorerScopeParams): SetAssetExplorerScopeEvent | CommandError | undefined {
    const { scope } = params;
    this.model.assetExplorer.updateScope(scope);
    return new SetAssetExplorerScopeEvent(params);
  }
}
