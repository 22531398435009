import { EntityIcon } from '../../../entity-icon/entity-icon';
import { EntityType, RenameEntityCommand } from '@xspecs/single-source-model';
import { Box, InputBase, Stack, useTheme } from '@mui/material';
import { useIntl } from 'react-intl';
import { ChangeEvent, KeyboardEventHandler, useCallback, useEffect, useRef, useState } from 'react';
import { useSingleSourceModel } from '../../../../hooks/use-single-source-model';
import { useSnackStack } from '../../../../wrappers/snack-stack-context';

type EntityHeaderProps = {
  id: string;
  name: string;
  type: EntityType | string;
  iconUrl?: string;
};

export const EntityHeader = (props: EntityHeaderProps) => {
  const { name: nameProp, type, id, iconUrl } = props;

  const inputRef = useRef<HTMLInputElement>(null);
  const theme = useTheme();
  const [name, setName] = useState(nameProp);
  const { addToast } = useSnackStack();
  const { formatMessage: f } = useIntl();
  const model = useSingleSourceModel();

  const onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  }, []);

  const onBlur = useCallback(() => {
    if (!name) {
      setName(nameProp);
      addToast({ message: f({ id: 'name-cannot-be-empty' }), severity: 'error' });
      return;
    }
    model?.messageBus.send(RenameEntityCommand, { entityId: id, newName: name });
  }, [addToast, f, id, model?.messageBus, name, nameProp]);

  const onKeyDown = useCallback<KeyboardEventHandler<HTMLInputElement>>((e) => {
    if (e.key === 'Enter') {
      inputRef.current?.blur();
    }
  }, []);

  useEffect(() => {
    setName(nameProp);
  }, [nameProp]);

  return (
    <Stack direction="row" gap={1} alignItems="center">
      <Box minWidth={36} width={36}>
        {iconUrl ? (
          <img src={iconUrl} alt={`${name} icon`} width={36} height={36} />
        ) : (
          <EntityIcon type={type} width={36} height={36} />
        )}
      </Box>
      <InputBase
        placeholder={f({ id: 'untitled' })}
        title={name}
        value={name}
        onKeyDown={onKeyDown}
        onChange={onChange}
        onBlur={onBlur}
        sx={{ ...theme.typography.h4, width: '100%' }}
        inputProps={{
          ref: inputRef,
          sx: {
            width: '100%',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          },
        }}
      />
    </Stack>
  );
};
