import { Box, BoxProps, useTheme } from '@mui/material';
import { LabelsSelector, LabelsSelectorProps } from '../labels-selector/labels-selector';
import { FloatingArrow, FloatingContext } from '@floating-ui/react';
import { LegacyRef } from 'react';

export type FloatingLabelsSelectorProps = {
  RootProps: BoxProps;
  arrowRef: LegacyRef<SVGSVGElement>;
  context: FloatingContext;
  selectorProps: LabelsSelectorProps;
};

export const FloatingLabelsSelector = (props: FloatingLabelsSelectorProps) => {
  const { RootProps, arrowRef, context, selectorProps } = props;

  const theme = useTheme();

  return (
    <Box
      {...RootProps}
      sx={{
        ...RootProps.sx,
        boxShadow: theme.shadows[8],
        py: 2,
        borderRadius: '4px',
        backgroundColor: 'background.paper',
        width: 300,
        zIndex: 1000,
      }}
    >
      <FloatingArrow ref={arrowRef} context={context} fill="white" />
      <LabelsSelector {...selectorProps} />
    </Box>
  );
};
