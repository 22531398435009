import { Link } from '@mui/icons-material';
import { getIconForUrl } from '../../../../icons/utils';
import { Icon } from '../../../../icons/icon';
import { useCallback, useState } from 'react';

type LinkIconProps = {
  favicon: string;
  url?: string;
};

export const LinkIcon = (props: LinkIconProps) => {
  const { favicon, url } = props;
  const [hasFaviconError, setHasFaviconError] = useState(false);

  const urlIconName = url && getIconForUrl(url);
  const urlIcon = urlIconName ? <Icon name={urlIconName} /> : null;

  const onError = useCallback(() => {
    setHasFaviconError(true);
  }, []);

  const urlFavicon =
    !hasFaviconError && favicon ? (
      <img width={24} src={favicon} alt="favicon" onError={onError} />
    ) : (
      <Link fontSize="medium" sx={{ color: 'action.active' }} />
    );

  return urlIcon || urlFavicon;
};
