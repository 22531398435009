import { ChangeEvent, useCallback, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  arrow,
  autoPlacement,
  FloatingArrow,
  offset,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
} from '@floating-ui/react';
import { Box, Button, FormControlLabel, Radio, RadioGroup, SxProps, Theme, Typography } from '@mui/material';
import { AssetScope } from '@xspecs/single-source-model';

type AssetsScopeMenuProps = { value: AssetScope; onChange: (value: string) => void };

export const AssetScopeMenu = (props: AssetsScopeMenuProps) => {
  const { value, onChange } = props;
  const [showFloating, setShowFloating] = useState(false);

  const { formatMessage: f } = useIntl();

  const arrowRef = useRef(null);

  const { floatingStyles, refs, context } = useFloating({
    placement: 'bottom-end',
    middleware: [
      offset(10),
      autoPlacement({ allowedPlacements: ['bottom-end'] }),
      arrow({ element: arrowRef.current }),
    ],
    open: showFloating,
    onOpenChange: setShowFloating,
  });
  const click = useClick(context, { keyboardHandlers: false });
  const dismiss = useDismiss(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss]);

  const floatingSx: SxProps<Theme> = (theme) => ({
    padding: '4px',
    borderRadius: 1,
    width: '200px',
    background: theme.palette.background.paper,
    boxShadow:
      '0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 5px 5px -3px rgba(0, 0, 0, 0.2)',
    zIndex: 'calc(infinity)',
  });

  const handleOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, value: string) => {
      onChange(value);
      setShowFloating(false);
    },
    [onChange],
  );

  return (
    <>
      <Button
        variant="text"
        size="small"
        sx={changeButtonSx}
        disableRipple
        color="primary"
        ref={refs.setReference}
        {...getReferenceProps()}
      >
        {f({ id: 'change' })}
      </Button>
      {showFloating ? (
        <Box ref={refs.setFloating} {...getFloatingProps()} style={floatingStyles} sx={floatingSx}>
          <FloatingArrow width={20} height={12} ref={arrowRef} context={context} fill="white" />
          <Box px={2} py={1.5}>
            <Typography variant="inputLabel" color="text.primary" fontWeight="500" mb={1}>
              {f({ id: 'show-assets-for' })}
            </Typography>
            <RadioGroup value={value} onChange={handleOnChange}>
              {Object.values(AssetScope).map((sc) => (
                <FormControlLabel key={sc} value={sc} control={<Radio size="small" />} label={sc} sx={controlLabelSx} />
              ))}
            </RadioGroup>
          </Box>
        </Box>
      ) : null}
    </>
  );
};

const changeButtonSx: SxProps<Theme> = {
  textTransform: 'capitalize',
  textDecoration: 'underline',
  p: 0,
  fontSize: (theme) => theme.typography.caption.fontSize,
  minWidth: 'auto',
};

const controlLabelSx: SxProps<Theme> = {
  '& .MuiFormControlLabel-label': {
    fontSize: (theme) => theme.typography.subtitle2.fontSize,
    whiteSpace: 'nowrap',
  },
};
