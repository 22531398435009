import { FC } from 'react';
import { IconComponentProps } from '../icon';

export const Square: FC<IconComponentProps> = ({ color }) => {
  return (
    <>
      <rect x="4.5" y="4.5" width="16" height="16" fill={color ?? '#E0E0E0'} />
      <rect x="5.25" y="5.25" width="14.5" height="14.5" stroke="black" strokeOpacity="0.12" strokeWidth="1.5" />
    </>
  );
};
