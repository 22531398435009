import { EntityBase } from '../EntityBase';
import { z } from 'zod';
import { ScriptConfig } from './ScriptConfig';
import { Local, LocalStoreProvider, localStoreProvider } from '../../data/LocalStoreProvider';
import { EntityType } from '../EntityType';
import { logger } from '@xspecs/logger';
import { Position } from '../../types';
import { ConstructBase } from '../constructs/ConstructBase';
import { Z_INDEXES } from '../../ZIndexes';
import { LaneConfig } from './LaneConfig';
import { Action } from '../constructs/Action';
import { Interface } from '../constructs/Interface';
import { Moment } from '../constructs/Moment';

export enum DropState {
  ALLOWED = 'ALLOWED',
  NOT_ALLOWED = 'NOT_ALLOWED',
  NEUTRAL = 'NEUTRAL',
  DEFAULT = 'DEFAULT',
}

export type DropStates = Record<string, DropState>;

type Lane = {
  displayName: string;
  height: number;
  color: string;
};

type Frame = {
  width: number;
  entities: EntityBase[][];
};

type Cell = {
  x: number;
  y: number;
  width: number;
  height: number;
  id: string;
};

interface ErrorMetaData {
  entity: EntityBase;
  lane: Lane;
  laneIndex: number;
  frame: Frame;
  frameIndex: number;
  isNarrativeScriptEntity: boolean;
  isActionScriptEntity: boolean;
  isNarrativeScript: boolean;
  isActionScript: boolean;
  laneConfig: LaneConfig;
  conflictGroup: (EntityType | string)[];
  scriptType: string;
}

export abstract class ScriptBase extends EntityBase {
  static LANE_PADDING = 80;

  public isExpanded: boolean; // TODO deprecated, remove
  public isOpen: boolean;
  public lanes: Lane[] = [];
  public frames: Frame[] = [];
  public isVisible = false; // override EntityBase.isVisible for scripts

  // static frameSchema = z.object({
  //   width: z.number(),
  //   entities: z.array(
  //     z.object({
  //       entities: z.array(EntityBase.abstractBaseSchema),
  //     }),
  //   ),
  // });

  static frameSchema = z.object({
    width: z.number(),
    entities: z.union([
      // Case 1: Accept EntityBase[][]
      z.array(z.array(EntityBase.abstractBaseSchema)),
      // Case 2: Accept [{ entities: EntityBase[] }]
      z.array(
        z.object({
          entities: z.array(EntityBase.abstractBaseSchema),
        }),
      ),
    ]),
  });
  // .transform((frame) => {
  //   // Normalize the entities structure
  //   if (frame.entities.length > 0 && 'entities' in frame.entities[0]) {
  //     frame.entities = frame.entities.map((entry: any) => entry.entities);
  //   }
  //   return frame;
  //});

  static schema = EntityBase.abstractBaseSchema.extend({
    isOpen: z.boolean().default(false),
    frames: z.array(ScriptBase.frameSchema).optional().default([]),
    zIndex: z.number().default(Z_INDEXES.Script),
    lanes: z
      .array(
        z.object({
          displayName: z.string().optional(),
          height: z.number(),
          color: z.string(),
        }),
      )
      .optional()
      .default([]),
  });

  static parse<T extends ScriptBase>(this: new (...args: any[]) => T, data: unknown): T {
    const validatedData = super.parseBase(data, ScriptBase.schema) as T;
    const script = super.parseBase(validatedData) as T;
    script.frames = validatedData.frames?.map((frame) => ({
      width: frame.width,
      entities: frame.entities.map((lane: any) =>
        lane.entities.map((entityData) => EntityBase.abstractBaseSchema.parse(entityData)),
      ),
    }));
    return script;
  }

  isValid(): boolean {
    return ScriptBase.schema.safeParse(this).success;
  }
  abstract get config(): ScriptConfig;

  static references = ['frames'];

  protected static excludedProperties = new Set([...Array.from(EntityBase.excludedProperties), 'lanePadding']);

  public ingestEntity(entity: EntityBase, frameIndex: number, laneIndex: number): boolean {
    if (entity.type === EntityType.Capability) {
      return this.applyError(SCRIPT_MESSAGES.CAPABILITY_NOT_ALLOWED_IN_SCRIPT);
    }
    if (laneIndex >= this.lanes.length) {
      return this.applyError('Lane not found.');
    }
    let frame = this.frames[frameIndex];
    if (!frame) {
      return this.applyError('Frame not found.');
    }
    const laneConfig = this.config.autoIngestInCorrectLane
      ? this.config.lanes.find((lane) => lane.ingests.includes(entity.type))
      : this.config.getLaneConfig(laneIndex);
    if (!laneConfig || !laneConfig.ingests.includes(entity.type)) {
      const error = this.entityNotAllowedInFrameError(
        this.getErrorMetaData(entity, frameIndex, laneIndex, laneConfig!),
      );
      return this.applyError(error.message);
    }
    if (!this.config.autoIngestInCorrectLane) {
      const conflictGroup = this.getConflictingEntitiesInFrame(this.config, entity, frameIndex);
      if (conflictGroup) {
        const error = this.entityConflictError(
          this.getErrorMetaData(entity, frameIndex, laneIndex, laneConfig, conflictGroup),
        );
        return this.applyError(error.message);
      }
    }
    let correctLaneIngestIndex: number | null = null;
    let correctFrameIngestIndex: number | null = null;
    if (this.config.autoIngestInCorrectLane) {
      const autoIngestPosition = this.calculateAutoIngestPosition(
        frameIndex,
        correctFrameIngestIndex!,
        entity,
        frame,
        correctLaneIngestIndex!,
        laneIndex,
      );
      correctFrameIngestIndex = autoIngestPosition.correctFrameIngestIndex;
      correctLaneIngestIndex = autoIngestPosition.correctLaneIngestIndex;
    } else {
      correctLaneIngestIndex = laneIndex;
      correctFrameIngestIndex = frameIndex;
    }
    if (correctLaneIngestIndex !== null && correctFrameIngestIndex !== null) {
      frame = this.frames[correctFrameIngestIndex];
      if (frame.entities[correctLaneIngestIndex].length >= laneConfig.maxEntities!) {
        const error = this.maxEntitiesError(this.getErrorMetaData(entity, frameIndex, laneIndex, laneConfig));
        return this.applyError(error.message);
      }
      const entities = frame.entities[correctLaneIngestIndex];
      entities.push(entity);
      entity.parent = this;
      entity.zIndex = Z_INDEXES.ConstructInsideFrame;
      this.resetError();
      this.handleDropStates({
        message: SCRIPT_MESSAGES.OK,
        hasError: false,
        laneIndex: correctLaneIngestIndex,
        final: true,
      });
      return true;
    }
    const error = this.entityNotAllowedInFrameError(this.getErrorMetaData(entity, frameIndex, laneIndex, laneConfig));
    return this.applyError(error.message);
  }

  private calculateAutoIngestPosition(
    frameIndex: number,
    correctFrameIngestIndex: number,
    entity: EntityBase,
    frame: Frame,
    correctLaneIngestIndex: number,
    laneIndex: number,
  ): { correctFrameIngestIndex: number; correctLaneIngestIndex: number } {
    if (this.config.autoIngestInCorrectLane) {
      for (let f = frameIndex; f < this.frames.length; f++) {
        if (correctFrameIngestIndex !== null) break;
        if (!this.getConflictingEntitiesInFrame(this.config, entity, f)) {
          for (let l = 0; l < frame.entities.length; l++) {
            const laneConfig = this.config.getLaneConfig(l);
            if (laneConfig.ingests.includes(entity.type)) {
              correctLaneIngestIndex = l;
              correctFrameIngestIndex = f;
              break;
            }
          }
        }
      }
    } else {
      correctLaneIngestIndex = laneIndex;
      correctFrameIngestIndex = frameIndex;
    }
    return { correctFrameIngestIndex, correctLaneIngestIndex };
  }

  eject(entity: EntityBase, nullifyParent = true): boolean {
    let found = false;
    let targetLane: Lane;
    this.frames.forEach((lanes) => {
      lanes.entities.forEach((entities, laneIndex) => {
        const index = entities.findIndex((e) => e.id === entity.id);
        if (index !== -1) {
          entities.splice(index, 1);
          if (nullifyParent) {
            entity.parent = null!;
          }
          found = true;
          targetLane = this.lanes[laneIndex];
        }
      });
    });

    if (found && targetLane!) {
      this.resetError();
      return true;
    }
    return this.applyError(SCRIPT_MESSAGES.ENTITY_NOT_FOUND);
  }

  private getConflictingEntitiesInFrame(
    config: ScriptConfig,
    entity: EntityBase,
    frameIndex: number,
  ): (EntityType | string)[] | null {
    const frame = this.frames[frameIndex];
    if (!frame) {
      logger.error('Frame not found.');
      return null;
    }
    const frameEntities = frame.entities.flat();
    if (!frameEntities) {
      logger.error('Lane not found.');
      return null;
    }

    for (const group of config.conflictingEntityTypeGroups) {
      if (group.includes(entity.type)) {
        if (frameEntities.some((e) => group.includes(e.type))) {
          return group;
        }
      }
    }
    return null;
  }

  public resetDropStates(): void {
    this.frames.forEach((lanes) => {
      lanes.entities.forEach((entities, laneIndex) => {
        this.setDropState(laneIndex, DropState.DEFAULT);
      });
    });
    this.notify();
  }

  @Local
  get dropStates(): DropStates {
    return localStoreProvider.get(LocalStoreProvider.getKey(this, 'dropStates'));
  }

  set dropStates(newValue: DropStates) {
    localStoreProvider.set(LocalStoreProvider.getKey(this, 'dropStates'), newValue);
  }

  /**
   * Gets the number of frames in the ScriptBase.
   * @returns The total number of frames.
   */
  get length(): number {
    return this.frames.length;
  }

  /**
   * Adds a new frame to the script at a specified or the next available index.
   * If a frame already exists at the specified index, the existing frames are shifted to the right.
   * @param frameIndex Optional. The index at which to add the new frame.
   * @returns true if the frame was successfully added; otherwise, false.
   */
  addFrame(frameIndex?: number): boolean {
    if (frameIndex === undefined) {
      frameIndex = this.getNextAvailableIndex();
    }
    if (frameIndex < 0 || frameIndex > this.frames.length) {
      return this.applyError(
        `Invalid frame index: ${frameIndex}. Cannot be negative or greater than the next available index.`,
      );
    }
    this.frames.splice(frameIndex, 0, {
      width: this.config.defaultFrameWidth,
      entities: Array(this.lanes.length)
        .fill(null)
        .map(() => []),
    });
    this.initializeLanes(frameIndex);
    return true;
  }

  removeFrame(frameIndex: number): boolean {
    if (!this.frames[frameIndex]) {
      return this.applyError(`Frame with index ${frameIndex} not found.`);
    }
    if (this.frames.length === 1) {
      return this.applyError('The last frame cannot be deleted.');
    }
    this.frames.splice(frameIndex, 1);
    this.notify();
    return true;
  }

  /**
   * Retrieves all frames in a sorted order along with their contained lanes and entities.
   * @returns An array of objects, each representing a frame with its index, lanes, and entities.
   */
  getFrames(): { frameIndex: number; lanes: { laneIndex: number; entities: EntityBase[] }[] }[] {
    const sortedFrames = Array.from(this.frames.entries()).sort((a, b) => a[0] - b[0]);
    return sortedFrames.map(([frameIndex, lanes]) => {
      const sortedLanes = Array.from(lanes.entities.entries()).sort((a, b) => a[0] - b[0]);
      return {
        frameIndex,
        lanes: sortedLanes.map(([laneIndex, entities]) => ({
          laneIndex,
          entities,
        })),
      };
    });
  }

  /**
   * Retrieves a specific frame and its lanes and entities by frame index.
   * @param frameIndex The index of the frame to retrieve.
   * @returns An object representing the frame, if found, with its index and details of its lanes and entities; otherwise, undefined if the frame does not exist.
   */
  getFrame(
    frameIndex: number,
  ): { frameIndex: number; width: number; lanes: { laneIndex: number; entities: EntityBase[] }[] } | undefined {
    const frame = this.frames[frameIndex];
    if (!frame) {
      return undefined;
    }

    const lanes = Array.from(frame.entities.entries()).sort((a, b) => a[0] - b[0]);
    return {
      frameIndex,
      width: frame.width,
      lanes: lanes.map(([laneIndex, entities]) => ({
        laneIndex,
        entities,
      })),
    };
  }

  /**
   * Adds a new lane to all frames, optionally at a specified index.
   * If the specified index is greater than the last index, appends a new lane.
   * Each new lane is initially empty.
   * @param index Optional. The index at which to add the new lane. If not specified, adds to the end.
   * @returns true if the lane was successfully added; otherwise, false.
   */
  addLane(index?: number): boolean {
    if (this.config.laneMutationAllowed === false) {
      return this.applyError(`Lanes addition not allowed for ${this?.parent?.type}.`);
    }
    if (index === undefined || index > this.lanes.length) {
      index = this.lanes.length;
    }
    const newLane: Lane = {
      displayName: '',
      height: this.config.defaultLaneHeight,
      color: this.config.getLaneConfig(index).color!,
    };
    this.frames.forEach((frame) => {
      frame.entities.splice(index!, 0, []);
    });
    this.lanes.splice(index, 0, newLane);
    return true;
  }

  /**
   * Renames a lane in all frames based on the given lane index.
   * @param laneIndex
   * @param name
   * @returns true if the lane was successfully renamed; otherwise, false.
   */
  renameLane(laneIndex: number, name: string): boolean {
    if (this.config.laneMutationAllowed === false) {
      return this.applyError(`Lanes renaming not allowed for ${this?.parent?.type}.`);
    }
    const lane = this.lanes[laneIndex];
    lane.displayName = name;
    return true;
  }

  /**
   * Removes a lane from all frames based on the given lane index.
   * @param laneIndex The index of the lane to be removed.
   * @returns true if the lane was successfully removed; otherwise, false.
   */
  removeLane(laneIndex: number): boolean {
    if (this.config.laneMutationAllowed === false) {
      return this.applyError(`Lanes deletion not allowed for ${this?.parent?.type}.`);
    }
    if (laneIndex < 0 || laneIndex >= this.lanes.length) {
      return this.applyError(`Lane with index ${laneIndex} not found.`);
    }
    if (this.lanes.length === 1) {
      return this.applyError('The last lane cannot be deleted.');
    }
    this.frames.forEach((frame) => {
      frame.entities.splice(laneIndex, 1);
    });
    this.lanes.splice(laneIndex, 1);
    return true;
  }

  getEntitiesInLane(laneIndex: number): EntityBase[] {
    return this.frames.reduce((entities, frame) => {
      return entities.concat(frame.entities[laneIndex]);
    }, [] as EntityBase[]);
  }

  getEntitiesInFrame(frameIndex: number) {
    return this.frames[frameIndex].entities.flat();
  }

  getCells(): Cell[][] {
    return this.frames.map((frame, frameIndex) =>
      frame.entities.map((lane, laneIndex) => ({
        x: this.getFrameAbsolutePosition(frameIndex).x,
        y: this.getLaneAbsolutePosition(frameIndex, laneIndex).y,
        width: frame.width,
        height: this.lanes[laneIndex].height,
        id: `${frameIndex}_${laneIndex}`,
      })),
    );
  }

  getIngestPosition(frameIndex: number, laneIndex: number): Position {
    // const frame = this.frames[frameIndex];
    // const lane = this.lanes[laneIndex];
    const framePos = this.getFrameAbsolutePosition(frameIndex);
    const lanePos = this.getLaneAbsolutePosition(frameIndex, laneIndex);
    return {
      x: framePos.x + 10,
      y: lanePos.y + 10,
    };
  }

  serialize(reference: boolean = false): unknown {
    if (reference) return super.serialize(reference);
    return {
      ...(super.serialize() as any),
      frames: this.frames.map((frame) => ({
        width: frame.width,
        entities: frame.entities.map((entities) => entities.filter((e) => !!e).map((entity) => entity.serialize(true))),
      })),
      lanes: this.lanes.map((lane) => ({
        displayName: lane.displayName,
        height: lane.height,
        color: lane.color,
      })),
    };
  }

  static initialize(entity: ScriptBase, recalculateDimensions = true): void {
    if (!entity.getFrame(0)) {
      for (let i = 0; i < 4; i++) {
        entity.addFrame(i);
      }
    }
    if (recalculateDimensions) {
      entity.recalculateDimensions();
    }
  }

  private initializeLanes(frameIndex: number): void {
    const frame = this.frames[frameIndex];
    if (!frame) {
      return;
    }
    this.config.lanes.forEach((laneConfig, index) => {
      while (frame.entities.length <= index) {
        frame.entities.push([]);
      }
      if (!this.lanes[index]) {
        this.lanes[index] = {
          displayName: laneConfig.displayName ?? '',
          height: this.config.defaultLaneHeight,
          color: laneConfig.color!,
        };
      }
    });
  }

  private potentiallyResizeAndPositionFrameEntities(frameIndex: number) {
    const laneSizes = this.determineLanesSizes();
    const frameSizes = this.determineFrameSizes();
    const frame = this.frames[frameIndex];
    frame.entities.forEach((_, laneIndex) => {
      this.lanes[laneIndex].height = Math.max(
        laneSizes[laneIndex] + ScriptBase.LANE_PADDING,
        this.config.defaultLaneHeight,
      );
      frame.width = frameSizes[frameIndex];
    });
    frame.entities.forEach((entities, laneIndex) => {
      entities.forEach((entity) => {
        if (entity instanceof ConstructBase) this.positionConstructInCellCenter(entity, frameIndex, laneIndex);
      });
    });
  }

  private determineLanesSizes() {
    const laneCount = this.lanes.length;
    const sizes = Array.from({ length: laneCount }, () => 0);
    for (let i = 0; i < laneCount; i++) {
      this.frames.forEach((frame) => {
        const entities = frame.entities[i];
        const max = entities.reduce((acc, entity) => {
          const height = entity ? entity.dimensions().height : 80; // THIS IS NEEDED TEMPORARILY FOR MIGRATION OF DUFF DATA
          return Math.max(acc, height);
        }, 0);
        sizes[i] = Math.max(sizes[i], max);
      });
    }
    return sizes;
  }

  private determineFrameSizes() {
    const sizes = Array.from({ length: this.frames.length }, () => this.config.defaultFrameWidth);
    this.frames.forEach((frame, frameIndex) => {
      frame.entities.forEach((entities) => {
        const max = entities.reduce((acc, entity) => {
          const width = entity ? entity.width : 120; // THIS IS NEEDED TEMPORARILY FOR MIGRATION OF DUFF DATA
          return Math.max(acc, width);
        }, 0);
        sizes[frameIndex] = Math.max(sizes[frameIndex], max);
      });
    });
    return sizes;
  }

  private getFrameAbsolutePosition(frameIndex: number): Position {
    return {
      x: this.position.x + this.frames.slice(0, frameIndex).reduce((sum, frame) => sum + frame.width, 0),
      y: this.position.y,
    };
  }

  private getLaneAbsolutePosition(frameIndex: number, laneIndex: number): Position {
    return {
      x: this.position.x + this.frames.slice(0, frameIndex).reduce((sum, frame) => sum + frame.width, 0),
      y: this.position.y + this.lanes.slice(0, laneIndex).reduce((sum, lane) => sum + lane.height, 0),
    };
  }

  private positionConstructInCellCenter(entity: ConstructBase, frameIndex: number, laneIndex: number) {
    const [frame, lane] = [this.frames[frameIndex], this.lanes[laneIndex]];
    const dimensions = this.getEntityDimensions(entity);
    const [frameAbsolutePosition, laneAbsolutePosition] = [
      this.getFrameAbsolutePosition(frameIndex),
      this.getLaneAbsolutePosition(frameIndex, laneIndex),
    ];
    const oldPosition = { ...entity.position };
    entity.position = {
      x: frameAbsolutePosition.x + (frame.width - dimensions.width) / 2,
      y: laneAbsolutePosition.y + entity.getTopOffset() + (lane.height - dimensions.height) / 2,
    };
    if ([EntityType.Narrative, EntityType.Action].includes(entity.type as EntityType)) {
      const script = entity['script'] as ScriptBase;
      script.position = {
        x: script.position.x + entity.position.x - oldPosition.x,
        y: script.position.y + entity.position.y - oldPosition.y,
      };
    }
  }

  private getEntityDimensions(entity: EntityBase) {
    if (entity instanceof ConstructBase) {
      return entity.dimensions();
    }
    return { width: entity.width, height: entity.height };
  }

  recalculateDimensions() {
    this.frames.forEach((frame, index) => this.potentiallyResizeAndPositionFrameEntities(index));
    this.updateScriptDimensions();
  }

  private updateScriptDimensions() {
    this.height = this.lanes.reduce((totalHeight, lane) => totalHeight + lane.height, 0);
    this.width = this.frames.reduce((totalWidth, frame) => totalWidth + frame.width, 0);
  }

  private getNextAvailableIndex(): number {
    let maxIndex = -1;
    this.frames.forEach((_, index) => {
      if (index > maxIndex) {
        maxIndex = index;
      }
    });
    return maxIndex + 1;
  }

  private setDropState(laneIndex: number, dropState: DropState) {
    this.dropStates = {
      ...this.dropStates,
      [laneIndex]: dropState,
    };
    this.notify();
  }

  private handleDropStates(params: { message: string; hasError: boolean; laneIndex: number; final?: boolean }) {
    const { laneIndex, hasError, final } = params;
    if (!laneIndex) return params;
    if (final) {
      this.resetDropStates();
      return params;
    }
    this.setDropState(laneIndex, hasError ? DropState.NOT_ALLOWED : DropState.ALLOWED);
    return params;
  }

  private getErrorMetaData(
    entity: EntityBase,
    frameIndex: number,
    laneIndex: number,
    laneConfig: LaneConfig,
    conflictGroup?: (EntityType | string)[],
  ): ErrorMetaData {
    const narrativeScript = this.type === EntityType.NarrativeScript;
    const actionScript = this.type === EntityType.ActionScript;
    const narrativeScriptEntity = entity instanceof Action || entity instanceof Interface || entity instanceof Moment;
    const actionScriptEntity = !narrativeScriptEntity;

    return {
      entity,
      lane: this.lanes[laneIndex],
      laneIndex,
      frame: this.frames[frameIndex],
      frameIndex,
      laneConfig,
      conflictGroup: conflictGroup ?? [],
      isNarrativeScript: narrativeScript,
      isActionScript: actionScript,
      isNarrativeScriptEntity: narrativeScriptEntity,
      isActionScriptEntity: actionScriptEntity,
      scriptType: this.type,
    };
  }

  private entityNotAllowedInFrameError(errorMetaData: ErrorMetaData) {
    if (errorMetaData.isNarrativeScript && errorMetaData.isActionScriptEntity) {
      return this.handleDropStates({
        message: SCRIPT_MESSAGES(errorMetaData).ENTITY_NOT_ALLOWED_IN_NARRATIVE_SCRIPT,
        hasError: true,
        laneIndex: this.config.lanes.findIndex((lane) => lane.ingests.includes(errorMetaData.entity.type)),
      });
    }
    if (errorMetaData.isActionScript && errorMetaData.isNarrativeScriptEntity) {
      return this.handleDropStates({
        message: SCRIPT_MESSAGES(errorMetaData).ENTITY_NOT_ALLOWED_IN_ACTION_SCRIPT,
        hasError: true,
        laneIndex: this.config.lanes.findIndex((lane) => lane.ingests.includes(errorMetaData.entity.type)),
      });
    }

    return this.handleDropStates({
      message: SCRIPT_MESSAGES(errorMetaData).ENTITY_NOT_ALLOWED_IN_FRAME,
      hasError: true,
      laneIndex: this.config.lanes.findIndex((lane) => lane.ingests.includes(errorMetaData.entity.type)),
    });
  }

  private maxEntitiesError(errorMetaData: ErrorMetaData) {
    return this.handleDropStates({
      message: SCRIPT_MESSAGES(errorMetaData).MAX_ENTITIES_ERROR,
      hasError: true,
      laneIndex: errorMetaData.laneIndex,
    });
  }

  private entityConflictError(errorMetaData: ErrorMetaData) {
    if (errorMetaData.isNarrativeScriptEntity) return this.entityConflictErrorNarrative(errorMetaData);
    else return this.entityConflictErrorAction(errorMetaData);
  }

  private entityConflictErrorNarrative(errorMetaData: ErrorMetaData) {
    if (errorMetaData.frame.entities.flat().find((e) => e.type === errorMetaData.entity.type)) {
      return this.handleDropStates({
        message: SCRIPT_MESSAGES(errorMetaData).MAX_ENTITIES_ERROR,
        hasError: true,
        laneIndex: errorMetaData.laneIndex,
      });
    }

    return this.handleDropStates({
      message: SCRIPT_MESSAGES(errorMetaData).ENTITY_CONFLICT_ERROR(errorMetaData.conflictGroup),
      hasError: true,
      laneIndex: errorMetaData.laneIndex,
    });
  }

  private entityConflictErrorAction(errorMetaData: ErrorMetaData) {
    return this.handleDropStates({
      // I don't think this error can happen yet, so keeping it simple for now.
      message: 'ActionScript entity conflict error',
      hasError: true,
      laneIndex: errorMetaData.laneIndex,
    });
  }
}

const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

const SCRIPT_MESSAGES = (errorMetaData: ErrorMetaData) => {
  const vowelRegex = /^[aeiouAEIOU]/;
  const article = vowelRegex.test(errorMetaData.entity.type) ? 'An' : 'A';
  const maxEntities = errorMetaData.laneConfig?.maxEntities ?? 1;
  const capitalizedLaneName = errorMetaData.lane.displayName ? capitalize(errorMetaData.lane.displayName) : '';
  const entityType = capitalize(errorMetaData.entity.type);
  const pluralizedEntityType = entityType + (maxEntities > 1 ? 's' : '');
  const isAre = maxEntities > 1 ? 'are' : 'is';
  const targetLane =
    errorMetaData.entity.type === EntityType.Interface
      ? 'Interaction'
      : errorMetaData.entity.type === EntityType.Moment
      ? 'Context'
      : errorMetaData.entity.type === EntityType.Action
      ? 'System'
      : 'the correct';

  let maxEntitiesError: string;
  const entitiesInCell = errorMetaData.frame.entities[errorMetaData.laneIndex];
  if (entitiesInCell && entitiesInCell[0]) {
    maxEntitiesError = `Cell is occupied with a ${capitalize(
      entitiesInCell[0].type,
    )} entity. \nTIP: Add a frame and put the new ${pluralizedEntityType} entity in it.`;
  } else
    maxEntitiesError = `Only ${maxEntities} ${pluralizedEntityType} ${isAre} allowed in this cell. TIP: Create a new frame either before or after this one.`;

  return {
    ENTITY_NOT_ALLOWED_IN_FRAME: `${capitalize(
      errorMetaData.entity.type,
    )}s are not allowed on the ${capitalizedLaneName} lane. TIP: Try moving it to the ${targetLane} lane.`,
    ENTITY_NOT_ALLOWED_IN_NARRATIVE_SCRIPT: `${article} ${capitalize(
      errorMetaData.entity.type,
    )} is not valid in Narrative Scripts. TIP: Try moving it to an Action Script.`,
    ENTITY_NOT_ALLOWED_IN_ACTION_SCRIPT: `${article} ${capitalize(
      errorMetaData.entity.type,
    )} is not valid in Action Scripts. TIP: Try moving it to a Narrative Script.`,
    MAX_ENTITIES_ERROR: maxEntitiesError,
    ENTITY_CONFLICT_ERROR: (types: (EntityType | string)[]) =>
      `An ${types
        .map((type) => (type === EntityType.Interface ? 'Interaction' : capitalize(type)))
        .join(' and an ')} cannot happen at the exact same time. TIP: Sequence them correctly using separate frames.`,
  };
};
SCRIPT_MESSAGES.OK = 'OK';
SCRIPT_MESSAGES.MIXED_CONSTRUCT_TYPE_BACKEND_ERROR =
  'Cannot ingest backend construct of different types in a single frame';
SCRIPT_MESSAGES.ENTITY_NOT_FOUND = 'Entity not found in frame';
SCRIPT_MESSAGES.CAPABILITY_NOT_ALLOWED_IN_SCRIPT = `Capability cannot be ingested into scripts. TIP: Use transition arrows to connect related scripts to a capability.`;
