import { useEffect, useState } from 'react';
import { Edge, EdgeProps, getBezierPath, Position, useInternalNode } from '@xyflow/react';
import { EdgeLabel } from './label/edge-label';
import { useUpdateEntity } from '../hooks/use-update-entity';
import { EdgeData } from '@xspecs/single-source-model';

export const SimpleFloatingEdge = (props: EdgeProps<Edge<EdgeData>>) => {
  const { id, source, target, markerEnd, selected, style, data } = props;

  const sourceNode = useInternalNode(source);
  const targetNode = useInternalNode(target);

  const [showLabel, setShowLabel] = useState(Boolean(selected || data?.name));

  const { onRename } = useUpdateEntity(id);

  useEffect(() => {
    setShowLabel(Boolean(data?.name));
  }, [data?.name]);

  if (!sourceNode || !targetNode) {
    return null;
  }

  const { sx, sy, tx, ty, sourcePos, targetPos } = getEdgeParams(sourceNode, targetNode);

  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX: sx,
    sourceY: sy,
    sourcePosition: sourcePos,
    targetPosition: targetPos,
    targetX: tx,
    targetY: ty,
  });

  return (
    <>
      {!data?.isReadOnly && showLabel ? (
        <EdgeLabel value={data?.name ?? ''} onChange={onRename} labelX={labelX} labelY={labelY} />
      ) : null}
      <path
        id={id}
        className="react-flow__edge-path"
        d={edgePath}
        strokeWidth={5}
        markerEnd={markerEnd}
        style={{ ...style, cursor: data?.isReadOnly ? 'default' : 'pointer' }}
        onDoubleClick={() => setShowLabel(true)}
      />
    </>
  );
};

function getHandleCoordsByPosition(node, handlePosition) {
  // all handles are from type source, that's why we use handleBounds.source here
  const handle = node.internals.handleBounds.source.find((h) => h.position === handlePosition);

  let offsetX = handle.width / 2;
  let offsetY = handle.height / 2;

  // this is a tiny detail to make the markerEnd of an edge visible.
  // The handle position that gets calculated has the origin top-left, so depending which side we are using, we add a little offset
  // when the handlePosition is Position.Right for example, we need to add an offset as big as the handle itself in order to get the correct position
  switch (handlePosition) {
    case Position.Left:
      offsetX = 0;
      break;
    case Position.Right:
      offsetX = handle.width;
      break;
    case Position.Top:
      offsetY = 0;
      break;
    case Position.Bottom:
      offsetY = handle.height;
      break;
  }

  const x = node.internals.positionAbsolute.x + handle.x + offsetX;
  const y = node.internals.positionAbsolute.y + handle.y + offsetY;

  return [x, y];
}

function getNodeCenter(node) {
  return {
    x: node.internals.positionAbsolute.x + node.width / 2,
    y: node.internals.positionAbsolute.y + node.height / 2,
  };
}

function getParams(nodeA, nodeB) {
  const centerA = getNodeCenter(nodeA);
  const centerB = getNodeCenter(nodeB);

  const horizontalDiff = Math.abs(centerA.x - centerB.x);
  const verticalDiff = Math.abs(centerA.y - centerB.y);

  let position;

  // when the horizontal difference between the nodes is bigger, we use Position.Left or Position.Right for the handle
  if (horizontalDiff > verticalDiff) {
    position = centerA.x > centerB.x ? Position.Left : Position.Right;
  } else {
    // here the vertical difference between the nodes is bigger, so we use Position.Top or Position.Bottom for the handle
    position = centerA.y > centerB.y ? Position.Top : Position.Bottom;
  }

  const [x, y] = getHandleCoordsByPosition(nodeA, position);
  return [x, y, position];
}

export function getEdgeParams(source, target) {
  const [sx, sy, sourcePos] = getParams(source, target);
  const [tx, ty, targetPos] = getParams(target, source);

  return {
    sx,
    sy,
    tx,
    ty,
    sourcePos,
    targetPos,
  };
}
