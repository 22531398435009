import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { SyntheticEvent } from 'react';

export type SidebarTab = { label: string; value: SidebarTabValues };

export enum SidebarTabValues {
  Explorer = 'explorer',
  Assets = 'assets',
}

export type SidebarTabsProps = {
  tabs: SidebarTab[];
  onChange: (event: SyntheticEvent, newValue: string) => void;
  value: string;
};

export const SidebarTabs = (props: SidebarTabsProps) => {
  const { tabs, onChange, value } = props;

  return (
    <Tabs
      value={value}
      onChange={onChange}
      textColor="secondary"
      indicatorColor="secondary"
      variant="fullWidth"
      sx={{ width: '100%' }}
    >
      {tabs.map((tab) => (
        <Tab key={tab.value} label={tab.label} value={tab.value} disableRipple />
      ))}
    </Tabs>
  );
};
