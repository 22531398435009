import {
  Auth0Provider,
  Auth0ProviderOptions,
  AuthorizationParams as OriginalAuthorizationParams,
  useAuth0,
  withAuthenticationRequired as originalWithAuthenticationRequired,
} from '@auth0/auth0-react';
import React, { ReactNode, useContext } from 'react';
import { CacheEntry as OriginalCacheEntry, LocalStorageCache as OriginalLocalStorageCache } from '@auth0/auth0-spa-js';

export type CacheEntry = OriginalCacheEntry;

export class LocalStorageCache extends OriginalLocalStorageCache {}

export type AuthorizationParams = OriginalAuthorizationParams;

// interface AuthContextType {
//   user: any;
//   isAuthenticated: boolean;
//   isLoading: boolean;
//   loginWithRedirect: () => void;
//   logout: (obj: any) => any;
// }

const isE2EMode = localStorage.getItem('E2E_MODE') === 'true';

const MockAuthContext = React.createContext<any>(null);

export const useAuth = () => {
  const auth0Context = useAuth0();
  const context = isE2EMode ? MockAuthContext : React.createContext(auth0Context /* as AuthContextType*/);
  return useContext(context);
};

interface MockProviderProps {
  children: React.ReactNode;
}

export const MockAuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const loginWithRedirect = () => {};
  const logout = () => {};

  const user = {
    name: 'Mock User',
    email: 'mock@example.com',
    email_verified: true,
    nickname: 'mockuser',
    picture: 'http://localhost:3000/alice.png',
    // picture: "https://avatars.githubusercontent.com/u/2021955?v=4",
    updated_at: '2024-05-07T01:45:39.433Z',
    sub: '123',
    ...JSON.parse(localStorage.getItem('E2E_USER') || '{}'),
  };

  const isAuthenticated = true;
  const isLoading = false;

  return (
    <MockAuthContext.Provider value={{ user, isAuthenticated, isLoading, loginWithRedirect, logout }}>
      {children}
    </MockAuthContext.Provider>
  );
};

interface Auth0ProviderProps extends Auth0ProviderOptions {
  children: React.ReactNode;
}

interface AuthProviderProps extends Auth0ProviderProps, MockProviderProps {}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children, ...props }) => {
  return isE2EMode ? (
    <MockAuthProvider {...props}>{children}</MockAuthProvider>
  ) : (
    <Auth0Provider {...props}>{children}</Auth0Provider>
  );
};

export function mockWithAuthenticationRequired<P extends object>(Component: React.ComponentType<P>): React.FC<P> {
  return function WithAuthenticationRequired(props: P): JSX.Element {
    return <Component {...props} />;
  };
}

export const withAuthenticationRequired = isE2EMode
  ? mockWithAuthenticationRequired
  : originalWithAuthenticationRequired;
