import { FC } from 'react';
import { IconComponentProps } from '../icon';

export const Jira: FC<IconComponentProps> = () => {
  return (
    <>
      <path
        d="M17.6427 1.66675H9.61891C9.61891 3.66675 11.238 5.28579 13.2379 5.28579H14.7141V6.71437C14.7141 8.71437 16.3331 10.3334 18.3331 10.3334V2.35724C18.3331 1.97629 18.0236 1.66675 17.6427 1.66675Z"
        fill="#2684FF"
      />
      <path
        d="M13.6666 5.66675H5.64277C5.64277 7.66675 7.26182 9.28576 9.26182 9.28576H10.738V10.7144C10.738 12.7144 12.3571 14.3334 14.3571 14.3334V6.35724C14.3571 5.97625 14.0475 5.66675 13.6666 5.66675Z"
        fill="url(#paint0_linear_1600_963)"
      />
      <path
        d="M9.69049 9.66675H1.66667C1.66667 11.6667 3.28571 13.2858 5.28571 13.2858H6.76188V14.7144C6.76188 16.7144 8.38099 18.3334 10.381 18.3334V10.3572C10.381 9.97625 10.0715 9.66675 9.69049 9.66675Z"
        fill="url(#paint1_linear_1600_963)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1600_963"
          x1="14.192"
          y1="5.67697"
          x2="10.778"
          y2="9.19769"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.176" stopColor="#0052CC" />
          <stop offset="1" stopColor="#2684FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1600_963"
          x1="10.4458"
          y1="9.69769"
          x2="6.49836"
          y2="13.5384"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.176" stopColor="#0052CC" />
          <stop offset="1" stopColor="#2684FF" />
        </linearGradient>
      </defs>
    </>
  );
};
